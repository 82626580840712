import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import { toast } from "../../../utils/helpers";
import { postRequest } from "../../../utils/request";

class Edit extends Component {
    state = {
        errors: {}
    };
    editUser = async event => {
        event.preventDefault();


        const data = new FormData(event.target);

        const response = await postRequest(apiUrl(`grupos/${this.props.grupo.id}`), data);



        if (response.success) {
            this.props.getGrupos();
            this.props.toggle();
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };

    deleteGrupo = async (event, grupo) => {
        event.preventDefault();


        const url = `grupos/${grupo}`;

        const response = await postRequest(apiUrl(url), {}, 'delete');



        if (response.success) {
            this.props.getGrupos();
            this.props.toggle();
            toast("Grupo borrado.");
        } else {
            toast(response.data, "error");
        }
    };

    render() {
        const { errors } = this.state;
        const { toggle, grupo } = this.props;
        return (
            <form method='post' onSubmit={this.editUser}>
                <input type='hidden' name='_method' value='patch' />
                <div className='popup__header'>Editar Grupo</div>
                <div className='popup__body'>
                    <Form grupo={grupo} errors={errors} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={(e) => this.deleteGrupo(e, grupo.id)}>
                        <i className='fas fa-trash-alt'></i>
                    </button>

                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

export default Edit;
