import React, { Component } from "react";

import { postRequest, withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";

import Form from "./Form";
import Loader from "../../general/Loader";
import { toast } from "../../../utils/helpers";

class Edit extends Component {
    state = {
        vehiculos: [],
        rutas: [],
        categoria: "",
        saving: false,
        errors: {},
    };
    componentDidMount() {
        this.getTable("vehiculos");
        this.getTable("rutas");
    }
    getTable = async (table) => {
        const response = await this.props.request(table);

        if (response.data && response.data.length) {
            this.setState({
                [table]: response.data,
            });
        }
    };
    updateTarjeta = async (event, id) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });


        const data = new FormData(event.target);

        const url = `programaciones/${this.props.programacion}/tarjetas/${id}`;

        const response = await postRequest(apiUrl(url), data);



        if (response.success) {
            this.props.getProgramacion();
            this.props.toggle({});
            toast("Tarjeta actualizada");
        } else {
            this.setState({
                errors: response.data.message,
            });
            toast("Por favor revisa todos los campos", "error");
        }

        this.setState({
            saving: false,
        });
    };
    onSelectFicha = (e) => {
        if (!e) {
            return;
        }
        const { vehiculos } = this.state;
        this.setState({
            categoria: vehiculos.filter(
                (vehiculo) => vehiculo.id === e.value
            )[0].categoriaId,
        });
    };
    render = () => {
        const { toggle, tarjeta } = this.props;
        const { vehiculos, rutas, categoria, saving, errors } = this.state;
        return (
            <div>
                <form onSubmit={(e) => this.updateTarjeta(e, tarjeta.id)}>
                    <input type='hidden' name='_method' value='patch' />
                    <div className='popup__header'>Editar Tarjeta</div>
                    <div className='popup__body'>
                        <Form
                            vehiculos={vehiculos}
                            rutas={rutas}
                            categoria={categoria}
                            tarjeta={tarjeta}
                            errors={errors}
                            onSelectFicha={this.onSelectFicha}
                        />
                    </div>
                    <div className='popup__footer'>
                        <button
                            type='button'
                            onClick={() => toggle({})}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        {saving ? (
                            <Loader type='small' />
                        ) : (
                            <button type='submit' className='btn btn-primary'>
                                Guardar
                            </button>
                        )}
                    </div>
                </form>
            </div>
        );
    };
}

export default withRequest(Edit);
