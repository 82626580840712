import React, { Component } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import { postRequest } from "../../utils/request";

class Create extends Component {
    state = {
        errors: {},
        cuenta: {}
    };
    createCuenta = async event => {
        event.preventDefault();

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl("cuentas"), data);

        if (response.success) {
            this.props.getCuentas();
            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { errors, cuenta } = this.state;
        const { toggle } = this.props;
        return (
            <form method='post' onSubmit={this.createCuenta}>
                <div className='popup__header'>Nueva Cuenta</div>
                <div className='popup__body'>
                    <Form cuenta={cuenta} errors={errors} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Crear
                    </button>
                </div>
            </form>
        );
    }
}

export default Create;
