import React, { Component } from "react";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { getLink, toast } from "../../utils/helpers";
import Loader from "../general/Loader";
import ImageLoader from "../general/ImageLoader";
import Precios from "./Precios";
import { apiRequest } from "../../hooks/apiRequest";
import { postRequest } from "../../utils/request";

class EditFotos extends Component {
	state = {
		errors: {},
		showDelete: false,
		precios_venta: [],
	};
	componentDidMount() {
		setTimeout(() => {
			if (this.props.producto?.precios_venta) {
				this.setState((state) => ({
					...state,
					precios_venta: this.props.producto?.precios_venta?.map(
						(precio) => ({
							id: precio.id,
							precio_venta: parseFloat(precio.precio_venta),
							costo: parseFloat(precio.porciento_ganancia),
							impuesto: precio.impuesto,
							precio_total: parseFloat(precio.total),
						})
					),
				}));
			}
		}, 500);
	}
	editProducto = async (event) => {
		event.preventDefault();

		if (!auth.can("editar-inventario")) return;

		const data = new FormData(event.target);
		const response = await postRequest(apiUrl(`productos/${this.props.producto.id}`), data);

		if (response.success) {
			this.props.history.push(getLink("/productos"));
            toast('Fotos guardas exitosamente.')
		}

		if (response.code === 422) {
			this.setState({ errors: response.data.message });
            toast('Las fotos no pudieron ser guardas', 'error')
		}
	};
	deleteImageRequest = async (imagen) => {
		const medioID = this.props.producto?.fotos.find(foto => foto.url === imagen.url)?.id;

		if (!medioID) return;

		const response = await apiRequest(
			null,
			`delete-medio/${medioID}`,
			"delete"
		);

		if(!response.success) return;

		toast("Imagen eliminada");
	};
	render() {
		const { errors } = this.state;
		const { producto, handleInputChange } = this.props;

		const notEditable = !auth.can("editar-inventario");

		if (producto === {}) {
			return <Loader />;
		}

		return (
			<div>
				<form
					method="post"
					onSubmit={this.editProducto}
					className="col-md-10"
				>
					<input type="hidden" name="_method" value="patch" />
					<input type="hidden" name="producto_id" value={producto.id} />
					<input type="hidden" value={producto.tipo} name="tipo" />
					<input type="hidden" name="nombre" value={producto.nombre} />
					<input type="hidden" name="referencia" value={producto.referencia} />
					<input type="hidden" name="estado" value={producto.estado} />
					<input type="hidden" name="unidad" value={producto.unidad} />
					<input type="hidden" name="grupo_id" value={producto.grupo?.id} />
					<input type="hidden" name="almacen_id" value={producto.almacen?.id} />
					<input type="hidden" name="impuesto_id" value={producto.impuesto?.id} />
					<input type="hidden" id="descripcion" name="descripcion" rows="4" value={producto.descripcion} />
					<input type="hidden" value={producto.costo} />
					{producto.tipo === "producto" && (
						<div className="form-group">
							<ImageLoader
								name="fotos[]"
								imagenes={producto.fotos.map(foto => foto.url) || []}
								multiple
								onInputChange={handleInputChange}
								deleteImageRequest={this.deleteImageRequest}
							/>
							{errors.fotos && (
								<small className="help-blockParams form-Text">
									{errors.fotos[0]}
								</small>
							)}
						</div>
					)}

					<div className="page-footer text-right">
						<button
							type="button"
							onClick={() =>
								this.props.history.push(getLink("/productos"))
							}
							className="btn btn-plain"
						>
							Cancelar
						</button>
						{!notEditable && (
							<button type="submit" className="btn btn-primary">
								Guardar
							</button>
						)}
					</div>
				</form>
			</div>
		);
	}
}

export default EditFotos;
