import React, { Component } from "react";

import { postRequest, withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";

import Form from "./Form";
import Loader from "../../general/Loader";
import { toast } from "../../../utils/helpers";

class Create extends Component {
    state = {
        vehiculos: [],
        rutas: [],
        categoria: "",
        saving: false,
        errors: [],
    };
    componentDidMount() {
        this.getTable("vehiculos");
        this.getTable("rutas");
    }
    getTable = async (table) => {
        const response = await this.props.request(table);

        if (response.data.length) {
            this.setState({
                [table]: response.data,
            });
        }
    };
    createAsignacion = async (event) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });

        const data = new FormData(event.target);

        const response = await postRequest(apiUrl("asignaciones"), data);

        if (response.success) {
            this.props.getAsignaciones();
            this.props.toggle({});
            toast("Asignación creada exitosamente.");
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
            toast("La asignación no pudo ser creada.", "error");
        }

        this.setState({
            saving: false,
        });
    };
    onSelectFicha = (e) => {
        if (!e) {
            return;
        }

        const { vehiculos } = this.state;
        const categoria = vehiculos.filter(
            (vehiculo) => vehiculo.id === e.value
        )[0];

        this.setState({
            categoria: categoria ? categoria.categoriaId : null,
        });
    };
    render = () => {
        const { toggle, categoriaActiva } = this.props;
        const { vehiculos, rutas, categoria, errors } = this.state;
        return (
            <div>
                <form onSubmit={this.createAsignacion}>
                    <div className='popup__header'>Nueva Asignación</div>
                    <div className='popup__body'>
                        <Form
                            vehiculos={vehiculos}
                            rutas={rutas}
                            categoria={categoria}
                            categoriaActiva={categoriaActiva}
                            asignacion={{}}
                            errors={errors}
                            onSelectFicha={this.onSelectFicha}
                        />
                    </div>
                    <div className='popup__footer'>
                        <button
                            type='button'
                            onClick={() => toggle({})}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        {this.state.saving ? (
                            <Loader type='small' />
                        ) : (
                            <button type='submit' className='btn btn-primary'>
                                Crear
                            </button>
                        )}
                    </div>
                </form>
            </div>
        );
    };
}

export default withRequest(Create);
