import React, { Component } from "react";
import dayjs from "dayjs";

import Loader from "../../general/Loader";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import { toast } from "../../../utils/helpers";
import { postRequest } from "../../../utils/request";

export default class IncentivosForm extends Component {

    state = {
        fecha: dayjs().format("YYYY-MM-DD"),
        monto: '',
        descripcion: '',
    }

    componentDidMount() {
        this.setState({
            frecuencia: this.props.defaultFrecuencia
        });
    }

    updateField(field, value) {
        this.setState({
            [field]: value
        });
    }

    onChangeFrecuencia = (e) => {
        if (!e.value) return;

        this.setState({
            frecuencia: e.value,
        });
    }

    createIncentivo = async event => {
        event.preventDefault();

        this.setState({
            saving: true
        });

        const data = new FormData(event.target);
        const url = `incentivos`;
        const response = await postRequest(apiUrl(url), data);

        if (response.success) {
            this.props.getIncentivos();
            document.getElementById("incentivosform").reset();
            this.props.toggle();
            toast("Nuevo incentivo creado.");
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({
            saving: false
        });
    };

    render() {
        const { fecha, monto, descripcion, frecuencia } = this.state;
        const { errors, empleadoId } = this.props;

        return (
            <form
                id='incentivosform'
                method='post'
                onSubmit={this.createIncentivo}>
                <input type='hidden' name='_method' value='post' />
                <div className='popup__header'>Crear Incentivo {frecuencia?.toUpperCase()}</div>
                    <div className='popup__body'>
                        <input type="hidden" name="empleado_id" value={empleadoId} />
                        <input type="hidden" name="tipo_incentivo" value="individual" />
                        <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Frecuencia:
                                </label>
                            </div>
                            <div className='col col-md-8'>
                                <select
                                    name="frecuencia"
                                    value={frecuencia}
                                    onChange={e => this.updateField('frecuencia', e.target.value)}
                                    className="form-control"
                                    required
                                >
                                    <option value="unico">Unico</option>
                                    <option value="fijo">Fijo</option>
                                </select>
                                {errors.frecuencia && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.frecuencia[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Fecha:
                                </label>
                            </div>
                            <div className='col col-md-8'>
                                <input
                                    type='date'
                                    name='fecha'
                                    onChange={e => this.updateField('fecha', e.target.value)}
                                    value={fecha}
                                    className='form-control'
                                />
                                {errors.fecha_inicio && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.fecha_inicio[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Monto:
                                </label>
                            </div>
                            <div className='col col-md-8'>
                                <input
                                    type='number'
                                    name='monto'
                                    step='0.01'
                                    onChange={e => this.updateField('monto', e.target.value)}
                                    value={monto}
                                    required
                                    className='text-input form-control'
                                />
                                {frecuencia === 'fijo' && (
                                    <div><small>Este monto se sumará de manera fija en cada nómina.</small></div>
                                )}
                                {errors.monto && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.monto[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Descripción:
                                </label>
                            </div>
                            <div className='col col-md-8'>
                                <input
                                    type='text'
                                    name='descripcion'
                                    required
                                    onChange={e => this.updateField('descripcion', e.target.value)}
                                    value={descripcion}
                                    className='form-control'
                                />
                                {errors.descripcion && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.descripcion[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={this.props.toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {this.state.saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Crear
                        </button>
                    )}
                </div>
            </form>
        );
    }
}
