import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { auth } from "../../utils/auth";
import { postRequest, withRequest } from "../../utils/request";
import { toast, formatCurrency, getLink } from "../../utils/helpers";
import { apiUrl } from "../../utils/apiUrl";
import Loader from "../general/Loader";
import dayjs from "dayjs";
import GastoItem from "./GastoItem";
import Select from "react-select/creatable";

class CrearGastosGenerales extends Component {
    state = {
        errors: {},
        compra: {},
        items: [false],
        categorias: [],
        cuentas: [],
        gastoItems: [{ id: Date.now(), subtotal: 0 }],
        total: 0,
        fecha: dayjs().format("YYYY-MM-DD"),
        saving: false,
    };
    componentDidMount() {
        this.getTable("items");
        this.getTable("categoria-gastos", "categorias");
        this.getTable("cuentas");
    }
    getTable = async (url = null, nombre = null) => {
        const table = await this.props.request(url);
        const tableName = nombre ? nombre : url;

        if (table.data) {
            this.setState({
                [tableName]: table.data,
                loading: false,
            });
        }
    };
    createGastos = async (event) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl("gastos-generales"), data);

        if (response.success) {
            this.props.history.push(getLink("/gastos-generales"));
            this.setState({
                saving: false,
            });
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message, saving: false });
            toast("Por favor revisa todos los campos", "error");
        }
    };
    addItem = () => {
        this.setState({
            gastoItems: [...this.state.gastoItems, { id: Date.now() }],
        });
    };
    removeGasto = (item) => {
        this.setState({
            gastoItems: this.state.gastoItems.filter((i) => i.id !== item),
        });
    };
    updateTotal = async (id, value) => {
        await this.setState({
            gastoItems: this.state.gastoItems.map((item) => {
                if (item.id === id) {
                    item.subtotal = value;
                }
                return item;
            }),
        });

        this.setState({
            total: this.state.gastoItems.reduce((accum, item) => {
                return accum + item.subtotal;
            }, 0),
        });
    };
    onChangeDate = (e) => {
        this.setState({
            fecha: e.target.value,
        });
    };
    onChangeInput = async (name, value) => {
        await this.setState({
            [name]: value,
        });
    };
    render() {
        const {
            errors,
            gastoItems,
            categorias,
            cuentas,
            items,
            total,
            saving,
            fecha,
        } = this.state;

        return (
            <div className='crear-compra'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Añadir Gastos Generales</span>
                    </h1>
                </div>
                <form
                    method='post'
                    onSubmit={this.createGastos}
                    onKeyUp={this.preventEnter}
                    className='row'>
                    <div className='form-group col-md-4'>
                        <label className='label form-control-label'>
                            Fecha:
                        </label>
                        <input
                            type='date'
                            className='text-input form-control'
                            id='fecha'
                            name='fecha'
                            value={fecha}
                            onChange={this.onChangeDate}
                        />
                        {errors.fecha && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha[0]}
                            </small>
                        )}
                    </div>
                    <div className='form-group col-md-4'>
                        <label className='label form-control-label'>
                            Categoría:
                        </label>
                        <Select
                            id='categoria'
                            name='categoria'
                            options={categorias.map((cat) => ({
                                value: cat.nombre,
                                label: cat.nombre,
                            }))}
                        />

                        {errors.categoria && (
                            <small className='help-blockParams form-Text'>
                                {errors.categoria[0]}
                            </small>
                        )}
                    </div>
                    <div className='form-group col-md-4'>
                        <label className='label form-control-label'>
                            Cuenta:
                        </label>
                        <Select
                            id='cuenta'
                            name='cuenta'
                            options={cuentas.map((cuenta) => ({
                                value: cuenta.nombre,
                                label: cuenta.nombre,
                            }))}
                        />

                        {errors.cuenta && (
                            <small className='help-blockParams form-Text'>
                                {errors.cuenta[0]}
                            </small>
                        )}
                    </div>
                    <div className='col-md-12'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th className='text-center'>Item</th>
                                    <th width='40%'>Descripción</th>
                                    <th width='100px' className='text-center'>
                                        Disponible
                                    </th>
                                    <th className='text-right'>Cantidad</th>
                                    <th className='text-right'>Costo</th>
                                    <th className='text-right' width='12%'>
                                        Sub-total
                                    </th>
                                    <th width='25px'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {gastoItems.map((gasto, index) => (
                                    <GastoItem
                                        key={gasto.id}
                                        gasto={gasto}
                                        index={index}
                                        items={items}
                                        fecha={fecha}
                                        removeGasto={this.removeGasto}
                                        updateTotal={this.updateTotal}
                                    />
                                ))}
                                <tr>
                                    <td colSpan='7' className='text-right'>
                                        <button
                                            type='button'
                                            onClick={this.addItem}
                                            className='btn'>
                                            <i className='fas fa-plus' />{" "}
                                            Agregar Item
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='5' className='text-right'>
                                        <strong className='label form-control-label'>
                                            Total:
                                        </strong>
                                    </td>
                                    <td colSpan='5' className='text-right'>
                                        <input
                                            type='hidden'
                                            name='pagado'
                                            value='0'
                                        />
                                        <input
                                            type='hidden'
                                            name='total'
                                            value={total}
                                        />
                                        {formatCurrency(total)}
                                        {errors.total && (
                                            <small className='help-blockParams form-Text'>
                                                {errors.total[0]}
                                            </small>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                    </div>
                    <div className='page-footer text-right col-md-12'>
                        <button
                            type='button'
                            onClick={() =>
                                (window.location = getLink("/gastos-generales"))
                            }
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        {saving ? (
                            <Loader type='small' />
                        ) : (
                            <button type='submit' className='btn btn-primary'>
                                Guardar
                            </button>
                        )}
                    </div>
                </form>
            </div>
        );
    }
}

export default withRouter(withRequest(CrearGastosGenerales));
