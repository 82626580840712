import React, { Component } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import { postRequest } from "../../utils/request";

class Edit extends Component {
    state = {
        errors: {}
    };
    editUser = async event => {
        event.preventDefault();

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl(`cuentas-contables/${this.props.cuenta.id}`), data);

        if (response.success) {
            this.props.getCuentas();
            this.props.toggle();
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { errors } = this.state;
        const { toggle, cuenta, borrarCuenta } = this.props;
        return (
            <form method='post' onSubmit={this.editUser}>
                <input type='hidden' name='_method' value='patch' />
                <div className='popup__header'>Editar Cuenta Contable</div>
                <div className='popup__body'>
                    <Form cuenta={cuenta} errors={errors} />
                </div>
                <div className='popup__footer'>
                    {cuenta.balance === '0.00' && (
                        <button
                            type='button'
                            className='btn float-left'
                            onClick={(e) => borrarCuenta()}>
                            <i className='fas fa-trash-alt'></i>
                        </button>
                    )}
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

export default Edit;
