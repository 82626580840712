import React, { createRef, useEffect, useState } from "react";
import Select from "react-select";
import dayjs from "dayjs";
import Summary from "./Summary";
import { getLink, getModalidadAusencia, modalidadAusenciaForDropdown, money, toast } from "../../utils/helpers";
import { request } from "../../utils/request";
import { apiRequest } from "../../hooks/apiRequest";
import Loader from "../general/Loader";
import DocumentoItem from "./DocumentoItem";

const Form = ({ausencia, cancel, success, ondelete, empleado}) => {
    const [diasDisponibles, setDiasDisponibles] = useState(empleado ? empleado.vacacionesSummary.adquiridos - empleado.vacacionesSummary.usados : 0);
    const [errors, setErrors] = useState({});
    const [tipoAusenciasForDropdown, setTipoAusenciasForDropdown] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cantidadDias, setCantidadDias] = useState(ausencia.cantidad_dia ?? "");
    const [modalidad, setModalidad] = useState(getModalidadAusencia(ausencia.modalidad));
    const [montoPagar, setMontoPagar] = useState(ausencia?.monto_pagar ?? 0);
    const [empleados, setEmpleados] = useState([]);
    const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState(ausencia.empleado ?? empleado);
    const [tipoAusencia, setTipoAusencia] = useState(ausencia?.tipo?.nombre);
    const [puedeContinuar, setPuedeContinuar] = useState(true);
    const formRef = createRef();
    const [diasRestantes, setDiasRestantes] = useState(ausencia?.restantes);
    const [maxDiasRestantes, setMaxDiasRestantes] = useState(diasDisponibles + ausencia.restantes);
    const [contarSabado, setContarSabado] = useState(ausencia.contar_sabado ?? 0);

    const [documentos, setDocumentos] = useState([{id: 0}]);

    useEffect(() => {
		setDocumentos(ausencia?.documentos ?? []);
    }, [ausencia]);

    const addDocumento = () => {
        setDocumentos(prev => ([
            ...prev,
            {id: documentos.length}
        ]))
    };

    const getTipos = () => {
        request("tipo-ausencia").then((response) => setTipoAusenciasForDropdown(response.data.map((tipo) => ({ value: tipo.id, label: tipo.nombre }))));
    };

    const getEmpleados = () => {
        const url = ausencia.id ? 'empleados' : 'empleados?estado=activos';
        request(url).then((response) => setEmpleados(response.data));
    };

    useEffect(() => {
        getTipos();
        getEmpleados();
    }, []);

    useEffect(() => {
        if (modalidad && (modalidad?.value === 'normal_pagas' || modalidad?.value === 'trabajadas_normal_pagas')) {
            const sueldoDia = parseFloat(empleadoSeleccionado.sueldoFijo) / 23.83;

            const years = dayjs().diff(dayjs(empleadoSeleccionado.fechaIngreso), 'year');

            setMontoPagar(ausencia?.monto_pagar ?? sueldoDia * (years < 5 ? 14 : 18));
        } else {
            setMontoPagar(0);
        }
    }, [empleadoSeleccionado, modalidad]);

    useEffect(() => {
        setDiasDisponibles(empleadoSeleccionado ? empleadoSeleccionado.vacacionesSummary.adquiridos - empleadoSeleccionado.vacacionesSummary.usados : 0);
    }, [empleadoSeleccionado]);

    useEffect(() => {
        if (ausencia.id) {
            setPuedeContinuar(true);
        } else {
            setPuedeContinuar(tipoAusencia === 'Vacaciones' ? diasDisponibles > 0  : true);
        }
    }, [tipoAusencia, diasDisponibles]);

    useEffect(() => {
        setMaxDiasRestantes(diasDisponibles + ausencia.restantes);
    }, [diasDisponibles, ausencia]);

    const actualizarDiasRestantes = (value) => {
        if (value > maxDiasRestantes) {
            setDiasRestantes(maxDiasRestantes);

            toast(`Los dias restantes no pueden ser mayor a ${maxDiasRestantes}`, "error");

            return;
        }

        if (value < 0) {
            setDiasRestantes(0);

            toast('Los dias restantes no pueden ser menor a 0', "error");

            return;
        }

        setDiasRestantes(value);
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
    };

    const validateFunctions = {
        "cantidad_dia": (value) => value ? true : "Este campo es requerido.",
        "descripcion": (value) => value ? true : "Este campo es requerido.",
        "fecha": (value) => value ? true : "Este campo es requerido.",
        "empleado_id": (value) => value ? true : "Este campo es requerido.",
        "modalidad": (value) => value ? true : "Este campo es requerido.",
        "tipo_ausencia_id": (value) => value ? true : "Este campo es requerido.",
        "restantes": (value) => value < 0 ? "Este campo no puede ser menor a 0" : ( value > maxDiasRestantes ? `Este Campo no puede ser mayor a ${maxDiasRestantes}` : true),
    };

    const onChangeValue = (value, field) => {
        const remover = () => setErrors((errors) => {
            const { [field]: error, ...otros } = errors;

            return otros;
        });

        if (Object.hasOwnProperty.call(validateFunctions, field)) {
            const msg = validateFunctions[field](value);

            msg === true ? remover() : setErrors((errors) => ({ ...errors, [field]: [msg] }));
        } else {
            remover();
        }
    };

    const validateData = (data) => {
        const errors = {};

        for (const field in data) {
            if (Object.hasOwnProperty.call(data, field)) {
                const value = data[field];

                if (Object.hasOwnProperty.call(validateFunctions, field)) {
                    const msg = validateFunctions[field](value);

                    if (msg !== true) {
                        errors[field] = [msg];
                    }
                }
            }
        }

        setErrors(errors);

        return Object.keys(errors).length ? false : true;
    };

    const contar_sabado = (val) => {
        if (val === contarSabado) {
            setContarSabado(0);
        } else {
            setContarSabado(val);
        }
    }

    const save = (nueva, print = false) => {
        setLoading(true);

        const data = new FormData(formRef.current);
        data.append('empleado_id', empleadoSeleccionado?.id)

        if (validateData(Object.fromEntries(data))) {
            let url = "ausencias";
            if (ausencia.id) {
                data.append("_method", "put");

                url += `/${ausencia.id}`;
            }

            apiRequest(data, url, "POST").then((response) => {
                if (response.success) {
                    toast(`Ausencia ${ausencia.id ? "actualizada" : "creada"}.` , "success");

                    success(response.data, nueva);

                    if (print) window.open(getLink(`/imprimir/ausencias/${response.data?.id}`));
                } else if (response.code === 422) {
                    setErrors(response.data.message);
                } else {
                    toast(response.data.message, "error");
                }

                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    };

    return (
        <form ref={formRef} onSubmit={onFormSubmit}>
            <div className='popup__header'>{ausencia.id ? "Editar" : "Nueva"} Ausencia</div>

            <div className={`popup__body ${puedeContinuar ? '' : 'overflow-visible'}`}>
                <div>
                    <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>Tipo*:</label>
                        </div>
                        <div className='col col-md-7'>
                            <Select
                                name='tipo_ausencia_id'
                                key='tipo_ausencia_id'
                                isClearable={true}
                                defaultValue={
                                    ausencia.tipo_ausencia_id ? {
                                        value: ausencia.tipo_ausencia_id,
                                        label: ausencia.tipo?.nombre
                                    } : null
                                }
                                onChange={(e) => {
                                    onChangeValue(e, "tipo_ausencia_id");
                                    setTipoAusencia(e.label);
                                }}
                                options={tipoAusenciasForDropdown}
                                isDisabled={ausencia?.estado === 'En curso' || ausencia?.estado === 'Completada'}
                            />
                            {errors.tipo_ausencia_id && (
                                <small className='help-blockParams form-Text'>
                                    {errors.tipo_ausencia_id[0]}
                                </small>
                            )}
                        </div>
                    </div>

                    <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>Empleado*:</label>
                        </div>
                        <div className='col col-md-7'>
                            <Select
                                name='empleado_id'
                                key='empleado_id'
                                isClearable={true}
                                isDisabled={empleado ? true : (ausencia?.estado === 'En curso' || ausencia?.estado === 'Completada')}
                                value={empleadoSeleccionado ? {
                                    value: empleadoSeleccionado?.id,
                                    label: empleadoSeleccionado?.nombreCompleto
                                } : null}
                                onChange={(e) => {
                                    setEmpleadoSeleccionado(empleados.reduce((p, emp) => emp.id === e?.value ? emp : p, null));
                                    onChangeValue(e, "empleado_id");
                                }}
                                options={empleados.map((emp) => ({ value: emp.id, label: emp.nombreCompleto }))}
                            />
                            {errors.empleado_id && (
                                <small className='help-blockParams form-Text'>
                                    {errors.empleado_id[0]}
                                </small>
                            )}
                        </div>
                    </div>

                    { empleadoSeleccionado && <Summary empleadoId={empleadoSeleccionado?.id} /> }

                    { !puedeContinuar && <div className='text-center'><small className='help-blockParams form-Text'>No tiene vacaciones disponible</small></div>}

                    { puedeContinuar && <>
                        { tipoAusencia === 'Vacaciones' && <div className='row form-group'>
                            <div className='col col-md-5'>
                                <label className='label form-control-label'>Modalidad*:</label>
                            </div>
                            <div className='col col-md-7'>
                                <Select
                                    name='modalidad'
                                    key='modalidad'
                                    isClearable={true}
                                    value={modalidad}
                                    onChange={(e) => {
                                        setModalidad(e);
                                        onChangeValue(e, "modalidad");
                                    }}
                                    isDisabled={empleadoSeleccionado ? (ausencia?.estado === 'En curso' || ausencia?.estado === 'Completada') : true}
                                    options={modalidadAusenciaForDropdown}
                                />
                                {errors.modalidad && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.modalidad[0]}
                                    </small>
                                )}
                            </div>
                        </div>}

                        <div className='row form-group'>
                            <div className='col col-md-5'>
                                <label className='label form-control-label'>Seleccione la fecha*:</label>
                            </div>
                            <div className='col col-md-7'>
                                <input
                                    type="date"
                                    name="fecha"
                                    className="form-control"
                                    defaultValue={ausencia.desde ? dayjs(ausencia.desde).format('YYYY-MM-DD') : null}
                                    onChange={(e) => onChangeValue(e.target.value, "fecha")}
                                    readOnly={ausencia?.estado === 'En curso' || ausencia?.estado === 'Completada'}
                                />
                                {errors.fecha && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.fecha[0]}
                                    </small>
                                )}
                            </div>
                        </div>

                        <div className='row form-group'>
                            { <>
                                <input type='hidden' name='descontar_vacaciones' value='1' />
                                <input type='hidden' name='contar_sabado' value={contarSabado} />

                                <div className='col col-md-12'>
                                    <div className="row m-0">
                                        <label className='switch switch-3d switch-success mr-3'>
                                            <input
                                                type='checkbox'
                                                className='switch-input'
                                                name='contar_sabado_check'
                                                value='1'
                                                checked={contarSabado === 1}
                                                readOnly={ausencia?.estado === 'En curso' || ausencia?.estado === 'Completada'}
                                                disabled={ausencia?.estado === 'En curso' || ausencia?.estado === 'Completada'}
                                                onChange={(e) => contar_sabado(1)}
                                            />
                                            <span className='switch-label' />
                                            <span className='switch-handle' />
                                        </label>

                                        <label className='label font-weight-bold form-control-label'>Considerar sábados como 1 día laborable</label>
                                    </div>

                                    {/* {errors.contar_sabado && (
                                        <small className='help-blockParams form-Text'>
                                            {errors.contar_sabado[0]}
                                        </small>
                                    )} */}
                                </div>

                                <div className='col col-md-12'>
                                    <div className="row m-0">
                                        <label className='switch switch-3d switch-success mr-3'>
                                            <input
                                                type='checkbox'
                                                className='switch-input'
                                                name='contar_sabado_check'
                                                value='0.5'
                                                checked={contarSabado === 0.5}
                                                readOnly={ausencia?.estado === 'En curso' || ausencia?.estado === 'Completada'}
                                                disabled={ausencia?.estado === 'En curso' || ausencia?.estado === 'Completada'}
                                                onChange={(e) => contar_sabado(0.5)}
                                            />
                                            <span className='switch-label' />
                                            <span className='switch-handle' />
                                        </label>

                                        <label className='label font-weight-bold form-control-label'>Considerar sábados como 1/2 día laborable</label>
                                    </div>
                                    {errors.contar_sabado && (
                                        <small className='help-blockParams form-Text'>
                                            {errors.contar_sabado[0]}
                                        </small>
                                    )}
                                </div>
                            </>}

                            { tipoAusencia !== 'Vacaciones' && <div className='col col-md-12'>
                                <div className="row m-0">
                                    <input type='hidden' name='contar_sabado' value='0' />
                                
                                    <label className='switch switch-3d switch-success mr-3'>
                                        <input type='hidden' name='descontar_vacaciones' value='0' />
                                        <input
                                            type='checkbox'
                                            className='switch-input'
                                            name='descontar_vacaciones'
                                            value='1'
                                            defaultChecked={ausencia.descontar_vacaciones ?? (diasDisponibles > 0)}
                                            readOnly={diasDisponibles <= 0 || ausencia?.estado === 'En curso' || ausencia?.estado === 'Completada'}
                                            disabled={diasDisponibles <= 0 || ausencia?.estado === 'En curso' || ausencia?.estado === 'Completada'}
                                        />
                                        <span className='switch-label' />
                                        <span className='switch-handle' />
                                    </label>

                                    <label className='label font-weight-bold form-control-label'>Deducir de los días de Vacaciones:</label>
                                </div>
                                
                                {errors.descontar_vacaciones && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.descontar_vacaciones[0]}
                                    </small>
                                )}
                            </div> }
                        </div>

                        <div className='row form-group'>
                            <div className='col col-md-5'>
                                <label className='label form-control-label'>Cantidad de Días*:</label>
                            </div>
                            <div className='col col-md-7'>
                                <input
                                    type="number"
                                    name="cantidad_dia"
                                    className="form-control"
                                    value={cantidadDias}
                                    onChange={(e) => {
                                        setCantidadDias(e.target.value);
                                        onChangeValue(e.target.value, "cantidad_dia");
                                    }}
                                    min={1}
                                    step={1}
                                    readOnly={ausencia?.estado === 'En curso' || ausencia?.estado === 'Completada'}
                                />
                                {errors.cantidad_dia && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.cantidad_dia[0]}
                                    </small>
                                )}
                            </div>
                        </div>

                        { ausencia?.estado === 'En curso' && <div className='row form-group'>
                            <div className='col col-md-5'>
                                <label className='label form-control-label'>Días disponibles:</label>
                            </div>
                            <div className='col col-md-7'>
                            <input
                                    type="number"
                                    name="restantes"
                                    className="form-control"
                                    value={diasRestantes}
                                    min={0}
                                    max={maxDiasRestantes}
                                    step={1}
                                    onChange={(e) => {
                                        actualizarDiasRestantes(e.target.value);
                                        onChangeValue(e.target.value, "restantes");
                                    }}
                                />
                            </div>
                        </div> }

                        <input type="hidden" name="monto_pagar" className="form-control" value={montoPagar} readOnly />

                        { tipoAusencia === 'Vacaciones' &&  <div className='row form-group'>
                            <div className='col col-md-5'>
                                <label className='label form-control-label'>Monto a Pagar:</label>
                            </div>
                            <div className='col col-md-7'>
                                <input
                                    type="number"
                                    step={0.01}
                                    style={{ backgroundColor: "#fff" }}
                                    value={parseFloat(money(montoPagar))}
                                    className="form-control"
                                    onChange={(e) => setMontoPagar(e.target.value)}
                                    readOnly={modalidad?.value !== 'normal_pagas' && modalidad?.value !== 'trabajadas_normal_pagas'}
                                    disabled={ausencia?.estado === 'Completada'}
                                />

                                {errors.monto_pagar && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.monto_pagar[0]}
                                    </small>
                                )}
                            </div>
                        </div> }

                        <div className='row form-group'>
                            <div className='col col-md-5'>
                                <label className='label form-control-label'>Descripción*:</label>
                            </div>
                            <div className='col col-md-7'>
                                <textarea
                                    type='text'
                                    className='text-input form-control'
                                    name='descripcion'
                                    defaultValue={ausencia.descripcion}
                                    onChange={(e) => onChangeValue(e.target.value, "descripcion")}
                                    readOnly={ausencia?.estado === 'Completada'}
                                />
                                {errors.descripcion && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.descripcion[0]}
                                    </small>
                                )}
                            </div>
                        </div>

                        <div className="row form-group">
                            <div className="col col-md-12">
                                <h3>Documentos Adjuntos</h3>
                            </div>
                        </div>
                        <div>
                            {documentos.map((documento, index) => (
                                <DocumentoItem key={documento.id}
                                    documento={documento}
                                    existing={ausencia?.documentos?.length}
                                    id={index}
                                />
                            ))}

                            <div className="row form-group">
                                <div className="col">
                                    <button type="button" className="btn float-right" onClick={addDocumento}>
                                        + Agregar Documento
                                    </button> 
                                </div>
                            </div>	
                        </div>
                    </> }
                </div>
            </div>

            { loading
                ? <Loader />
                : (<div className='popup__footer'>
                    { puedeContinuar ? <div className="row">
                        {ausencia?.id && ausencia?.estado !== 'Completada' &&  <button 
                            type='button' 
                            className='btn btn-plain mr-auto ml-3' 
                            onClick={() => ondelete(ausencia) }>
                            Cancelar Ausencia
                        </button>}
                        <button
                            type='button'
                            onClick={cancel}
                            className={ausencia?.id ? 'btn btn-plain ml-auto mr-2' : 'btn btn-plain mr-auto ml-2'}>
                            Cancelar
                        </button>
                        {ausencia?.id ? "" : <button type='button' onClick={() => save(false, true)} className='btn btn-outline-primary mr-2'>
                            Guardar e Imprimir
                        </button>}
                        {ausencia?.id ? "" : <button type='button' onClick={() => save(true)} className='btn btn-outline-primary mr-2'>
                            Guardar y Crear Nueva
                        </button>}
                        <button type='button' onClick={() => save(false)} className='btn btn-primary mr-2'>
                            {ausencia?.id ? "Actualizar" : "Crear"}
                        </button>
                    </div> : <div className="row">
                        <button
                            type='button'
                            onClick={cancel}
                            className={ausencia?.id ? 'btn btn-plain ml-auto mr-2' : 'btn btn-plain mr-auto ml-2'}>
                            Cancelar
                        </button>
                    </div>}
                </div>)
            }
        </form>
    );
};

export default Form;
