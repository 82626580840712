import React, { Component } from "react";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatCurrency } from "../../utils/helpers";
import dayjs from "dayjs";
import Loader from "../general/Loader";
import { request } from "../../utils/request";

export default class ReporteNominaTSS extends Component {
    state = {
        empleados: [],
        tss: [],
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {
        const file = window.localStorage.getItem('tss_file')
        const url = 'reportes' + window.location.search + '&tss_file=' + file;
        const response = await request(apiUrl(url));

        if (response.success) {
            this.setState({
                empleados: response.data.empleados,
                tss: response.data.tss,
            });
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const {empleados, tss} = this.state;

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);

        var empleadosNoTSS = empleados.filter(emp => {
            const entss = tss.filter(e => e.cedula === emp.cedula);
            return entss.length === 0;
        });

        var empleadosNoSistema = tss.filter(emp => {
            const entsistema = empleados.filter(e => e.cedula === emp.cedula);
            return entsistema.length === 0;
        });

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Comparar Empleados Activos con TSS
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(query.desde).format("DD/MM/YYYY")} -{" "}
                        {dayjs(query.hasta).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            Empleados en Sistema: {empleados.length}
                        </div>

                        <div className='m-t-5 float-right'>
                            Empleados en TSS: {tss.length}
                        </div>
                    </div>
                </div>
                {empleadosNoTSS.length > 0 && (<div>
                    <h3 className="pb-10 m-t-20">
                        Empleados activos en el sistema que no están en TSS
                        <div className="float-right">Total: {empleadosNoTSS.length}</div>
                    </h3>
                    <table className='table m-b-40'>
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Nombre</th>
                                <th>Tipo</th>
                                <th className='text-right'>Cédula</th>
                            </tr>
                        </thead>
                        <tbody>
                            {empleadosNoTSS.map((empleado) => {
                                return (
                                    <tr key={empleado.id}>
                                        <td>{empleado.codigo}</td>
                                        <td>
                                            {empleado.nombre}
                                        </td>
                                        <td>{empleado.tipo}</td>
                                        <td className='text-right'>
                                            {empleado.cedula}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>)}
                {empleadosNoSistema.length > 0 && (<div>
                    <h3 className="pb-10">
                        Empleados en TSS que no están en el sistema
                        <div className="float-right">Total: {empleadosNoSistema.length}</div>
                    </h3>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th className='text-right'>Cédula</th>
                            </tr>
                        </thead>
                        <tbody>
                            {empleadosNoSistema.map((empleado) => {
                                return (
                                    <tr key={empleado.id}>
                                        <td>
                                            {empleado.nombre}
                                        </td>
                                        <td className='text-right'>
                                            {empleado.cedula}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>)}
            </div>
        );
    }
}
