import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import { postRequest } from "../../../utils/request";

class Create extends Component {
    state = {
        errors: {},
        grupo: {}
    };
    createGrupo = async event => {
        event.preventDefault();


        const data = new FormData(event.target);

        const response = await postRequest(apiUrl("grupos"), data);



        if (response.success) {
            this.props.getGrupos();
            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { errors, grupo } = this.state;
        const { toggle } = this.props;
        return (
            <form method='post' onSubmit={this.createGrupo}>
                <div className='popup__header'>Nuevo Grupo</div>
                <div className='popup__body'>
                    <Form grupo={grupo} errors={errors} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Crear
                    </button>
                </div>
            </form>
        );
    }
}

export default Create;
