import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import { toast } from "../../../utils/helpers";
import { postRequest } from "../../../utils/request";

class Edit extends Component {
    state = {
        errors: {},
    };
    editSucursal = async (event) => {
        event.preventDefault();

        const data = new FormData(event.target);
        const response = await postRequest(
            apiUrl(`sucursales/${this.props.sucursal.id}`),
            data
        );

        if (response.success) {
            // this.props.getSucursales();
            toast("Sucursal Editada con éxito", 'success');
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { errors } = this.state;
        const { sucursales, sucursal } = this.props;
        return (
            <form method='post' onSubmit={this.editSucursal}>
                <input type='hidden' name='_method' value='patch' />
                <Form
                    sucursal={sucursal}
                    sucursales={sucursales}
                    errors={errors}
                />
                <div className='popup__footer'>
                    <button type='submit' className='btn btn-primary'>
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

export default Edit;
