import React, { Component } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import { toast } from "../../utils/helpers";
import Loader from "../general/Loader";
import { postRequest } from "../../utils/request";

class Create extends Component {
	state = {
		errors: {},
		contacto: {},
		isLoading: false,
	};
	createContacto = async (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		const data = new FormData(event.target);
		const response = await postRequest(apiUrl("contactos"), data);

		if (response.success) {
			if (this.props.fromFacturacion) {
				this.props.toggle(null, response.data);
				return;
			}
			this.props.getContactos();
			this.props.toggle();
			toast("Nuevo contacto creado.");
		}
		if (response.code === 422) {
			this.setState({ errors: response.data.message });
			toast("Favor verificar todos los campos.", "error");
		}

		this.setState({ isLoading: false });
	};
	render() {
		const { errors, contacto, isLoading } = this.state;
		const { toggle } = this.props;
		return (
			<form method="post" onSubmit={this.createContacto}>
				<div className="popup__header">Nuevo Contacto</div>
				<div className="popup__body">
					<Form contacto={contacto} errors={errors} />
				</div>
				<div className="popup__footer">
					<button
						type="button"
						onClick={toggle}
						className="btn btn-plain"
					>
						Cancelar
					</button>
					{isLoading ? (
						<Loader type="small" />
					) : (
						<button type="submit" className="btn btn-primary">
							Crear
						</button>
					)}
				</div>
			</form>
		);
	}
}

export default Create;
