import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import { toast } from "../../../utils/helpers";
import { postRequest } from "../../../utils/request";

class Edit extends Component {
    state = {
        errors: {},
    };
    editUser = async (event) => {
        event.preventDefault();

        const data = new FormData(event.target);
        const response = await postRequest(
            apiUrl(`categorias/${this.props.categoria.id}`),
            data
        );

        if (response.success) {
            this.props.getCategorias();
            this.props.toggle();
            toast("Categoría actualizada.");
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    delete = async (event, categoria) => {
        event.preventDefault();



        const url = `categorias/${categoria}`;

        const response = await postRequest(apiUrl(url), {}, 'delete');



        if (response.success) {
            this.props.getCategorias();
            this.props.toggle({});
            toast("Categoría borrada.");
            return;
        } else {
            toast(response.data, "error");
        }
    };
    render() {
        const { errors } = this.state;
        const { toggle, categoria } = this.props;
        return (
            <form method='post' onSubmit={this.editUser}>
                <input type='hidden' name='_method' value='patch' />
                <div className='popup__header'>Editar Categoría</div>
                <div className='popup__body'>
                    <Form categoria={categoria} errors={errors} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={(e) => this.delete(e, categoria.id)}>
                        <i className='fas fa-trash-alt'></i>
                    </button>
                    <button
                        type='button'
                        onClick={() => toggle({})}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

export default Edit;
