import React, { Component } from "react";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatCurrency } from "../../utils/helpers";
import dayjs from "dayjs";
import Loader from "../general/Loader";
import { request } from "../../utils/request";

export default class ReporteProduccionRutas extends Component {
    state = {
        rutas: false,
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {

        const response = await request(apiUrl(`reportes` + window.location.search));
        if (response.success) {
            this.setState({
                rutas: response.data,
            });
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { rutas } = this.state;

        if (!rutas) {
            return <Loader />;
        }

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);

        const totalViajes = rutas.reduce((acc, ruta) => {
            return acc + parseFloat(ruta.viajes);
        }, 0);

        const totalVentas = rutas.reduce((acc, ruta) => {
            return acc + parseFloat(ruta.ventas);
        }, 0);

        const totalDolares = rutas.reduce((acc, ruta) => {
            return acc + parseFloat(ruta.dolares);
        }, 0);

        const totalDepositos = rutas.reduce((acc, ruta) => {
            return acc + parseFloat(ruta.depositos);
        }, 0);

        rutas.sort((a, b) => {
            return b.ventas - a.ventas;
        });

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Producción por Zonas
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(query.desde).format("DD/MM/YYYY")} -{" "}
                        {dayjs(query.hasta).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>Zonas: {rutas.length}</div>

                        <div className='m-t-5 float-right'>
                            <h3>
                                <span className='m-r-25'>
                                    TOTAL CUADRES: {totalViajes}
                                </span>{" "}
                                <span className='m-r-25'>
                                    TOTAL VENTAS: {formatCurrency(totalVentas)}
                                </span>{" "}
                                <span className='m-r-25'>
                                    TOTAL DEPÓSITOS: {formatCurrency(totalDepositos)}
                                </span>{" "}
                                <span>
                                    TOTAL DÓLARES: {formatCurrency(totalDolares, 'USD$')}
                                </span>{" "}
                            </h3>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Zona</th>
                            <th>Mensajero</th>
                            <th className='text-center'>Cuadres</th>
                            <th className='text-right'>Ventas</th>
                            <th className='text-right'>Depósitos</th>
                            <th className='text-right'>Dólares</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rutas.map((ruta) => {
                            const choferes = ruta.tarjetas
                                .map((tarjeta) => {
                                    return (
                                        tarjeta.ficha +
                                        " - " +
                                        tarjeta.operadores[0].asignadoNombre
                                    );
                                })
                                .filter(
                                    (item, i, ar) => ar.indexOf(item) === i
                                );

                            return (
                                <tr key={ruta.id}>
                                    <td>
                                        <strong>{ruta.nombre}</strong>
                                        <div>
                                            <small>{ruta.descripcion}</small>
                                        </div>
                                    </td>
                                    <td>
                                        {choferes.map((chofer) => (
                                            <div key={chofer}>{chofer}</div>
                                        ))}
                                    </td>
                                    <td className='text-center'>
                                        {ruta.viajes}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(ruta.ventas)}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(ruta.depositos)}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(ruta.dolares, 'USD$')}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='m-t-25 row'>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>Zonas: {rutas.length}</div>

                        <div className='m-t-5 float-right'>
                            <h3>
                                <span className='m-r-25'>
                                    TOTAL CUADRES: {totalViajes}
                                </span>{" "}
                                <span className='m-r-25'>
                                    TOTAL VENTAS: {formatCurrency(totalVentas)}
                                </span>{" "}
                                <span className='m-r-25'>
                                    TOTAL DEPÓSITOS: {formatCurrency(totalDepositos)}
                                </span>{" "}
                                <span>
                                    TOTAL DÓLARES: {formatCurrency(totalDolares, 'USD$')}
                                </span>{" "}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
