import React, { Component } from "react";
import { postRequest, withRequest } from "../../../utils/request";
import { getLink } from "../../../utils/helpers";
import { apiUrl } from "../../../utils/apiUrl";

class ReporteNominaTss extends Component {
    state = {
        errors: {},
    };

    compararNomina = async (event) => {
        event.preventDefault();

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl("reportes/comparar-tss"), data);

        if (response.success) {
            //Guardar el CSV en localStorage para que tenga menos visibilidad
            window.localStorage.setItem('tss_file', response.data);

            const url = getLink("/imprimir/reportes?reporte=comparar-nomina-tss");
            window.open(url, '_blank').focus();

            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };

    render() {
        const {errors} = this.state;
        return (
            <form
                method='get'
                target='_blank'
                encType='multipart/form-data'
                onSubmit={e => this.compararNomina(e)}>
                <div className='popup__header'>Comparar Listado de Empleados con TSS</div>
                <div className='popup__body'>
                    <div>
                        <p className="m-b-20">Debes guardar el archivo generado por la TSS en formato CSV UTF-8.</p>
                        <input
                            type='file'
                            name='tss_file'
                        />
                        {errors.tss_file && (
                            <small className='help-blockParams form-Text'>
                                {errors.tss_file[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={this.props.toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Comparar
                    </button>
                </div>
            </form>
        );
    }
}

export default withRequest(ReporteNominaTss);
