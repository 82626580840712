import React, { Component } from "react";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatCurrency, formatNumber } from "../../utils/helpers";
import dayjs from "dayjs";
import Loader from "../general/Loader";
import { request } from "../../utils/request";

export default class ReporteNominas extends Component {
    state = {
        empleados: false,
        categorias: [],
        produccionOperadores: [],
        viajes: 0,
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {
        const response = await request(apiUrl(`reportes` + window.location.search));

        if (response.success) {
            this.setState({
                ...response.data
            });
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const {
            empleados,
            viajes,
            categorias,
            depositosCat,
        } = this.state;

        if (!empleados) {
            return <Loader />;
        }

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        const query = queryString.parse(window.location.search);

        const totalVentas = empleados.reduce((acc, empleado) => {
            return acc + parseFloat(empleado.ventas);
        }, 0);

        const totalDepositos = empleados.reduce((acc, empleado) => {
            return acc + parseFloat(empleado.depositos);
        }, 0);

        const totalDolares = empleados.reduce((acc, empleado) => {
            return acc + parseFloat(empleado.dolares);
        }, 0);


        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Reporte de Producción de Empleados{" "}
                        {query.contrato === "informal" ? "Informales" : ""}
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(query.desde).format("DD/MM/YYYY")} -{" "}
                        {dayjs(query.hasta).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            EMPLEADOS: {empleados.length}
                        </div>

                        <div className='float-right'>
                            <h3>
                                TOTAL INGRESOS:{" "}
                                {formatCurrency(
                                    totalVentas + totalDepositos
                                )}
                            </h3>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nombre</th>
                            <th>Cargo</th>
                            <th className='text-right'>Ventas</th>
                            <th className='text-right'>Depositos</th>
                            <th className='text-right'>Dólares</th>
                        </tr>
                    </thead>
                    <tbody>
                        {empleados.map((empleado, index) => {
                            return (
                                <tr key={index}>
                                    <td>{empleado.codigo}</td>
                                    <td>{empleado.nombre}</td>
                                    <td>{empleado.cargo}</td>
                                    <td className='text-right'>
                                        {formatCurrency(empleado.ventas)}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(
                                            parseFloat(empleado.depositos)
                                        )}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(empleado.dolares, 'USD$')}
                                    </td>
                                </tr>
                            );
                        })}
                        <tr style={{ fontWeight: "bold" }}>
                            <td></td>
                            <td></td>
                            <td>TOTALES =></td>
                            <td className='text-right'>
                                {formatCurrency(totalVentas)}
                            </td>
                            <td className='text-right'>
                                {formatCurrency(totalDepositos)}
                            </td>
                            <td className='text-right'>
                                {formatCurrency(totalDolares, 'USD$')}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {empleados.length > 0 && (
                    <div className='sumary m-t-70'>
                        <h3 className='text-center'>
                            Produccion por rango de fechas
                        </h3>
                        <table className='table text-center m-b-25'>
                            <thead>
                                <tr>
                                    <th width='33.33%'>
                                        Cantidad de Cuadres
                                    </th>
                                    <th width='33.33%'>
                                        Ventas Promedio por Cuadre
                                    </th>
                                    <th width='33.33%'>
                                        Total de INGRESOS
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{formatNumber(viajes)}</td>
                                    <td>
                                        {formatCurrency(
                                            (totalVentas + totalDepositos) / viajes
                                        )}
                                    </td>
                                    <td>
                                        {formatCurrency(totalVentas + totalDepositos)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className='table m-t-45'>
                            <tbody>
                                <tr>
                                    <td colSpan="4" className='text-center'>
                                        <strong>Producción por Categoría</strong>
                                    </td>
                                </tr>
                                {categorias.map((cat, index) => (
                                    <tr key={cat.nombre}>
                                        <td>
                                            <strong>{cat.nombre}</strong>
                                        </td>
                                        <td className='text-right'>
                                            <div>
                                                <strong>Ventas</strong>
                                            </div>
                                            <div>
                                                {formatCurrency(
                                                    cat.ventas
                                                )}
                                            </div>
                                        </td>
                                        <td className='text-right'>
                                            <div>
                                                <strong>Depósitos</strong>
                                            </div>
                                            <div>
                                                {formatCurrency(
                                                    depositosCat[index].depositos
                                                )}
                                            </div>
                                        </td>
                                        <td className='text-right'>
                                            <div>
                                                <strong>Dólares</strong>
                                            </div>
                                            <div>
                                                {formatCurrency(
                                                    cat.dolares, 'USD$'
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    }
}
