import React, { Component } from "react";
import { postRequest } from "../../../utils/request";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import { toast } from "../../../utils/helpers";
import Loader from "../../general/Loader";

class Edit extends Component {
    state = {
        errors: {}
    };
    editUser = async event => {
        event.preventDefault();

        this.setState({saving: true});

        const data = new FormData(event.target);

        const response = await postRequest(apiUrl(`academico/inscripciones/${this.props.inscripcion.id}`), data);

        if (response.success) {
            this.props.getInscripciones();
            this.props.toggle();
            toast("Evaluacion actualizado.");
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message, saving: false });
        }
    };
    deleteEvaluacion = async (event, inscripcion) => {
        event.preventDefault();

        this.setState({saving: true});

        const response = await postRequest(apiUrl(`academico/inscripciones/${inscripcion}`), 'delete');

        if (response.success) {
            this.props.getInscripciones();
            this.props.toggle();
            this.setState({saving: false});
            toast("Evaluacion borrada.");
            return;
        } else {
            this.setState({errors: response.data.message, saving: false})
            toast(response.data, "error");
        }
    };
    render() {
        const { errors, saving } = this.state;
        const { toggle, inscripcion } = this.props;
        return (
            <form method='post' onSubmit={this.editUser}>
                <input type='hidden' name='_method' value='patch' />
                <div className='popup__header'>Editar Inscripción</div>
                <div className='popup__body'>
                    <Form
                        editar
                        grados={this.props.grados}
                        periodosAcademicos={this.props.periodosAcademicos}
                        estudiantes={this.props.estudiantes}
                        tutores={this.props.tutores}
                        productos={this.props.productos}
                        salidasOptativas={this.props.salidasOptativas}
                        becas={this.props.becas}
                        gruposPagos={this.props.gruposPagos}
                        inscripcion={inscripcion}
                        errors={errors}
                    />
                </div>
                <div className='popup__footer'>
                    {!saving && (
                        <button
                            type='button'
                            className='btn float-left'
                            onClick={e => this.deleteEvaluacion(e, inscripcion.id)}>
                            <i className='fas fa-trash-alt'></i>
                        </button>
                    )}
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {saving && <Loader type="small"/>}
                    {!saving && (
                        <button type='submit' className='btn btn-primary'>
                            Guardar
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

export default Edit;
