import React, { Component } from "react";
import Select from "react-select";

import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import { toast, formatCurrency } from "../../../utils/helpers";
import Modal from "../../general/Modal";
import { postRequest } from "../../../utils/request";

class Tarjeta extends Component {
    state = {
        saving: false,
        chofer: false,
        ayudante: false,
        showDropdown: false,
        showModal: false,
        matchTipo: /(chofer|mensajero)/i,
    };

    constructor(props) {
        super(props);

        this.form = React.createRef();
    }

    componentDidMount() {
        const matchTipo = this.state.matchTipo;
        const chofer = this.props.tarjeta.operadores.filter(
            (op) => matchTipo.test(op.nombre)
        );

        this.setState({
            tarjeta: this.props.tarjeta,
            chofer: chofer[0] ? chofer[0].asignadoId : null,
            operadores: this.props.tarjeta.operadores.filter(
                (op) => op.asignadoId
            ),
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const tarjeta = nextProps.tarjeta;
        const matchTipo = this.state.matchTipo;
        const chofer = tarjeta.operadores.filter(
            (op) => matchTipo.test(op.nombre)
        );
        this.setState({
            tarjeta: tarjeta,
            chofer: chofer[0] ? chofer[0].asignadoId : null,
            operadores: tarjeta.operadores.filter((op) => op.asignadoId),
        });
    }

    updateTarjeta = async (event, progId, id) => {
        event.preventDefault();

        this.setState({ saving: true });


        const data = new FormData(event.target);

        const url = "programaciones/" + progId + "/tarjetas/" + id;

        const response = await postRequest(apiUrl(url), data);



        if (response.success) {
            this.setState({
                saving: false,
            });

            this.props.updateTarjetas(response.data);
        }
    };

    cerrarTarjeta = async (progId, id) => {
        this.setState({ showDropdown: false });


        let data = new FormData();
        data.append("_method", "PATCH");
        data.append("activa", 0);

        const url = "programaciones/" + progId + "/tarjetas/" + id + "/cerrar";

        const response = await postRequest(apiUrl(url), data);



        if (response.success) {
            this.setState({
                tarjeta: response.data,
                saving: false,
            });

            this.props.updateTarjetas(response.data);
        }
    };

    abrirTarjeta = async (event, progId, id) => {
        event.preventDefault();

        if (!auth.can("abrir-tarjetas")) {
            toast(
                "Debes pedir a un administrador que abra la tarjeta.",
                "error"
            );
            return;
        }

        this.setState({ saving: true, showDropdown: false });


        let data = new FormData();
        data.append("_method", "PATCH");
        data.append("activa", 1);

        const url = "programaciones/" + progId + "/tarjetas/" + id + "/abrir";

        const response = await postRequest(apiUrl(url), data);



        if (response.success) {
            this.setState({
                tarjeta: response.data,
                saving: false,
            });

            this.props.updateTarjetas(response.data);
        }
    };

    despacharTarjeta = async (event, progId, tarjeta) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });

        if (!this.state.chofer) {
            this.setState({
                saving: false,
            });
            toast("Debes completar la tarjeta para poder despachar.", "error");

            return;
        }

        this.cambiarEstado("despachada", progId, tarjeta.id);
    };

    noTrabajo = (e, progId, tarjeta) => {
        e.preventDefault();

        this.setState({
            saving: true,
        });

        if (tarjeta.noTrabajo) {
            this.cambiarEstado("abierta", progId, tarjeta.id);
        } else {
            this.cambiarEstado("no-trabajo", progId, tarjeta.id);
            this.cerrarTarjeta(e, progId, tarjeta.id);
        }
    };

    cambiarEstado = async (estado, progId, tarjeta) => {

        let data = new FormData();
        data.append("_method", "PATCH");
        data.append("estado", estado);

        const url = "programaciones/" + progId + "/tarjetas/" + tarjeta;

        const response = await postRequest(apiUrl(url), data);



        if (response.success) {
            this.setState({
                tarjeta: response.data,
                saving: false,
            });

            this.props.updateTarjetas(response.data);
        }
    };

    removeTarjeta = async (event, progId, id) => {
        event.preventDefault();

        this.setState({ showDropdown: false });



        const url = "programaciones/" + progId + "/tarjetas/" + id;

        const response = await postRequest(apiUrl(url), {}, 'delete');



        if (response.success) {
            this.props.getProgramacion();
            return;
        } else {
            toast("Debes borrar todos los tickets primero.", "error");
            this.setState({ errors: response.data.message });
        }
    };

    submitForm = (e) => {
        setTimeout(() => {
            var evObj = document.createEvent("Events");
            evObj.initEvent("submit", true, false);
            this.form.current.dispatchEvent(evObj);
        }, 0);
    };

    showDropdown = () => {
        this.setState({
            showDropdown: !this.state.showDropdown,
        });
    };

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    hideDropdown = (e) => {
        if (this.dropdown && this.dropdown.contains(e.target)) {
            return;
        }
        this.setState({
            showDropdown: false,
        });
    };

    UNSAFE_componentWillMount() {
        document.addEventListener("mousedown", this.hideDropdown, false);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.hideDropdown, false);
    }

    fueDespachado = (id) => {
        return (
            this.props.empleadosDespachados.indexOf(id) > -1 &&
            this.state.tarjeta.estado !== "despachada"
        );
    };

    render() {
        const { empleados, index, programacion } = this.props;
        const { tarjeta, showDropdown, saving, showModal, matchTipo } = this.state;

        if (!tarjeta) return <Loader />;

        const asignados = tarjeta.operadores.map((op) => op.asignadoId);

        const empleadosNoAsignados = empleados.filter((emp) => {
            return !asignados.includes(emp.id);
        });

        return (
            <div
                style={{ animationDelay: index * 50 + "ms" }}
                id={tarjeta.id}
                className='asignacion tarjeta'>
                <form
                    ref={this.form}
                    onSubmit={(e) =>
                        this.updateTarjeta(e, programacion, tarjeta.id)
                    }>
                    <input type='hidden' name='_method' value='PATCH' />
                    <input
                        type='hidden'
                        name='categoria_id'
                        value={tarjeta.categoriaId}
                    />
                    <input
                        type='hidden'
                        name='vehiculo_id'
                        value={tarjeta.vehiculoId}
                    />
                    <input
                        type='hidden'
                        name='ruta_id'
                        value={tarjeta.rutaId}
                    />
                    <div className='asignacion-header row'>
                        <div className='ficha'>{tarjeta.ficha}</div>
                        {tarjeta.ruta && (
                            <div className='ruta'>
                                {tarjeta.ruta.nombre}{" "}
                                <small
                                    className='ruta-descripcion'
                                    title={tarjeta.ruta.descripcion}>
                                    ({tarjeta.ruta.descripcion})
                                </small>
                                {tarjeta.meta && (
                                    <span className='meta'>
                                        Meta{" "}
                                        <span className='separator'>»</span>{" "}
                                        {formatCurrency(tarjeta.meta)}
                                    </span>
                                )}
                            </div>
                        )}
                        {!tarjeta.activa && (
                            <button
                                className='btn'
                                type='button'
                                onClick={(e) =>
                                    this.abrirTarjeta(
                                        e,
                                        programacion,
                                        tarjeta.id
                                    )
                                }
                                style={{ color: "red" }}>
                                <i className='zmdi zmdi-lock'></i>
                            </button>
                        )}
                        {tarjeta.activa && (
                            <div
                                className={
                                    "asignacion-menu " +
                                    (showDropdown ? "active" : "")
                                }>
                                <button
                                    onClick={this.showDropdown}
                                    type='button'>
                                    <i className='zmdi zmdi-menu'></i>
                                </button>
                                <div
                                    ref={(dropdown) =>
                                        (this.dropdown = dropdown)
                                    }
                                    className='dropdown'>
                                    <ul>
                                        {tarjeta.activa && (
                                            <li>
                                                <button
                                                    type='button'
                                                    onClick={() => {
                                                        this.showDropdown();
                                                        this.props.editar(
                                                            tarjeta
                                                        );
                                                    }}>
                                                    <i className='zmdi zmdi-edit'></i>{" "}
                                                    Editar
                                                </button>
                                            </li>
                                        )}
                                        {!tarjeta.despachada && (
                                            <li>
                                                <button
                                                    type='button'
                                                    onClick={(e) =>
                                                        this.noTrabajo(
                                                            e,
                                                            programacion,
                                                            tarjeta
                                                        )
                                                    }>
                                                    <i className='zmdi zmdi-block'></i>{" "}
                                                    {!tarjeta.noTrabajo && "No"}{" "}
                                                    Trabajó
                                                </button>
                                            </li>
                                        )}
                                        <li>
                                            <button
                                                type='button'
                                                onClick={this.toggleModal}>
                                                <i className='zmdi zmdi-lock'></i>{" "}
                                                Cerrar
                                            </button>
                                        </li>
                                        {tarjeta.tickets.length === 0 && (
                                            <li>
                                                <button
                                                    type='button'
                                                    onClick={(e) =>
                                                        this.removeTarjeta(
                                                            e,
                                                            programacion,
                                                            tarjeta.id
                                                        )
                                                    }>
                                                    <i className='zmdi zmdi-delete'></i>{" "}
                                                    Borrar
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='asignacion-body row'>
                        <div className='operadores row'>
                            {tarjeta.operadores.map((operador) => (
                                <div key={operador.id} className='operador'>
                                    <div className='label'>
                                        {operador.nombre}
                                    </div>
                                    <div className='empleado'>
                                        {tarjeta.activa ? (
                                            <Select
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={this.submitForm}
                                                name={`operadores[${operador.id}][empleado_id]`}
                                                key={
                                                    operador.id +
                                                    this.fueDespachado(
                                                        operador.asignadoId
                                                    )
                                                }
                                                defaultValue={
                                                    !this.fueDespachado(
                                                        operador.asignadoId
                                                    ) &&
                                                    operador.asignadoId && {
                                                        isFixed: true,
                                                        value:
                                                            operador.asignadoId,
                                                        label:
                                                            operador.asignadoNombre,
                                                    }
                                                }
                                                options={empleadosNoAsignados
                                                    .filter((empleado) => {
                                                        if (matchTipo.test(operador.nombre)) {
                                                            return (
                                                                empleado.operaVehiculo
                                                            );
                                                        }
                                                        return (
                                                            empleado.cargo &&
                                                            empleado.cargo
                                                                .toLowerCase()
                                                                .indexOf(
                                                                    "ayudante"
                                                                ) > -1
                                                        );
                                                    })
                                                    .map((empleado) => ({
                                                        value: empleado.id,
                                                        label:
                                                            empleado.nombreCompleto,
                                                    }))}
                                            />
                                        ) : (
                                            operador.asignadoNombre
                                        )}
                                    </div>
                                </div>
                            ))}
                            {tarjeta.operadores && (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{width: 180}}>
                                                <strong>Celular: </strong>
                                                {tarjeta.operadores[0]?.asignadoTelefono || 'N/A' }
                                            </td>
                                            <td style={{width: 150}}>
                                                <strong>{formatCurrency(tarjeta.giTicket?.total)}</strong>
                                            </td>
                                            <td><small>Depósitos:</small></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Flota: </strong>
                                                {tarjeta.operadores[0]?.asignadoFlota || 'N/A' }
                                            </td>
                                            <td>
                                                <strong>{formatCurrency(tarjeta.giTicket?.dolares, 'USD$')}</strong>
                                            </td>
                                            <td>
                                                <strong>{formatCurrency(tarjeta.giTicket?.depositos?.reduce((a,i) => a+parseFloat(i.monto), 0))}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>
                        {saving && (
                            <div className='tickets-btn-wrap'>
                                <Loader type='small' />
                            </div>
                        )}
                        {tarjeta.despachada &&
                            !tarjeta.noTrabajo &&
                            tarjeta.activa &&
                            !saving && (
                                <div className='tickets-btn-wrap'>
                                    <button
                                        type='button'
                                        onClick={this.props.clickFicha}
                                        className='tickets-btn'>
                                        Editar <i className="zmdi zmdi-edit"></i>
                                    </button>
                                </div>
                            )}

                        {tarjeta.activa === false &&
                            !saving && (
                                <div className='tickets-btn-wrap'>
                                    <button
                                        type='button'
                                        onClick={this.props.clickFicha}
                                        className='tickets-btn'>
                                        Ver
                                    </button>
                                </div>
                            )}
                        {/* {!tarjeta.activa && !saving && (
                            <div className='tickets-btn-wrap'>
                                <button type='button' className='tickets-btn'>
                                    <span className='count'>
                                        {formatCurrency(tarjeta.toneladas)}
                                        <small>Ventas</small>
                                    </span>
                                </button>
                            </div>
                        )} */}
                        {tarjeta.noTrabajo && !saving && (
                            <div className='tickets-btn-wrap'>
                                <div className='tickets-label'>No Trabajó</div>
                            </div>
                        )}
                        {!tarjeta.despachada && !tarjeta.noTrabajo && !saving && (
                            <button
                                type='button'
                                onClick={(e) =>
                                    this.despacharTarjeta(
                                        e,
                                        programacion,
                                        tarjeta
                                    )
                                }
                                className='tickets-btn btn-despachar au-btn--green m-r-10'>
                                <span className='count'>Despachar</span>
                            </button>
                        )}
                    </div>
                </form>
                <Modal
                    show={showModal}
                    title='Cerrar Tarjeta'
                    toggle={this.toggleModal}
                    callback={(e) =>
                        this.cerrarTarjeta(programacion, tarjeta.id)
                    }>
                    ¿Seguro que quieres cerrar esta tarjeta?
                </Modal>
            </div>
        );
    }
}

export default Tarjeta;
