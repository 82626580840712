import React, { Component } from "react";
import { getCurrentMonthName, getMiddleDate } from "../../utils/helpers";
import dayjs from "dayjs";
import Select from "react-select";
import SelectAsync from "react-select/async";
import { withRequest } from "../../utils/request";

class NavidadForm extends Component {
    state = {
        nombre: '',
        tipo: 'FIJOS',
        grupos: [],
        nombreGrupos: '',
        periodo: '',
        categoriaEmpleados: '',
        tipoEmpleados: '',
        tipoEmpleadosId: '',
    };
    onChangeTipo = async (e) => {
        const tipo = e.target.value;

        await this.setState({
            tipo: tipo,
            periodo: tipo === 'FIJOS' ? 'quincenal' : 'mensual',
        });

        this.setState({
            nombre: this.getNombre(tipo, dayjs()),
        });

    };

    onChangeEmpleados = async (values) => {
        if (!values) {
            await this.setState({
                tipoEmpleados: '',
                tipoEmpleadosId: '',
            });
        } else {
            const label  = values.map(v => v.label).join(', ');
            const value  = values.map(v => v.value).join(',');

            await this.setState({
                tipoEmpleados: label,
                tipoEmpleadosId: value,
            });
        }

        this.setState({
            nombre: this.getNombre(),
        });
    };

    getNombre = () => {
        const { tipoEmpleados, nombreGrupos, categoriaEmpleados } = this.state;
        const year = dayjs().year();
        let nombre = '';

        if (nombreGrupos) {
            nombre += nombreGrupos;
        }

        if (tipoEmpleados) {
            if (nombreGrupos) {
                nombre += ' - ' + tipoEmpleados;
            } else {
                nombre += tipoEmpleados;
            }
        }

        if (categoriaEmpleados) {
            nombre += ` (${categoriaEmpleados})`
        }

        console.log({categoriaEmpleados});

        return (
            `Salario Navidad ${year} - ${nombre}`
        );
    };

    onChangeNombre = (e) => {
        this.setState({
            nombre: e.target.value,
        });
    };

    onChangeGrupos = async (e) => {
        if (e === null) {
            await this.setState({
                grupos: [],
                nombreGrupos: '',
            });
        } else {
            await this.setState({
                grupos: e.map(v => v.value),
                nombreGrupos: e.map(v => v.label).join(', '),
            });
        }

        this.setState({
            nombre: this.getNombre(),
        });
    };
    onChangePeriodo = async (e) => {
        await this.setState({
            periodo: e.target.value,
        });

        this.setState({
            nombre: this.getNombre(),
        })
    };

    onChangeCategoriaEmpleados = async (e) => {
        if (e === null) {
            await this.setState({
                categoriaEmpleados: '',
            });
        } else {
            await this.setState({
                categoriaEmpleados: e.map(v => v.label).join(', '),
            });
        }

        this.setState({
            nombre: this.getNombre(),
        });
    };

    getOptions = async (url = null, label = "nombre", value = "id") => {
		const table = await this.props.request(url);
		if (table.data) {
			return table.data.map((item) => ({
				value: item[value],
				label: item[label],
			}));
		}
	};

    render() {
        const { nombre, tipo, grupos, periodo, tipoEmpleadosId } = this.state;
        const { errors, tiposEmpleados, gruposEmpleados } = this.props;
        const tiposSeleccionados = tiposEmpleados
            .map((t) => {
                return {
                    label: t.nombre,
                    value: t.id,
                };
            });

        const gruposDisponibles = gruposEmpleados.filter(g => {
            return g.periodo === periodo;
        });

        return (
            <div>
                <input type="hidden" name="tipo" value='NAVIDAD' />

                <input type="hidden" name="fecha_inicio" value={dayjs().startOf('year').format('YYYY-MM-DD')} />
                <input type="hidden" name="fecha_cierre" value={dayjs().endOf('year').subtract(1, 'month').format('YYYY-MM-DD')} />

                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Periodo:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <select
                            name='periodo'
                            className="form-control"
                            required
                            onChange={this.onChangePeriodo}
                            value={periodo}
                        >
                            <option value="">Seleccionar...</option>
                            <option value="semanal">Semanal</option>
                            <option value="quincenal">Quincenal</option>
                            <option value="mensual">Mensual</option>
                        </select>
                        {errors.periodo && (
                            <small className='help-blockParams form-Text'>
                                {errors.periodo[0]}
                            </small>
                        )}
                    </div>
                </div>

                {gruposDisponibles.length > 0 && (
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Grupos de Empleados:
                            </label>
                        </div>
                        <div className='col col-md-8'>
                            <Select
                                isMulti
                                onChange={this.onChangeGrupos}
                                options={[
                                    {
                                        label: gruposEmpleados.length > 0 ? "Todos" : 'No hay empleados',
                                        value: gruposEmpleados.length > 0 ? "todos" : '',
                                    },
                                    ...gruposDisponibles.map(g => ({label: g.nombre, value: g.id})),
                                ]}
                            />
                            <input type="hidden" name="grupo_empleados" value={grupos} />
                            {errors.grupo_empleados && (
                                <small className='help-blockParams form-Text'>
                                    {errors.grupo_empleados[0]}
                                </small>
                            )}
                        </div>
                    </div>
                )}

                {periodo && tipo && tiposSeleccionados.length > 0 && (
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Tipo Empleados:
                            </label>
                        </div>
                        <div className='col col-md-8'>
                            <Select
                                isMulti
                                onChange={this.onChangeEmpleados}
                                options={[
                                    {
                                        label: tiposSeleccionados.length > 0 ? "Todos" : 'No hay empleados',
                                        value: tiposSeleccionados.length > 0 ? "todos" : '',
                                    },
                                    ...tiposSeleccionados,
                                ]}
                            />
                            <input type="hidden" name="tipo_empleados" value={tipoEmpleadosId}/>
                            {errors.tipo && (
                                <small className='help-blockParams form-Text'>
                                    {errors.tipo[0]}
                                </small>
                            )}
                        </div>
                    </div>
                )}
                {periodo && tiposSeleccionados.length === 0 && (
                    <div className="nota-formulario">No hay empleados disponibles en el periodo seleccionado.</div>
                )}

                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Categoría Empleados:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <SelectAsync
                            name="categorias[]"
                            isMulti
                            cacheOptions
                            defaultOptions
                            onChange={this.onChangeCategoriaEmpleados}
                            loadOptions={(e) => {
                                return this.getOptions(
                                    "taxonomias?relacion=empleados&buscar=" + e
                                );
                            }}
                        />
                    </div>
                </div>

                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Nombre:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='nombre'
                            name='nombre'
                            onChange={this.onChangeNombre}
                            value={nombre}
                        />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRequest(NavidadForm);
