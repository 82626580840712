import React, { useEffect, useLayoutEffect, useState } from 'react';
import { apiUrl } from '../../../utils/apiUrl';
import { auth } from '../../../utils/auth';
import { toast } from '../../../utils/helpers';
import { postRequest, request } from '../../../utils/request';
import Loader from '../../general/Loader';
import Modal from '../../general/Modal';
import FormPagosEducativos from './Forms/FormPagosEducativos';
import FormGruposPagos from './Forms/FormGruposPagos';
import { apiRequest } from '../../../hooks/apiRequest';

const PagosEducativos = () => {
    const [state, setState] = useState({
        showCreate: false,
        showEdit: false,
        showGrupos: false,
        item: {},
        loading: true,
        saving: false,
        errors: {},
    });
    const [items, setItems] = useState([]);
    const [gruposPagos, setGruposPagos] = useState([]);
    const [grados, setGrados] = useState([]);
    const [filtrosQuery, setFiltrosQuery] = useState("");
	const [filtros, setFiltros] = useState({});
    const [showDelete, setShowDelete] = useState(false);

    const toggleCreateModal = () => {
        setState(prevState => ({
            ...state,
            errors: {},
            showCreate: !prevState.showCreate,
        }));
    };

    const toggleGruposPagosModal = () => {
        setState(prevState => ({
            ...state,
            showGrupos: !prevState.showGrupos,
        }));
    };

    const toggleEditModal = (item = null) => {
        if (item) {
            setState(prevState => ({
                ...state,
                item: item,
                errors: {},
                showEdit: !prevState.showEdit,
            }));
        } else {
            setState(prevState => ({
                ...state,
                errors: {},
                showEdit: !prevState.showEdit,
            }));
        }
    };

    const toggleDeleteModal = () => {
        setShowDelete(prev => !prev);
    }

    const getData = (url) => {
        setState({...state, loading: true});
        request(url).then((res) => {
			setItems(res.data);
            setState((prevState) => ({...prevState, loading: false}));
		});
    };

    const create = async (event) => {
        event.preventDefault();
        setState(prevState => ({...prevState, saving: true}));

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl("academico/pagos-educativos"), data);

        if (response.success) {
            getData('academico/pagos-educativos', 'items');
            toggleCreateModal();
            toast("Servicio Educativo creado.");
        }
        if (response.code === 422) {
            setState(prevState => ({...prevState, errors: response.data.message}));
        }

        setState(prevState => ({...prevState, saving: false}));
    };

    const edit = async (event) => {
        event.preventDefault();
        setState({...state, saving: true});
        const {item} = state;


        const data = new FormData(event.target);

        const response = await postRequest(apiUrl(`academico/pagos-educativos/${item.id}`), data);



        if (response.success) {
            getData('academico/pagos-educativos', 'items');
            toggleEditModal();
            toast("Pago Educativo actualizado.");
        }

        if (response.code === 422) {
            setState(prevState => ({ ...prevState, errors: response.data.message }));
            toast("No se pudo procesar el pedido.", "error");
        }

        setState(prevState => ({...prevState, saving: false}));
    };

    const editGruposPagos = async (event) => {
        event.preventDefault();
        setState({...state, saving: true});

        const data = new FormData(event.target);

        const response = await apiRequest(data, 'configuraciones');

        if (response.success) {
            getConfig();
            toggleGruposPagosModal();
            toast("Servicio Educativo actualizado.");
        }

        if (response.code === 422) {
            setState(prevState => ({ ...prevState, errors: response.data.message }));
            toast("No se pudo procesar el pedido.", "error");
        }

        setState(prevState => ({...prevState, saving: false}));
    };

    const getConfig = async () => {
        const url = "configuraciones?seccion=academica_grupos-pagos";
        const configuraciones = await apiRequest({}, url, 'get');
        const gpagos = JSON.parse(configuraciones.data["grupos-pagos"]);
        setGruposPagos(Object.keys(gpagos).map(g => gpagos[g]));
    }

    const getGrados = async () => {
        const grados = await apiRequest({}, "academico/grados", 'get');
        setGrados(grados.data);
    }

    const deleteItem = async () => {
        setState(prevState => ({...prevState, saving: true}));


        const {item} = state;
        const url = `academico/pagos-educativos/${item.id}`;

        const response = await postRequest(apiUrl(url), {}, 'delete');



        if (response.success) {
            getData('academico/pagos-educativos', 'items');
            setState(prevState => ({...prevState, saving: false}));
            toggleEditModal();
            toggleDeleteModal();
            toast("Servicio Educativo borrado.");
        } else {
            setState(prevState => ({...prevState, saving: false}));
            toast(response.data, "error");
        }
    };

    const onFiltroChange = async (value, filtro) => {
		setFiltros((filtros) => ({
			...filtros,
			[filtro]: value,
		}));
	};

    useEffect(() => {
        getData(`academico/pagos-educativos${filtrosQuery}`, 'items');
        getConfig();
        getGrados();
    }, [filtrosQuery]);

    useLayoutEffect(() => {
		let query = Object.keys(filtros).map((fil) => {
			return `${fil}=${filtros[fil]}`;
		});

		setFiltrosQuery("?" + query.join("&"));
	}, [filtros]);

    const {showCreate, showEdit, showGrupos, item, loading, errors, saving} = state;

    const clasesEstadoMapping = {
        activo: 'success',
        pendiente: 'warning',
        cerrado: 'danger',
        inactivo: 'gray',
    }

    return (<>
        <div className="row m-b-20 descripcion-subpage">
            <div className="col">
                <input
                    type="text"
                    className='form-control'
                    name="buscar"
                    onChange={(e) =>
                        onFiltroChange(e.target.value, "buscar")
                    }
                    placeholder='Buscar...'
                />
            </div>
            <div className="col text-right">
                <button className="au-btn au-btn-icon au-btn-filter uppercase m-r-15" onClick={() => toggleGruposPagosModal()}><i className='zmdi zmdi-settings'></i> Grupos de Pagos</button>
                <button className="au-btn au-btn-icon au-btn--green au-btn--small" onClick={() => toggleCreateModal()}>+ Nuevo Servicio Educativo</button>
            </div>
        </div>
        <hr />
        {loading && <Loader />}
        {!loading && items.length > 0 && (<div className="table-data">
            <table className='table table-data2'>
                <thead>
                    <tr>
                        <th>Servicio Educativo</th>
                        <th className='text-center'>Estado</th>
                        <th className='text-right'></th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item => (
                        <tr key={item.id} className="tr-shadow">
                            <td>{item.nombre}</td>
                            <td className='text-center'>
                                <span className={`badge badge-${clasesEstadoMapping[item.estado]} text-uppercase`}>{item.estado}</span>
                            </td>
                            <td className='text-right'>
                                <div className="table-data-feature">
                                    <button
                                        className="item"
                                        onClick={() => toggleEditModal(item)}
                                    >
                                        <i className="zmdi zmdi-edit" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )))}
                </tbody>
            </table>
        </div>)}

        {!loading && items.length === 0 && (
            <div className='text-center p-t-20 p-b-40'>{ filtrosQuery !== '?' ? "No hay elementos que coincidan con la búsqueda." : "No hay elementos creados." }</div>
        )}

        <Modal
            blank
            show={showCreate}
            size="1048px"
            toggle={() => toggleCreateModal()}>
            <form method='post' onSubmit={e => create(e) }>
                <input type="hidden" name="_method" value="POST" />
                <div className='popup__header'>Crear Nuevo Servicio Educativo</div>
                <div className='popup__body'>
                    <input type='hidden' name='estado' value='activo' />
                    <FormPagosEducativos
                        item={{}}
                        gruposPagos={gruposPagos}
                        grados={grados}
                        errors={errors}
                    />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={() => toggleCreateModal()}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {saving ? (<Loader type="small" />) : (<button type='submit' className='btn btn-primary'>
                        Crear
                    </button>)}
                </div>
            </form>
        </Modal>
        <Modal
            blank
            show={showEdit}
            size="1048px"
            toggle={toggleEditModal}>
            <form method='post' onSubmit={e => edit(e) }>
                <input type="hidden" name="_method" value="PATCH" />
                <div className='popup__header'>Editar Servicio Educativo <div className='float-right'>
                        <div className='text-right'>
                            <span className='m-r-15'>Activo:</span>
                            <label className='switch switch-3d switch-success mr-3'>
                                <input type='hidden' name='estado' value='inactivo' />
                                <input
                                    key={item.id}
                                    type='checkbox'
                                    className='switch-input'
                                    name='estado'
                                    value='activo'
                                    defaultChecked={
                                        item.estado === 'activo'
                                    }
                                />
                                <span className='switch-label' />
                                <span className='switch-handle' />
                            </label>
                        </div>
                        {errors.estado && (
                            <small className='help-blockParams form-Text'>
                                {errors.estado[0]}
                            </small>
                        )}
                    </div></div>
                <div className='popup__body'>
                    <FormPagosEducativos
                        item={item}
                        gruposPagos={gruposPagos}
                        grados={grados}
                        errors={errors}
                    />
                </div>
                <div className='popup__footer'>
                    {!saving && (
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={toggleDeleteModal}>
                        <i className='fas fa-trash-alt'></i>
                    </button>)}
                    <button
                        type='button'
                        onClick={() => toggleEditModal()}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {saving ? (<Loader type="small" />) : (<button type='submit' className='btn btn-primary'>
                        Actualizar
                    </button>)}
                </div>
            </form>
        </Modal>
        <Modal
            blank
            show={showGrupos}
            toggle={toggleGruposPagosModal}>
            <form method='post' onSubmit={e => editGruposPagos(e) }>
                <div className='popup__header'>Grupos de Pagos</div>
                <div className='popup__body'>
                    <FormGruposPagos
                        item={item}
                        gpagos={gruposPagos}
                        errors={errors}
                    />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={() => toggleGruposPagosModal()}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {saving ? (<Loader type="small" />) : (<button type='submit' className='btn btn-primary'>
                        Actualizar
                    </button>)}
                </div>
            </form>
        </Modal>
        <Modal
            title='Borrar Servicio Educativo'
            show={showDelete}
            callback={deleteItem}
            acceptText="Borrar"
            toggle={toggleDeleteModal}>
            ¿Seguro que deseas borrar {item.nombre}?
        </Modal>
    </>)
}

export default PagosEducativos;
