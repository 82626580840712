import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import { toast } from "../../../utils/helpers";
import { postRequest } from "../../../utils/request";

class Edit extends Component {
    state = {
        errors: {}
    };
    editUser = async event => {
        event.preventDefault();

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl(`almacenes/${this.props.almacen.id}`), data);

        if (response.success) {
            this.props.getAlmacenes();
            this.props.toggle();
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    deleteAlmacen = async (event, almacen) => {
        event.preventDefault();



        const url = `almacenes/${almacen}`;

        const response = await postRequest(apiUrl(url), {}, 'delete');



        if (response.success) {
            this.props.getAlmacenes();
            this.props.toggle();
            toast("Almacen borrado.");
            return;
        } else {
            toast(response.data, "error");
        }
    };
    render() {
        const { errors } = this.state;
        const { toggle, almacen } = this.props;
        return (
            <form method='post' onSubmit={this.editUser}>
                <input type='hidden' name='_method' value='patch' />
                <div className='popup__header'>Editar Almacen</div>
                <div className='popup__body'>
                    <Form almacen={almacen} errors={errors} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={e => this.deleteAlmacen(e, almacen.id)}>
                        <i className='fas fa-trash-alt'></i>
                    </button>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

export default Edit;
