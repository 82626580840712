import React, { Component } from "react";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { postRequest, withRequest } from "../../utils/request";
import {
    toast,
    formatCurrency,
    isMecanico,
    getLink,
} from "../../utils/helpers";
import { apiUrl } from "../../utils/apiUrl";
import Loader from "../general/Loader";
import dayjs from "dayjs";
import GastoItem from "./GastoItem";

class CrearGastos extends Component {
    state = {
        errors: {},
        compra: {},
        vehiculo: "",
        vehiculos: [],
        items: [],
        empleados: [],
        mecanico: "",
        gastoItems: [{ id: Date.now(), subtotal: 0 }],
        total: 0,
        fecha: dayjs().format("YYYY-MM-DD"),
        saving: false,
    };
    componentDidMount() {
        this.getTable("vehiculos");
        this.getTable("items");
        this.getTable("empleados");
    }
    getTable = async (url = null) => {
        const table = await this.props.request(url);

        if (table.data) {
            this.setState({
                [url]: table.data,
                loading: false,
            });
        }
    };
    createGastos = async (event) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl("gastos"), data);

        if (response.success) {
            this.props.history.push(getLink("/gastos"));
            this.setState({
                saving: false,
            });
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message, saving: false });
            toast("Por favor revisa todos los campos", "error");
        }
    };
    addItem = () => {
        this.setState({
            gastoItems: [...this.state.gastoItems, { id: Date.now() }],
        });
    };
    removeGasto = (item) => {
        this.setState({
            gastoItems: this.state.gastoItems.filter((i) => i.id !== item),
        });
    };
    updateTotal = async (id, value) => {
        await this.setState({
            gastoItems: this.state.gastoItems.map((item) => {
                if (item.id === id) {
                    item.subtotal = value;
                }
                return item;
            }),
        });

        this.setState({
            total: this.state.gastoItems.reduce((accum, item) => {
                return accum + item.subtotal;
            }, 0),
        });
    };
    onChangeDate = (e) => {
        this.setState({
            fecha: e.target.value,
        });
    };
    onChangeInput = async (name, value) => {
        await this.setState({
            [name]: value,
        });
    };
    render() {
        const {
            errors,
            gastoItems,
            items,
            vehiculo,
            mecanico,
            empleados,
            vehiculos,
            total,
            saving,
            fecha,
        } = this.state;

        const mecanicos = empleados.filter(
            (empleado) => empleado.active && isMecanico(empleado.cargo)
        );

        const query = queryString.parse(window.location.search);

        return (
            <div className='crear-compra'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Añadir Gastos</span>
                    </h1>
                </div>
                <form
                    method='post'
                    onSubmit={this.createGastos}
                    onKeyUp={this.preventEnter}
                    className='row'>
                    <div className='form-group col-md-4'>
                        <label className='label form-control-label'>
                            Fecha:
                        </label>
                        <input
                            type='date'
                            className='text-input form-control'
                            id='fecha'
                            name='fecha'
                            value={fecha}
                            onChange={this.onChangeDate}
                        />
                        {errors.fecha && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha[0]}
                            </small>
                        )}
                    </div>
                    <div className='form-group col-md-4'>
                        <label className='label form-control-label'>
                            Vehículo:
                        </label>
                        {vehiculos.length > 0 && (
                            <Select
                                defaultValue={vehiculos
                                    .filter(
                                        (vehiculo) =>
                                            vehiculo.id ===
                                            parseInt(query.vehiculo)
                                    )
                                    .map((vehiculo) => ({
                                        label: vehiculo.ficha,
                                        value: vehiculo.id,
                                    }))}
                                options={vehiculos.map((vehiculo) => ({
                                    label: vehiculo.ficha,
                                    value: vehiculo.id,
                                }))}
                                onChange={(e) => {
                                    if (!e) e = {};
                                    this.onChangeInput("vehiculo", e.value);
                                }}
                                required
                                name={`vehiculo_id`}
                                isClearable={true}
                            />
                        )}
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                    <div className='form-group col-md-4'>
                        <label className='label form-control-label'>
                            Mecánico:
                        </label>
                        {mecanicos.length > 0 && (
                            <Select
                                defaultValue={mecanicos
                                    .filter(
                                        (mecanico) =>
                                            mecanico.id ===
                                            parseInt(query.mecanico)
                                    )
                                    .map((mecanico) => ({
                                        label:
                                            mecanico.nombre.split(" ")[0] +
                                            " " +
                                            mecanico.apellido[0] +
                                            ".",
                                        value: mecanico.id,
                                    }))}
                                options={mecanicos.map((mecanico) => ({
                                    label:
                                        mecanico.nombre.split(" ")[0] +
                                        " " +
                                        mecanico.apellido[0] +
                                        ".",
                                    value: mecanico.id,
                                }))}
                                onChange={(e) => {
                                    if (!e) e = {};
                                    this.onChangeInput("mecanico", e.value);
                                }}
                                name={`mecanico_id`}
                                isClearable={true}
                            />
                        )}
                        {errors.mecanico && (
                            <small className='help-blockParams form-Text'>
                                {errors.mecanico[0]}
                            </small>
                        )}
                    </div>
                    <div className='col-md-12'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th width='40%'>Item</th>
                                    <th width='100px' className='text-center'>
                                        Disponible
                                    </th>
                                    <th>Cantidad</th>
                                    <th className='text-center'>Costo</th>
                                    <th width='15%' className='text-center'>
                                        Grupo
                                    </th>
                                    <th className='text-right' width='12%'>
                                        Sub-total
                                    </th>
                                    <th width='25px'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {gastoItems.map((gasto, index) => (
                                    <GastoItem
                                        key={gasto.id}
                                        gasto={gasto}
                                        index={index}
                                        items={items}
                                        fecha={fecha}
                                        vehiculo={vehiculo}
                                        mecanico={mecanico}
                                        removeGasto={this.removeGasto}
                                        updateTotal={this.updateTotal}
                                    />
                                ))}
                                <tr>
                                    <td colSpan='7' className='text-right'>
                                        <button
                                            type='button'
                                            onClick={this.addItem}
                                            className='btn'>
                                            <i className='fas fa-plus' />{" "}
                                            Agregar Item
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='5' className='text-right'>
                                        <strong className='label form-control-label'>
                                            Total:
                                        </strong>
                                    </td>
                                    <td colSpan='5' className='text-right'>
                                        <input
                                            type='hidden'
                                            name='pagado'
                                            value='0'
                                        />
                                        <input
                                            type='hidden'
                                            name='total'
                                            value={total}
                                        />
                                        {formatCurrency(total)}
                                        {errors.total && (
                                            <small className='help-blockParams form-Text'>
                                                {errors.total[0]}
                                            </small>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                    </div>
                    <div className='page-footer text-right col-md-12'>
                        <button
                            type='button'
                            onClick={() =>
                                (window.location = getLink("/gastos"))
                            }
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        {saving ? (
                            <Loader type='small' />
                        ) : (
                            <button type='submit' className='btn btn-primary'>
                                Guardar
                            </button>
                        )}
                    </div>
                </form>
            </div>
        );
    }
}

export default withRouter(withRequest(CrearGastos));
