import React, { Component } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import Loader from "../general/Loader";
import { toast, getLink } from "../../utils/helpers";
import PageHeader from "../general/PageHeader";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { postRequest } from "../../utils/request";

class CreateProducto extends Component {
	state = {
		errors: {},
		producto: {},
		saving: false,
        continuar: false,
	};
	createProducto = async (event) => {
		event.preventDefault();

		this.setState({
			saving: true,
		});

		const data = new FormData(event.target);
		const response = await postRequest(apiUrl("productos"), data);

		if (response.success) {
            if (this.state.continuar) {
                window.location.reload();
            } else {
                this.props.history.push(getLink("/productos"));
            }
            toast('Producto creado exitosamente.');
		}
		if (response.code === 422) {
			this.setState({ errors: response.data.message });
		} else if (response.code === 400 || response.code === 500) {
			toast(response.data.message, "error");
		}

		this.setState({
			saving: false,
		});
	};

	cancel = () => {
		this.props.history.push(getLink("/productos"));
	};

    continuar = (continuar = false) => {
        this.setState({
            continuar
        })
    }
	render() {
		const { errors, producto } = this.state;
		return (
			<div className="container-fluid">
				<form method="post" onSubmit={this.createProducto}>
                    <div className='page-header'>
                        <Link to={getLink('/productos')}>&laquo; Volver a Productos</Link>
                        <h1 className='page-title row'>
                            <div className="col">Nuevo Producto/Servicio</div>
                        </h1>
                    </div>
					<Form producto={producto} errors={errors} />
					<div className="page-footer text-right">
						<button
							type="button"
							onClick={this.cancel}
							className="btn btn-plain"
						>
							Cancelar
						</button>
						{this.state.saving ? (
							<Loader type="small" />
						) : (
							<span>
								<button type="submit" className="btn btn-secondary" onClick={() => this.continuar(true)}>
									Crear y Continuar Creando
								</button>
								<button type="submit" className="btn btn-primary m-r-15 m-l-15" onClick={() => this.continuar(false)}>
									Crear y Salir
								</button>
							</span>
						)}
					</div>
				</form>
			</div>
		);
	}
}

export default withRouter(CreateProducto);
