import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import { postRequest, withRequest } from "../../../utils/request";
import { toast } from "../../../utils/helpers";
import Loader from "../../general/Loader";

class Terminos extends Component {
    state = {
        errors: {},
        config: false,
    };
    componentDidMount() {
        this.getConfig();
    }
    getConfig = async (url) => {
        url = url ? url : "configuraciones?seccion=sucursal_terminos";
        const configuraciones = await this.props.request(url);

        if (configuraciones.data) {
            this.setState({
                config: configuraciones.data,
                loading: false,
            });
        }
    };
    editConfig = async (event) => {
        event.preventDefault();

        const data = new FormData(event.target);
        const response = await postRequest(
            apiUrl('configuraciones'),
            data
        );

        if (response.success) {
            this.getConfig();
            toast(response.data);
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
            toast(response.data, 'error');
        }
    };
    render() {
        const { errors, config } = this.state;

        if (config === false) {
            return <Loader />
        }
        return (
            <form method='post' onSubmit={this.editConfig}>
                <input type='hidden' name='_method' value='post' />
                <input type='hidden' name='seccion' value='sucursal_terminos' />
                <h3 class="section-title">Unidad de Producción</h3>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Singular:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input type="text"
                            className='text-input form-control'
                            id='sistema'
                            defaultValue={config.unidad_produccion_s}
                            name='configuraciones[unidad_produccion_s]' />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Plural:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input type="text"
                            className='text-input form-control'
                            id='sistema'
                            defaultValue={config.unidad_produccion_p}
                            name="configuraciones[unidad_produccion_p]" />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Abreviación:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input type="text"
                            className='text-input form-control'
                            id='sistema'
                            defaultValue={config.unidad_produccion_a}
                            name='configuraciones[unidad_produccion_a]' />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='popup__footer'>
                    <button type='submit' className='btn btn-primary'>
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

export default withRequest(Terminos);
