import React, { Component, useState } from "react";

import { postRequest, withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../general/Loader";
import { formatCurrency, getLink, toast } from "../../utils/helpers";
import { debounce } from "lodash";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { Link } from "react-router-dom";

class CuentasContables extends Component {
    state = {
        cuentas: {},
        cuenta: {},
        padre: {},
        links: null,
        showCreate: false,
        showEdit: false,
        showTransaccion: false,
        loading: true,
        showBorrarCuenta: false,
        filtros: [],
        filtrosQuery: '',
    };
    componentDidMount() {
        this.getCuentas();
    }
    getCuentas = async (url = null) => {
        url = url ? url : "cuentas-contables?padres";
        const cuentas = await this.props.request(url);

        if (cuentas.data) {
            this.setState({
                cuentas: cuentas.data,
            });
        }

        this.setState({
            loading: false,
        });
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        if (!value) {
            this.getCuentas("cuentas-contables?padres");
            return;
        }
        this.getCuentas("cuentas-contables?" + query.join("&"));
    }, 500);
    borrarCuenta = async () => {
        const {cuenta} = this.state;
        const url = `cuentas-contables/${cuenta.id}`;
        const response = await postRequest(apiUrl(url), {}, 'delete');

        if (response.success) {
            this.getCuentas();
            this.setState({
                showEdit: false,
                cuenta: {}
            });
            toast("Cuenta borrada.");
            return;
        } else {
            toast(response.data, "error");
        }
    }
    toggleCreateModal = (padre = null) => {
        this.setState({
            showCreate: !this.state.showCreate,
            cuenta: {},
            padre: padre
        });
    };
    toggleEditModal = (cuenta) => {
        this.setState({
            showEdit: !this.state.showEdit,
            cuenta: cuenta,
        });
    };
    toggleBorrarCuenta = () => {
        this.setState({
            showBorrarCuenta: !this.state.showBorrarCuenta,
        });
    };
    toggleTransaccionModal = (cuenta) => {
        this.setState({
            showTransaccion: !this.state.showTransaccion,
            cuenta: cuenta ? cuenta : {},
        });
    };
    render() {
        const { cuentas, loading } = this.state;
        return (
            <div>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Cuentas Contables</span>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Cuenta
                        </button>
                    </h1>
                </div>
                <div className='page-controls'>
                    <div className='table-data__tool'>
                        <div className='table-data__tool-left row'>
                            <div className='col'>
                                <label>Buscar</label>
                                <input
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "buscar"
                                        )
                                    }
                                    type='search'
                                    placeholder='Buscar...'
                                    className='form-control'
                                    name='buscar'
                                />
                            </div>
                            {/* <div className='col-md-2'>
                                <label htmlFor='estado'>Tipo</label>
                                <select
                                    className='form-control'
                                    name='tipo'
                                    id='tipo'
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "tipo"
                                        )
                                    }>
                                    <option value='todos'>Todos</option>
                                    <option value='LOCAL'>Local</option>
                                    <option value='BANCARIA'>Bancaria</option>
                                </select>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!cuentas.length ? (
                                <div>
                                    {!loading && (
                                        <div>
                                            Todavía no hay cuentas creadas.
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="table-ccontables">
                                    <div className="row header-ccontables">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-1">#</div>
                                        <div className="col-md-3">Cuenta</div>
                                        <div className="col-md-4">Descripción</div>
                                        <div className="col-md-2">Naturaleza</div>
                                        <div className="col-md-1"></div>
                                    </div>
                                    <div className="table-ccontables-wrap">
                                        {cuentas.map(c => (<RowCuentas key={c.id} cuenta={c} toggleCreateModal={this.toggleCreateModal} toggleEditModal={this.toggleEditModal}/>))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        cuenta={this.state.cuenta}
                        padre={this.state.padre}
                        getCuentas={this.getCuentas}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={() => this.toggleEditModal({})}>
                    <Edit
                        toggle={() => this.toggleEditModal({})}
                        cuenta={this.state.cuenta}
                        getCuentas={this.getCuentas}
                        borrarCuenta={this.toggleBorrarCuenta}
                    />
                </Modal>
                <Modal
                    toggle={this.toggleBorrarCuenta}
                    title='Borrar Cuenta'
                    acceptText='Borrar'
                    callback={this.borrarCuenta}
                    show={this.state.showBorrarCuenta}>
                    ¿Seguro que desea borrar esta cuenta?
                </Modal>
            </div>
        );
    }
}

const RowCuentas = ({cuenta, toggleEditModal, toggleCreateModal}) => {
    const [active, setActive] = useState(false);
    return (
        <div className={`row-ccontables-wrap ${active?'active':''}`}>
            <div className="row row-ccontables">
                <div className="col-md-1">
                    {cuenta.subcuentas?.length > 0 && (
                        <button className="btn" onClick={() => setActive(!active)}>
                            <i className={`fa ${active?'fa-minus':'fa-plus'}`}></i>
                        </button>
                    )}
                </div>
                <div className="col-md-1">
                    {cuenta.numero}
                </div>
                <div className={`col-md-3 ${cuenta.defecto ? 'bold' : ''}`}>
                    {cuenta.nombre}
                </div>
                <div className="col-md-4">
                    {cuenta.descripcion}
                </div>
                <div className="col-md-2">
                    {cuenta.naturaleza}
                </div>
                <div className="col-md-1">
                    <div className='table-data-feature text-right'>
                        <button
                            className='item'
                            data-toggle='tooltip'
                            data-placement='top'
                            onClick={() => toggleCreateModal(cuenta)}
                            data-original-title='Abonar'>
                            <i className='zmdi zmdi-collection-plus' />
                        </button>
                        <button
                            className='item'
                            data-toggle='tooltip'
                            data-placement='top'
                            title=''
                            data-original-title='Editar'
                            onClick={() => {
                                toggleEditModal(
                                    cuenta
                                );
                            }}>
                            <i className='zmdi zmdi-edit' />
                        </button>
                    </div>
                </div>
            </div>
            {cuenta.subcuentas && (
                <div className="child-ccontables">
                    {cuenta.subcuentas.map(c => <RowCuentas key={c.id} cuenta={c} toggleCreateModal={toggleCreateModal} toggleEditModal={toggleEditModal}/>)}
                </div>
            )}
        </div>
    );
}

export default withRequest(CuentasContables);
