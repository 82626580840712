import React, { Component } from "react";
import { postRequest, request, withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import Form from "./Form";
import { toast } from "../../../utils/helpers";

class CreateSolicitud extends Component {
    state = {
        vehiculos: [],
        empleados: [],
        continuar: false,
        saving: false,
        siguiente: {},
    };
    componentDidMount() {
        this.getTable("vehiculos");
        this.getTable("empleados", "?estado=activos");
        this.getSiguiente();
    }
    createSolicitud = async event => {
        event.preventDefault();

        this.setState({
            saving: true
        });

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl(`solicitudes`), data);

        if (response.success) {
            if (this.props.getSolicitudes) {
                this.props.getSolicitudes();
            }
            document.getElementById("solicitudesform").reset();

            this.props.toggle();

            if (this.state.continuar) {
                this.props.toggle();
            }

            toast("Solicitud creada exitosamente.");
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({
            continuar: false,
            saving: false
        });
    };
    getTable = async (url = null, params = "") => {
        const table = await this.props.request(url + params);

        if (table.data && table.data.length > 0) {
            this.setState({
                [url]: table.data
            });
        }
    };
    getSiguiente = () => {
        request("numeracion-documentos/siguiente/solicitud/S0").then((res) => {
            this.setState({ siguiente: res.data })
		});
    };
    render() {
        const { empleado } = this.props;
        const { vehiculos, empleados, siguiente } = this.state;
        return (
            <form
                id='solicitudesform'
                method='post'
                onSubmit={this.createSolicitud}>
                <input type='hidden' name='_method' value='post' />
                <div className='popup__header'>
                    Nueva Solicitud
                    {siguiente.id && ` ${siguiente.prefijo}${siguiente.numero_siguiente}`}
                </div>
                <Form
                    empleado={empleado}
                    vehiculos={vehiculos}
                    empleados={empleados}
                />
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={this.props.toggle}
                        className='btn btn-plain float-left'>
                        Cancelar
                    </button>
                    {!this.state.saving && (
                        <button type='submit' className='btn btn-secondary' onClick={() => this.setState({continuar: true})}>
                            Guardar y Crear Nueva
                        </button>
                    )}
                    {this.state.saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary m-t-5 m-l-10'>
                            Crear
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

export default withRequest(CreateSolicitud);
