import React, { Component } from "react";

import { postRequest, withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";

import Form from "./Form";
import Loader from "../../general/Loader";

class Create extends Component {
    state = {
        vehiculos: [],
        rutas: [],
        categoria: "",
        saving: false,
        errors: {},
    };
    componentDidMount() {
        this.getTable("vehiculos");
        this.getTable("rutas");
    }
    getTable = async (table) => {
        const response = await this.props.request(table);

        if (response.data && response.data.length) {
            this.setState({
                [table]: response.data,
            });
        }
    };
    createProgramacion = async (event) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });


        const data = new FormData(event.target);

        const url = `programaciones/${this.props.programacion}/tarjetas`;

        const response = await postRequest(apiUrl(url), data);



        if (response.success) {
            this.props.getProgramacion();
            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({
            saving: false,
        });
    };
    onSelectFicha = (e) => {
        if (!e) {
            return;
        }
        const { vehiculos } = this.state;
        this.setState({
            categoria: vehiculos.filter(
                (vehiculo) => vehiculo.id === e.value
            )[0].categoriaId,
        });
    };
    render = () => {
        const { toggle } = this.props;
        const { vehiculos, rutas, categoria, errors } = this.state;
        return (
            <div>
                <form onSubmit={this.createProgramacion}>
                    <div className='popup__header'>Nueva Tarjeta</div>
                    <div className='popup__body'>
                        <Form
                            vehiculos={vehiculos}
                            rutas={rutas}
                            categoria={categoria}
                            tarjeta={{}}
                            errors={errors}
                            onSelectFicha={this.onSelectFicha}
                        />
                    </div>
                    <div className='popup__footer'>
                        <button
                            type='button'
                            onClick={toggle}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        {this.state.saving ? (
                            <Loader type='small' />
                        ) : (
                            <button type='submit' className='btn btn-primary'>
                                Crear
                            </button>
                        )}
                    </div>
                </form>
            </div>
        );
    };
}

export default withRequest(Create);
