import React, { Component } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import Loader from "../general/Loader";
import { postRequest, withRequest } from "../../utils/request";

class Create extends Component {
    state = {
        errors: {},
        reclamacion: {},
        saving: false,
    };
    createReclamacion = async (event) => {
        event.preventDefault();

        this.setState({ saving: true });

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl("reclamaciones"), data);

        if (response.success) {
            this.props.getReclamaciones();
            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({ saving: false });
    };
    render() {
        const { errors, reclamacion, saving } = this.state;
        const { toggle, rutas, empleados, vehiculos } = this.props;
        return (
            <form method='post' onSubmit={this.createReclamacion}>
                <div className='popup__header'>Nueva Reclamación</div>
                <div className='popup__body'>
                    <Form
                        reclamacion={reclamacion}
                        rutas={rutas}
                        empleados={empleados}
                        vehiculos={vehiculos}
                        errors={errors}
                    />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Crear
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

export default withRequest(Create);
