import React, { Component } from "react";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatNumber, formatCurrency } from "../../utils/helpers";
import dayjs from "dayjs";
import Loader from "../general/Loader";
import { request } from "../../utils/request";

export default class ReporteRelacionGastosFicha extends Component {
    state = {
        vehiculos: false
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {

        const response = await request(apiUrl(`reportes` + window.location.search));

        if (response.success) {
            this.setState({
                vehiculos: response.data
            });
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { vehiculos } = this.state;

        if (!vehiculos) {
            return <Loader />;
        }

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            suc => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);

        const totalGlobal = vehiculos.reduce((acc, vehiculo) => {
            vehiculo.gastos.map(gasto => {
                acc = acc + parseFloat(gasto.precio * gasto.cantidad);
                return gasto;
            });

            return acc;
        }, 0);

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Relación de Gastos por Ficha
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(query.desde).format("DD/MM/YYYY")} -{" "}
                        {dayjs(query.hasta).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            <h3>Total Vehículos: {vehiculos.length}</h3>
                        </div>

                        <div className='float-right'>
                            <h3>Gasto Total: {formatCurrency(totalGlobal)}</h3>
                        </div>
                    </div>
                </div>
                {vehiculos.map(vehiculo => (
                    <div>
                        <h3>
                            {vehiculo.ficha} - {vehiculo.categoria}
                        </h3>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Referencia</th>
                                    <th>Descripción</th>
                                    <th>Grupo</th>
                                    <th className='text-center'>Cantidad</th>
                                    <th className='text-center'>Precio</th>
                                    <th className='text-right'>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehiculo.gastos.map(gasto => (
                                    <tr key={gasto.id}>
                                        <td>
                                            {dayjs(gasto.fecha).format(
                                                "DD/MM/YYYY"
                                            )}
                                        </td>
                                        <td>{gasto.item.referencia}</td>
                                        <td>{gasto.item.nombre}</td>
                                        <td>
                                            {gasto.item.grupo &&
                                                gasto.item.grupo.nombre}
                                        </td>
                                        <td className='text-center'>
                                            {formatNumber(gasto.cantidad)}
                                        </td>
                                        <td className='text-center'>
                                            {formatCurrency(gasto.precio)}
                                        </td>
                                        <td className='text-right'>
                                            {formatCurrency(
                                                gasto.cantidad * gasto.precio
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-right'>
                                        <strong>TOTAL =></strong>
                                    </td>
                                    <td className='text-right'>
                                        <strong>
                                            {formatCurrency(
                                                vehiculo.gastos.reduce(
                                                    (acc, gasto) =>
                                                        acc +
                                                        parseFloat(
                                                            gasto.precio *
                                                                gasto.cantidad
                                                        ),
                                                    0
                                                )
                                            )}
                                        </strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ))}

                <div className='m-t-25'>
                    <div className='float-left'>
                        <h3>Total Vehículos: {vehiculos.length}</h3>
                    </div>

                    <div className='float-right'>
                        <h3>Gasto Total: {formatCurrency(totalGlobal)}</h3>
                    </div>
                </div>
            </div>
        );
    }
}
