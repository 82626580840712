import React, { Component } from "react";

import { postRequest, withRequest } from "../../../utils/request";
import Pagination from "../../general/Pagination";
import Modal from "../../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import { getLink, toast } from "../../../utils/helpers";
import { Link } from "react-router-dom";
import { apiUrl } from "../../../utils/apiUrl";
import { auth } from "../../../utils/auth";

class Taxonomias extends Component {
    state = {
        taxonomias: {},
        taxonomia: {},
        links: null,
        meta: null,
        showCreate: false,
        showEdit: false,
        loading: true,
    };
    componentDidMount() {
        this.getTaxonomias();
    }
    getTaxonomias = async (url = null) => {
        url = url ? url : "taxonomias";
        const taxonomias = await this.props.request(url);

        if (taxonomias) {
            this.setState({
                taxonomias: taxonomias.data,
                links: taxonomias.links,
                meta: taxonomias.meta,
                loading: false,
            });
        }
    };
    deleteTaxonomia = async (taxonomia) => {
        const url = `taxonomias/${taxonomia}`;
        const response = await postRequest(apiUrl(url), {}, 'delete');

        if (response.success) {
            this.getTaxonomias();
            toast("Taxonomia Borrada.");
            return;
        } else {
            this.setState({ errors: response.data });
            toast(response.data, 'error');
        }
    };
    toggleCreateModal = (taxonomia = {}) => {
        this.setState({
            showCreate: !this.state.showCreate,
            taxonomia,
        });
    };
    toggleEditModal = (taxonomia) => {
        this.setState({
            showEdit: !this.state.showEdit,
            taxonomia,
        });
    };
    toggleDeleteModal = (taxonomia) => {
        this.setState({
            showDelete: !this.state.showDelete,
            taxonomia,
        });
    };
    formatTaxonomias = (taxonomias) => {
        if (taxonomias instanceof Array) {
            return taxonomias.reduce((p, c) => {
                const name = `${c.tipo} ${c.relacion}`
                if (!p[name]) {
                    p[name] = [];
                }
                p[name].push(c);
                return p;
            }, {});
        }

        return taxonomias;
    }
    toggleAccordion = (e) => {
        e.target.classList.toggle('opened');
    }
    render() {
        const taxonomias = this.formatTaxonomias(this.state.taxonomias);
        return (
            <div>
                <div className='page-header'>
                    <Link to={getLink('/configuracion/')}>&laquo; Volver a configuración</Link>
                    <h1 className='page-title row'>
                        <span className='col'>Categorías</span>
                    </h1>
                </div>
                <table className="table-clean">
                    <tbody>
                        {Object.keys(taxonomias).map(relacion => (
                            <tr>
                                <td className="table-accordion">
                                    <button className="table-accordion__toggle" onClick={e => this.toggleAccordion(e)}>{relacion}</button>
                                    <div className="table-accordion__content">
                                        <table className='table table-data2'>
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Cantidad</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {taxonomias[relacion].map((taxonomia) => (
                                                    <tr
                                                        className='tr-shadow'
                                                        key={taxonomia.id}>
                                                        <td>{taxonomia.nombre}</td>
                                                        <td>{taxonomia.cantidad}</td>
                                                        <td>
                                                            <div className='table-data-feature'>
                                                                <button
                                                                    className='item'
                                                                    data-toggle='tooltip'
                                                                    data-placement='top'
                                                                    title=''
                                                                    data-original-title='Edit'
                                                                    onClick={() => {
                                                                        this.toggleEditModal(
                                                                            taxonomia
                                                                        );
                                                                    }}>
                                                                    <i className='zmdi zmdi-edit' />
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    onClick={(e) =>
                                                                        this.deleteTaxonomia(taxonomia.id)
                                                                    }
                                                                    className='btn btn-danger btn-sm'>
                                                                    <i className='fa fa-trash-alt'></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="text-right">
                                            <button
                                                className='btn'
                                                onClick={() => this.toggleCreateModal({
                                                    relacion: taxonomias[relacion][0].relacion,
                                                    tipo: taxonomias[relacion][0].tipo,
                                                })}>
                                                <i className='zmdi zmdi-plus' />
                                                Añadir Categoría
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {this.state.links && (
                    <Pagination
                        links={this.state.links}
                        meta={this.state.meta}
                        getData={this.getTaxonomias}
                    />
                )}
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        taxonomia={this.state.taxonomia}
                        getTaxonomias={this.getTaxonomias}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={() => this.toggleEditModal({})}>
                    <Edit
                        toggle={() => this.toggleEditModal({})}
                        taxonomia={this.state.taxonomia}
                        getTaxonomias={this.getTaxonomias}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Taxonomias);
