import React, { Component } from "react";
import Select from "react-select";

import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import { toast } from "../../../utils/helpers";
import { postRequest } from "../../../utils/request";

class Asignacion extends Component {
    state = {
        saving: false,
        showDropdown: false,
        matchTipo: /(chofer|mensajero)/i,
    };

    constructor(props) {
        super(props);

        this.form = React.createRef();
    }

    updateAsignacion = async (event, id) => {
        event.preventDefault();

        this.setState({ saving: true });

        const data = new FormData(event.target);
        const url = "asignaciones/" + id;
        const response = await postRequest(apiUrl(url), data);

        if (response.success) {
            this.setState({
                saving: false,
            });
        }
    };

    removeAsignacion = async (event, asignacion) => {
        event.preventDefault();

        this.setState({ showDropdown: false });



        const url = `asignaciones/${asignacion}`;

        const response = await postRequest(apiUrl(url), {}, 'delete');



        if (response.success) {
            this.props.getTable("asignaciones");
            toast("Asignación Borrada Exitosamente");
            return;
        } else {
            this.setState({ errors: response.data.message });
            toast("La asignación no pudo ser borrada", "error");
        }
    };

    submitForm = (e) => {
        setTimeout(() => {
            var evObj = document.createEvent("Events");
            evObj.initEvent("submit", true, false);
            this.form.current.dispatchEvent(evObj);
        }, 0);
    };

    showDropdown = () => {
        this.setState({
            showDropdown: !this.state.showDropdown,
        });
    };

    hideDropdown = (e) => {
        if (this.dropdown.contains(e.target)) {
            return;
        }
        this.setState({
            showDropdown: false,
        });
    };

    UNSAFE_componentWillMount() {
        document.addEventListener("mousedown", this.hideDropdown, false);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.hideDropdown, false);
    }

    render() {
        const { asignacion, empleados, index } = this.props;
        const { showDropdown, matchTipo } = this.state;
        return (
            <div
                style={{ animationDelay: index * 50 + "ms" }}
                id={asignacion.id}
                className='asignacion'>
                <form
                    ref={this.form}
                    onSubmit={(e) => this.updateAsignacion(e, asignacion.id)}>
                    <input type='hidden' name='_method' value='PATCH' />
                    <input
                        type='hidden'
                        name='categoria_id'
                        value={asignacion.categoriaId}
                    />
                    <input
                        type='hidden'
                        name='vehiculo_id'
                        value={asignacion.vehiculoId}
                    />
                    <input
                        type='hidden'
                        name='ruta_id'
                        value={asignacion.rutaId}
                    />
                    <div className='asignacion-header row'>
                        <div className='ficha'>{asignacion.ficha}</div>
                        {asignacion.ruta && (
                            <div className='ruta'>
                                {asignacion.ruta.nombre}{" "}
                                <small
                                    className='ruta-descripcion'
                                    title={asignacion.ruta.descripcion}>
                                    ({asignacion.ruta.descripcion})
                                </small>
                                {asignacion.meta && (
                                    <span className='meta'>
                                        Meta{" "}
                                        <span className='separator'>»</span>{" "}
                                        {asignacion.meta}
                                    </span>
                                )}
                            </div>
                        )}
                        <div
                            className={
                                "asignacion-menu " +
                                (showDropdown ? "active" : "")
                            }>
                            <button onClick={this.showDropdown}>
                                <i className='zmdi zmdi-menu'></i>
                            </button>
                            <div
                                ref={(dropdown) => (this.dropdown = dropdown)}
                                className='dropdown'>
                                {showDropdown && (
                                    <ul>
                                        <li>
                                            <button
                                                onClick={() => {
                                                    this.showDropdown();
                                                    this.props.editar(
                                                        asignacion
                                                    );
                                                }}>
                                                <i className='zmdi zmdi-edit'></i>{" "}
                                                Editar
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                onClick={(e) =>
                                                    this.removeAsignacion(
                                                        e,
                                                        asignacion.id
                                                    )
                                                }>
                                                <i className='zmdi zmdi-delete'></i>{" "}
                                                Borrar
                                            </button>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='asignacion-body row'>
                        <div className='operadores row'>
                            {asignacion.operadores.map((operador) => (
                                <div key={operador.id} className='operador'>
                                    <div className='label'>
                                        {operador.nombre}
                                    </div>
                                    <div className='empleado'>
                                        <Select
                                            isSearchable={true}
                                            isClearable={true}
                                            onChange={this.submitForm}
                                            isLoading={empleados.length === 0}
                                            name={`operadores[${operador.id}][empleado_id]`}
                                            defaultValue={
                                                operador.asignadoId && {
                                                    isFixed: true,
                                                    value: operador.asignadoId,
                                                    label:
                                                        operador.asignadoNombre,
                                                }
                                            }
                                            options={empleados
                                                .filter((empleado) => {
                                                    if ( matchTipo.test(operador.nombre) ) {
                                                        return (
                                                            empleado.operaVehiculo
                                                        );
                                                    }
                                                    return (
                                                        empleado.cargo &&
                                                        empleado.cargo
                                                            .toLowerCase()
                                                            .indexOf(
                                                                "ayudante"
                                                            ) > -1
                                                    );
                                                })
                                                .map((empleado) => ({
                                                    value: empleado.id,
                                                    label:
                                                        empleado.nombreCompleto,
                                                }))}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Asignacion;
