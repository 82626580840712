import React, { Component } from "react";

import { postRequest, withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";

import Form from "./Form";
import { toast } from "../../../utils/helpers";
import Loader from "../../general/Loader";

class Edit extends Component {
    state = {
        vehiculos: [],
        rutas: [],
        categoria: "",
        errors: [],
        saving: false,
    };
    componentDidMount() {
        this.getTable("vehiculos");
        this.getTable("rutas");
    }
    getTable = async (table) => {
        const response = await this.props.request(table);

        if (response.data.length) {
            this.setState({
                [table]: response.data,
            });
        }
    };
    updateAsignacion = async (event, id) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });

        const data = new FormData(event.target);
        const url = "asignaciones/" + id;
        const response = await postRequest(apiUrl(url), data);

        if (response.success) {
            this.props.getAsignaciones();
            this.props.toggle({});
            toast("Asignación actualizada.");
        } else {
            this.setState({
                errors: response.data.message,
            });
            toast("Por favor revisa todos los campos", "error");
        }

        this.setState({
            saving: false,
        });
    };
    onSelectFicha = (e) => {
        if (!e) {
            return;
        }

        const { vehiculos } = this.state;
        const categoria = vehiculos.filter(
            (vehiculo) => vehiculo.id === e.value
        )[0];

        this.setState({
            categoria: categoria ? categoria.categoriaId : null,
        });
    };
    render = () => {
        const { toggle, asignacion, categoriaActiva } = this.props;
        const { vehiculos, rutas, categoria, errors, saving } = this.state;
        return (
            <div>
                <form onSubmit={(e) => this.updateAsignacion(e, asignacion.id)}>
                    <input type='hidden' name='_method' value='patch' />
                    <div className='popup__header'>Editar Asignación</div>
                    <div className='popup__body'>
                        <Form
                            vehiculos={vehiculos}
                            rutas={rutas}
                            categoria={categoria}
                            asignacion={asignacion}
                            categoriaActiva={categoriaActiva}
                            errors={errors}
                            onSelectFicha={this.onSelectFicha}
                        />
                    </div>
                    <div className='popup__footer'>
                        <button
                            type='button'
                            onClick={() => toggle({})}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        {!saving ? (
                            <button type='submit' className='btn btn-primary'>
                                Guardar
                            </button>
                        ) : (
                            <Loader type='small' />
                        )}
                    </div>
                </form>
            </div>
        );
    };
}

export default withRequest(Edit);
