import React, { Component } from "react";
import { debounce } from "lodash";
import { Link, Route, withRouter } from "react-router-dom";

import { postRequest, withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../general/Loader";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { getLink, toast } from "../../utils/helpers";
import View from "./View";
import IncentivoItem from "./IncentivoItem";
import dayjs from "dayjs";

class Incentivos extends Component {
    state = {
        incentivos: [],
        incentivo: {},
        links: null,
        meta: null,
        showCreate: false,
        showEdit: false,
        showCompletar: true,
        showProgramacion: true,
        estado: "completada",
        loading: true,
        filtros: {
            buscar: "",
            desde: dayjs().startOf('month').format('YYYY-MM-DD'),
            hasta: dayjs().endOf('month').format('YYYY-MM-DD'),
        },
        filtrosQuery: `?limit=20&desde=${dayjs().startOf('month').format('YYYY-MM-DD')}&hasta=${dayjs().endOf('month').format('YYYY-MM-DD')}&estado=pendiente`,
        empleados: [],
        tipos: [],
    };
    componentDidMount() {
        this.getIncentivos();
        this.getTable("empleados", "?estado=activos");
        this.getTable("tipos");
    }
    getTable = async (table, query = '') => {
        const response = await this.props.request(table + query);

        if (response.data && response.data.length) {
            this.setState({
                [table]: response.data,
            });
        }
    };
    getIncentivos = async (url = null) => {
        url = url
            ? url + "&limit=20"
            : "incentivos" + this.state.filtrosQuery;
        const incentivos = await this.props.request(url);
        if (incentivos.data) {
            this.setState({
                incentivos: incentivos.data,
                links: incentivos.links,
                meta: incentivos.meta,
                loading: false,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            incentivo: { operadores: [] },
        });
    };
    toggleEditModal = (incentivo) => {
        this.setState({
            showEdit: !this.state.showEdit,
            incentivo: incentivo,
        });
        this.props.history.push(getLink("/incentivos"));
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getIncentivos("incentivos?limit=20&" + query.join("&"));
    }, 500);

    updateincentivo = async (incentivo, fields) => {

        let data = new FormData();
        data.append("_method", "PATCH");
        Object.keys(fields).map((field) => data.append(field, fields[field]));

        const response = await postRequest(apiUrl(`incentivos/${incentivo}`), data);

        if (response.success) {
            this.getIncentivos();
            toast("¡Reclamación actualizada!");
        }
    };

    render() {
        const {
            incentivo,
            incentivos,
            empleados,
            tipos,
            loading,
            filtrosQuery,
        } = this.state;
        return (
            <div className='container'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Incentivos</span>
                        <Link
                            to={getLink(
                                `/imprimir/incentivos${filtrosQuery}`
                            )}
                            target='_blank'
                            className='au-btn-filter m-r-15'>
                            <i className='zmdi zmdi-print' /> Imprimir
                        </Link>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Incentivo
                        </button>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='buscar'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'                                        name='buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='periodo'>Periodo</label>
                                    <select
                                        type='text'
                                        name='periodo'
                                        className='form-control'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "periodo"
                                            )
                                        }
                                    >
                                        <option value="todo">Todos</option>
                                        <option value="semanal">Semanal</option>
                                        <option value="quincenal">Quincenal</option>
                                        <option value="mensual">Mensual</option>
                                    </select>
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='frecuencia'>Frecuencia</label>
                                    <select
                                        type='text'
                                        name='frecuencia'
                                        id='desde'
                                        className='form-control'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "frecuencia"
                                            )
                                        }
                                    >
                                        <option value="todos">Todos</option>
                                        <option value="fijo">Fijos</option>
                                        <option value="unico">Únicos</option>
                                    </select>
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='desde'>Desde</label>
                                    <input
                                        type='date'
                                        name='desde'
                                        id='desde'
                                        className='form-control'
                                        defaultValue={dayjs().startOf('month').format('YYYY-MM-DD')}
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "desde"
                                            )
                                        }
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='hasta'>Hasta</label>
                                    <input
                                        type='date'
                                        name='hasta'
                                        id='hasta'
                                        className='form-control'
                                        defaultValue={dayjs().endOf('month').format('YYYY-MM-DD')}
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "hasta"
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!incentivos.length ? (
                                <div>
                                    {!loading && (
                                        <div>No hay incentivos creados.</div>
                                    )}
                                </div>
                            ) : (
                                <div className="tabla-incentivos">
                                    <div className="tabla-incentivos__header incentivo-row">
                                        <div>Empleado</div>
                                        <div>Detalle</div>
                                        <div className="text-center">Frecuencia</div>
                                        <div className="text-center">Fecha</div>
                                        <div>Monto</div>
                                        <div></div>
                                    </div>
                                    <div className="tabla-incentivos__body">
                                        {incentivos.map(
                                            (incentivo) => <IncentivoItem incentivo={incentivo} key={incentivo.id} />
                                        )}
                                    </div>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getIncentivos}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Route
                    exact
                    path='/:sucursal/incentivos/:id'
                    render={(props) => (
                        <Modal show blank toggle={this.toggleEditModal}>
                            <Edit
                                toggle={this.toggleEditModal}
                                empleados={empleados}
                                incentivoId={parseInt(props.match.params.id )}
                                incentivo={
                                    incentivos.filter( (d) => {
                                        return d.id === parseInt(props.match.params.id );
                                    })[0] || {}
                                }
                                getIncentivos={this.getIncentivos}
                            />
                        </Modal>
                    )}
                />
                <Route
                    exact
                    path='/:sucursal/incentivos/:id/ver'
                    render={(props) => {
                        return (
                            <Modal
                                show
                                title='Ver incentivo Programado'
                                toggle={this.toggleEditModal}>
                                <View
                                    incentivo={
                                        incentivos.filter(
                                            (r) =>
                                                r.id ===
                                                parseInt(props.match.params.id)
                                        )[0] || {}
                                    }
                                />
                            </Modal>
                        );
                    }}
                />
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        incentivo={incentivo}
                        empleados={empleados}
                        tipos={tipos}
                        getIncentivos={this.getIncentivos}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRouter(withRequest(Incentivos));
