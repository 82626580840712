import React, { Component } from "react";
import { postRequest, withRequest } from "../../utils/request";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import Loader from "../general/Loader";
import Form from "./Form";
import { toast } from "../../utils/helpers";

class EditServicio extends Component {
    state = {
        vehiculos: [],
        errors: {},
        saving: false,
    };
    componentDidMount() {
        this.getTable("vehiculos");
    }
    updateServicio = async (event, id) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });

        const data = new FormData(event.target);
        const url = `servicios/${id}`;
        const response = await postRequest(apiUrl(url), data);

        if (response.success) {
            if (this.props.getServicios) {
                this.props.getServicios();
            }
            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({
            saving: false,
        });
    };
    getTable = async (url = null, params = "") => {
        const table = await this.props.request(url + params);

        if (table.data && table.data.length > 0) {
            this.setState({
                [url]: table.data,
            });
        }
    };
    deleteServicio = async (event, servicio) => {
        event.preventDefault();



        const url = `servicios/${servicio}`;

        const response = await postRequest(apiUrl(url), {}, 'delete');



        if (response.success) {
            if (this.props.getServicios) {
                this.props.getServicios();
            }
            this.props.toggle();
            toast("Servicio borrado.");
            return;
        } else {
            toast(response.data, "error");
        }
    };
    render() {
        const { servicio } = this.props;
        const { vehiculos, errors } = this.state;
        return (
            <form
                id='serviciosform'
                method='post'
                onSubmit={(e) => this.updateServicio(e, servicio.id)}>
                <input type='hidden' name='_method' value='PATCH' />
                <div className='popup__header'>Editar Servicio</div>
                <Form
                    vehiculos={vehiculos}
                    servicio={servicio}
                    errors={errors}
                />
                <div className='popup__footer'>
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={(e) => this.deleteServicio(e, servicio.id)}>
                        <i className='fas fa-trash-alt'></i>
                    </button>
                    <button
                        type='button'
                        onClick={this.props.toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {this.state.saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Guardar
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

export default withRequest(EditServicio);
