import React from "react";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatCurrency, toast } from "../../utils/helpers";
import { postRequest } from "../../utils/request";

export default function Enviar({ producto, toggle, getProductos, almacenes }) {

    async function enviarProducto(event) {
        event.preventDefault();

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl(`productos/${producto.id}/enviar`), data);

        if (response.success) {
            getProductos();
            toggle(producto);
            toast("Producto enviado exitosamente.");
        } else {
            toast("El producto no ha podido enviarse.", "error");
        }
    }

    return (
        <form method='post' onSubmit={enviarProducto}>
            <div className='popup__header'>Enviar producto a otro almacén</div>
            <div className='popup__body'>
                <h3>{producto.nombre}</h3>
                <table className='table m-b-15'>
                    <tbody>
                        <tr>
                            <td>
                                <strong>REF: </strong>
                                {producto.referencia}
                            </td>
                            <td>
                                <strong>Costo</strong>:{" "}
                                {formatCurrency(producto.costo)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Almacén: </strong>
                                {producto.almacen?.nombre}
                            </td>
                            <td>
                                <strong>Disponible</strong>: {producto.cantidad}
                            </td>
                        </tr>
                    </tbody>
                </table>
                    <div className='form-group m-t-0'>
                        <label>Almacen</label>
                        <select
                            required
                            className='form-control'
                            name='almacen'>
                            <option>- Seleccionar -</option>
                           {/*eslint-disable-next-line array-callback-return*/}
                            {almacenes.map((almacen) => {
                                if(almacen?.tipo_producto === "productos" && producto.almacen?.id !== almacen.id) {
                                    return (
                                        <option value={almacen.id}>
                                            {almacen.nombre}
                                        </option>
                                    );
                                }
                            })}
                        </select>
                    </div>
                <div className='form-group m-t-0'>
                    <label>Cantidad</label>
                    <input
                        className='form-control'
                        type='number'
                        name='cantidad'
                        min='0.01'
                        max={producto.cantidad}
                        required
                        step='0.01'
                    />
                </div>
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                <button type='submit' className='btn btn-primary'>
                    Enviar
                </button>
            </div>
        </form>
    );
}
