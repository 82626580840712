import React, { Component } from "react";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatCurrency, formatNumber, toast } from "../../utils/helpers";
import dayjs from "dayjs";
import Loader from "../general/Loader";
import { request } from "../../utils/request";

export default class ReporteCombustible extends Component {
    state = {
        items: [],
        totalToneladas: 0,
        success: false
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {
        const response = await request(apiUrl(`reportes` + window.location.search));

        if (response.success) {
            this.setState({
                items: response.data.gastos,
                totalToneladas: response.data.totalToneladas,
                contenedor: response.data.contenedor,
                success: true
            });
        } else {
            toast("Hubo un error y no se pueden cargar los datos.", "error");
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { items, totalToneladas, success, contenedor } = this.state;

        if (items.length === 0 && success === false) {
            return <Loader />;
        }

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            suc => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);
        const itemsList = items.reduce((res, item) => {
            if (!res[item.ficha]) {
                res[item.ficha] = [];
                res[item.ficha].push(item);
            } else {
                res[item.ficha].push(item);
            }
            return res;
        }, {});

        /* const totalToneladas = items.reduce(
            (acc, item) => acc + parseFloat(item.toneladas),
            0
        ); */

        const totalGalones = items.reduce(
            (acc, item) => acc + parseFloat(item.cantidad),
            0
        );

        const totalGastos = items.reduce(
            (acc, item) => acc + parseFloat(item.total),
            0
        );

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Reporte de Asignación de Combustible
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(query.desde).format("DD/MM/YYYY")} -{" "}
                        {dayjs(query.hasta).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10 m-t-30 m-b-30'>
                        <table class="table text-center">
                            <thead>
                                <tr>
                                    <th>VEHÍCULOS</th>
                                    <th>TOTAL VENTAS</th>
                                    <th>TOTAL DE GALONES</th>
                                    <th>GASTO TOTAL</th>
                                    <th>DISPONIBLE</th>
                                    <th>CAPACIDAD</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{fontSize: 22}}>
                                    <td>{Object.keys(itemsList).length}</td>
                                    <td>{formatNumber(totalToneladas)}</td>
                                    <td>{formatNumber(totalGalones)}</td>
                                    <td>{formatCurrency(totalGastos)}</td>
                                    <td>{formatNumber(contenedor.disponible)}</td>
                                    <td>{formatNumber(contenedor.capacidad)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {items.length > 0 ? (
                    <div>
                        {query.desde === query.hasta ? (
                            <SimpleList items={items} />
                        ) : (
                            <div>
                                {Object.keys(itemsList).map(ficha => {
                                    return (
                                        <div key={ficha} className='m-b-25'>
                                            <h3>
                                                {ficha} -{" "}
                                                {itemsList[ficha][0].categoria}
                                            </h3>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>Fecha</th>
                                                        <th>Item</th>
                                                        <th className='text-center'>
                                                            Ventas
                                                        </th>
                                                        <th className='text-center'>
                                                            Viajes
                                                        </th>
                                                        <th className='text-center'>
                                                            Galones
                                                        </th>
                                                        <th className='text-right'>
                                                            Costo/UND
                                                        </th>
                                                        <th className='text-right'>
                                                            Gasto Combustible
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {itemsList[ficha].map(
                                                        (item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {dayjs(
                                                                            item.fecha
                                                                        ).format(
                                                                            "DD/MM/YYYY"
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.item
                                                                        }
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {formatNumber(
                                                                            item.toneladas /
                                                                                1000
                                                                        )}
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {
                                                                            item.viajes
                                                                        }
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {
                                                                            item.cantidad
                                                                        }
                                                                    </td>
                                                                    <td className='text-right'>
                                                                        {formatCurrency(
                                                                            item.costo
                                                                        )}
                                                                    </td>
                                                                    <td className='text-right'>
                                                                        {formatCurrency(
                                                                            item.total
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                            <strong>
                                                                TOTALES:
                                                            </strong>
                                                        </td>
                                                        <td className='text-center'>
                                                            <strong>
                                                                {formatNumber(
                                                                    itemsList[
                                                                        ficha
                                                                    ].reduce(
                                                                        (
                                                                            acc,
                                                                            item
                                                                        ) =>
                                                                            acc +
                                                                            parseFloat(
                                                                                item.toneladas /
                                                                                    1000
                                                                            ),
                                                                        0
                                                                    )
                                                                )}
                                                            </strong>
                                                        </td>
                                                        <td className='text-center'>
                                                            <strong>
                                                                {formatNumber(
                                                                    itemsList[
                                                                        ficha
                                                                    ].reduce(
                                                                        (
                                                                            acc,
                                                                            item
                                                                        ) =>
                                                                            acc +
                                                                            parseFloat(
                                                                                item.viajes
                                                                            ),
                                                                        0
                                                                    )
                                                                )}
                                                            </strong>
                                                        </td>
                                                        <td className='text-center'>
                                                            <strong>
                                                                {formatNumber(
                                                                    itemsList[
                                                                        ficha
                                                                    ].reduce(
                                                                        (
                                                                            acc,
                                                                            item
                                                                        ) =>
                                                                            acc +
                                                                            parseFloat(
                                                                                item.cantidad
                                                                            ),
                                                                        0
                                                                    )
                                                                )}
                                                            </strong>
                                                        </td>
                                                        <td></td>
                                                        <td className='text-right'>
                                                            <strong>
                                                                {formatCurrency(
                                                                    itemsList[
                                                                        ficha
                                                                    ].reduce(
                                                                        (
                                                                            acc,
                                                                            item
                                                                        ) =>
                                                                            acc +
                                                                            parseFloat(
                                                                                item.total
                                                                            ),
                                                                        0
                                                                    )
                                                                )}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                ) : (
                    <div class='text-center'>
                        No hay resultados para los parametros indicados.
                    </div>
                )}
                <div className='text-right p-b-10 m-t-50'>
                    <div className='float-left'>
                        VEHÍCULOS: {Object.keys(itemsList).length}
                    </div>

                    <div className='float-right'>
                        <h3>
                            <span className='m-r-40'>
                                Total Ventas:{" "}
                                {formatNumber(totalToneladas / 1000)}
                            </span>
                            <span className='m-r-40'>
                                Total de Galones: {formatNumber(totalGalones)}
                            </span>
                            <span>
                                GASTO TOTAL: {formatCurrency(totalGastos)}
                            </span>
                        </h3>
                    </div>
                </div>
            </div>
        );
    }
}

function SimpleList({ items }) {
    return (
        <table className='table'>
            <thead>
                <tr>
                    <th>Fecha</th>
                    <th>Ficha</th>
                    <th>Item</th>
                    <th className='text-center'>Ventas</th>
                    <th className='text-center'>Viajes</th>
                    <th className='text-center'>Galones</th>
                    <th className='text-right'>Costo/UND</th>
                    <th className='text-right'>Gasto Combustible</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{dayjs(item.fecha).format("DD/MM/YYYY")}</td>
                            <td>{item.ficha}</td>
                            <td>{item.item}</td>
                            <td className='text-center'>
                                {formatNumber(item.toneladas / 1000)}
                            </td>
                            <td className='text-center'>{item.viajes}</td>
                            <td className='text-center'>{item.cantidad}</td>
                            <td className='text-right'>
                                {formatCurrency(item.costo)}
                            </td>
                            <td className='text-right'>
                                {formatCurrency(item.total)}
                            </td>
                        </tr>
                    );
                })}
                <tr>
                    <td></td>
                    <td></td>
                    <td>
                        <strong>TOTALES:</strong>
                    </td>
                    <td className='text-center'>
                        <strong>
                            {formatNumber(
                                items.reduce(
                                    (acc, item) =>
                                        acc + parseFloat(item.toneladas / 1000),
                                    0
                                )
                            )}
                        </strong>
                    </td>
                    <td className='text-center'>
                        <strong>
                            {formatNumber(
                                items.reduce(
                                    (acc, item) =>
                                        acc + parseFloat(item.viajes),
                                    0
                                )
                            )}
                        </strong>
                    </td>
                    <td className='text-center'>
                        <strong>
                            {formatNumber(
                                items.reduce(
                                    (acc, item) =>
                                        acc + parseFloat(item.cantidad),
                                    0
                                )
                            )}
                        </strong>
                    </td>
                    <td></td>
                    <td className='text-right'>
                        <strong>
                            {formatCurrency(
                                items.reduce(
                                    (acc, item) => acc + parseFloat(item.total),
                                    0
                                )
                            )}
                        </strong>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
