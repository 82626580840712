import React, { Component } from "react";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatNumber, formatCurrency } from "../../utils/helpers";
import dayjs from "dayjs";
import Loader from "../general/Loader";
import { request } from "../../utils/request";

export default class ReporteRelacionGastosProduccion extends Component {
    state = {
        vehiculos: false
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {
        const response = await request(apiUrl(`reportes` + window.location.search));

        if (response.success) {
            this.setState({
                vehiculos: response.data
            });
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { vehiculos } = this.state;

        if (!vehiculos) {
            return <Loader />;
        }

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            suc => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);

        const beneficios = vehiculos.reduce(
            (acc, vehiculo) => acc + parseFloat(vehiculo.beneficios),
            0
        );

        const totalDescuentos = vehiculos.reduce(
            (acc, vehiculo) => acc + parseFloat(vehiculo.descuentos),
            0
        );

        const totalVentas = vehiculos.reduce(
            (acc, vehiculo) => acc + parseFloat(vehiculo.ventas),
            0
        );

        const totalDepositos = vehiculos.reduce(
            (acc, vehiculo) => acc + parseFloat(vehiculo.depositos),
            0
        );

        const totalDolares = vehiculos.reduce(
            (acc, vehiculo) => acc + parseFloat(vehiculo.dolares),
            0
        );

        const totalGastos = vehiculos.reduce(
            (acc, vehiculo) => acc + parseFloat(vehiculo.repuestosServicios),
            0
        );

        const totalCombustible = vehiculos.reduce(
            (acc, vehiculo) => acc + parseFloat(vehiculo.gastoCombustible),
            0
        );

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Relación de Gastos vs Producción
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(query.desde).format("DD/MM/YYYY")} -{" "}
                        {dayjs(query.hasta).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            Total de vehículos: {vehiculos.length}
                        </div>

                        <div className='float-right'>
                            <h3>
                                Ganancia Total: {formatCurrency(beneficios)}
                            </h3>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{ width: "80px" }}>Ficha</th>
                            <th className='text-center'>Cuadres</th>
                            <th className='text-center'>Galones</th>
                            <th className='text-right'>Combustible</th>
                            <th className='text-right'>Ventas</th>
                            <th className='text-right'>Depósitos</th>
                            <th className='text-right'>Dólares</th>
                            <th className='text-right'>Gastos</th>
                            <th className='text-right'>Descuentos</th>
                            <th className='text-right'>Beneficios</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vehiculos.map(vehiculo => (
                            <tr key={vehiculo.ficha}>
                                <td>{vehiculo.ficha}</td>
                                <td className='text-center'>
                                    {vehiculo.viajes}
                                </td>
                                <td className='text-center'>
                                    {formatNumber(vehiculo.galones)}
                                </td>
                                <td className='text-right'>
                                    {formatCurrency(vehiculo.gastoCombustible)}
                                </td>
                                <td className='text-right'>
                                    {formatCurrency(vehiculo.ventas)}
                                </td>
                                <td className='text-right'>
                                    {formatCurrency(vehiculo.depositos)}
                                </td>
                                <td className='text-right'>
                                    {formatCurrency(vehiculo.dolares)}
                                </td>
                                <td className='text-right'>
                                    {formatCurrency(
                                        vehiculo.repuestosServicios
                                    )}
                                </td>
                                <td className='text-right'>
                                    {formatCurrency(vehiculo.descuentos)}
                                </td>
                                <td className='text-right'>
                                    {formatCurrency(vehiculo.beneficios)}
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td></td>
                            <td></td>
                            <td>
                                <strong>TOTALES =></strong>
                            </td>
                            <td className='text-right'>
                                <strong>
                                    {formatCurrency(totalCombustible)}
                                </strong>
                            </td>
                            <td className='text-right'>
                                <strong>
                                    {formatCurrency(totalVentas)}
                                </strong>
                            </td>
                            <td className='text-right'>
                                <strong>
                                    {formatCurrency(totalDepositos)}
                                </strong>
                            </td>
                            <td className='text-right'>
                                <strong>
                                    {formatCurrency(totalDolares)}
                                </strong>
                            </td>
                            <td className='text-right'>
                                <strong>{formatCurrency(totalGastos)}</strong>
                            </td>
                            <td className='text-right'>
                                <strong>
                                    {formatCurrency(totalDescuentos)}
                                </strong>
                            </td>
                            <td className='text-right'>
                                <strong>{formatCurrency(beneficios)}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className='m-t-25'>
                    <div className='float-left'>
                        Total de vehículos: {vehiculos.length}
                    </div>

                    <div className='float-right'>
                        <h3>Ganancia Total: {formatCurrency(beneficios)}</h3>
                    </div>
                </div>
            </div>
        );
    }
}
