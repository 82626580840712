import React, { Component } from "react";
import dayjs from "dayjs";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import Loader from "../general/Loader";
import { toast } from "../../utils/helpers";
import Form from "./Form";
import { postRequest } from "../../utils/request";

export default class Create extends Component {
    state = {
        saving: false,
        empleado: {},
        fechaInicio: dayjs().format("YYYY-MM-DD"),
        intervalo: 1,
        cuotas: 1,
        monto: '',
        errors: {},
    };
    createDescuento = async event => {
        event.preventDefault();

        this.setState({
            saving: true
        });

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl('descuentos'), data);

        if (response.success) {
            this.props.getDescuentosProgramados();
            document.getElementById("descuentosform").reset();
            this.props.toggle();
            toast("Nuevo descuento creado.");
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({
            saving: false
        });
    };
    render() {
        const {empleados, descuento, columnas} = this.props;
        const {errors} = this.state;

        return (
            <form
                id='descuentosform'
                method='post'
                onSubmit={this.createDescuento}>
                <input type='hidden' name='_method' value='post' />
                <div className='popup__header'>Crear Descuento Programado</div>
                <Form
                    errors={errors}
                    empleados={empleados}
                    descuento={descuento}
                    columnas={columnas}
                />
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={this.props.toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {this.state.saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Crear
                        </button>
                    )}
                </div>
            </form>
        );
    }
}
