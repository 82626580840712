import React, { Component } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import Loader from "../general/Loader";
import { toast, getLink } from "../../utils/helpers";
import PageHeader from "../general/PageHeader";
import { withRouter } from "react-router";
import { postRequest } from "../../utils/request";

class CreateItem extends Component {
	state = {
		errors: {},
		item: {},
		saving: false,
	};
	createItem = async (event) => {
		event.preventDefault();

		this.setState({
			saving: true,
		});

		const data = new FormData(event.target);
		const response = await postRequest(apiUrl("items"), data);

		if (response.success) {
			this.props.history.push(getLink("/inventario"));
		}
		if (response.code === 422) {
			this.setState({ errors: response.data.message });
		} else if (response.code === 400 || response.code === 500) {
			toast(response.data.message, "error");
		}

		this.setState({
			saving: false,
		});
	};

	cancel = () => {
		this.props.history.push(getLink("/inventario"));
	};
	render() {
		const { errors, item } = this.state;
		return (
			<div className="container-fluid">
				<PageHeader
                    title="Nuevo Item/Servicio"
                    goBackLink={getLink('/inventario')}
                    goBackText="Volver a Items"
                    hasButton={false}
                />
				<form method="post" onSubmit={this.createItem}>
					<Form item={item} errors={errors} />
					<div className="page-footer text-right">
						<button
							type="button"
							onClick={this.cancel}
							className="btn btn-plain"
						>
							Cancelar
						</button>
						{this.state.saving ? (
							<Loader type="small" />
						) : (
							<button type="submit" className="btn btn-primary">
								Crear
							</button>
						)}
					</div>
				</form>
			</div>
		);
	}
}

export default withRouter(CreateItem);
