import React, { Component } from "react";
import { postRequest, withRequest } from "../../utils/request";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import Loader from "../general/Loader";
import Form from "./Form";
import { toast } from "../../utils/helpers";

class CreateServicio extends Component {
    state = {
        vehiculos: [],
        errors: [],
        saving: false,
    };
    componentDidMount() {
        this.getTable("vehiculos");
    }
    createServicio = async (event) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl(`servicios`), data);

        if (response.success) {
            if (this.props.getServicios) {
                this.props.getServicios();
            }
            document.getElementById("serviciosform").reset();
            this.props.toggle();

            toast("Servicio creada exitosamente.");
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({
            saving: false,
        });
    };
    getTable = async (url = null, params = "") => {
        const table = await this.props.request(url + params);

        if (table.data && table.data.length > 0) {
            this.setState({
                [url]: table.data,
            });
        }
    };
    render() {
        const { vehiculos, errors } = this.state;
        return (
            <form
                id='serviciosform'
                method='post'
                onSubmit={this.createServicio}>
                <input type='hidden' name='_method' value='post' />
                <div className='popup__header'>Nuevo Servicio</div>
                <Form vehiculos={vehiculos} errors={errors} />
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={() => this.props.toggle()}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {this.state.saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Crear
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

export default withRequest(CreateServicio);
