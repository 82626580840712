import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import { formatCurrency, toast } from "../../../utils/helpers";
import Loader from "../../general/Loader";
import { postRequest } from "../../../utils/request";

export default class MovimientosForm extends Component {
    state = {
        id: null,
        saving: false,
        errors: {}
    };
    updateMovimientos = async event => {
        event.preventDefault();

        this.setState({ saving: true });

        const { prestamo } = this.props;

        const data = new FormData(event.target);
        const url = `gi-prestamos/${prestamo.id}/movimientos`;
        const response = await postRequest(apiUrl(url), data);

        if (response.success) {
            this.props.getPrestamos();
            toast(response.data);
            //this.props.toggle(false);
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });

            if (response.data.message.numero) {
                toast("Hay un error en los campos. Favor verificar.", "error");
            }
        }

        this.setState({ saving: false });
    };
    render() {
        const {
            saving
        } = this.state;

        const {prestamo} = this.props;

        return (
            <div className='tickets-form'>
                <table class="table table-prestamos m-b-30">
                    <thead>
                        <tr>
                            <th><small>Deuda Inicial:</small></th>
                            <th><small>Balance Deuda Inicial:</small></th>
                            <th><small>Total de Abonos:</small></th>
                            <th><small>Total de Préstamos:</small></th>
                            <th><small>Total Adeudado:</small></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <strong>{formatCurrency(prestamo.deudaInicial)}</strong>
                            </td>
                            <td>
                                <strong>{formatCurrency(prestamo.deudaInicialP)}</strong>
                            </td>
                            <td>
                                <strong>{formatCurrency(prestamo.totalAbono)}</strong>
                            </td>
                            <td>
                                <strong>{formatCurrency(prestamo.totalPrestamo)}</strong>
                            </td>
                            <td>
                                <strong>{formatCurrency(prestamo.totalAdeudado)}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <form
                    id='ticketForm'
                    method='post'
                    onSubmit={this.updateMovimientos}>
                    <input type='hidden' name='_method' value='patch' />
                    <input type='hidden' name='prestamo_id' value={prestamo.id} />
                    <div className="row">
                        <div className="col-md-12">
                            <table className='table table-produccion'>
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <th>Abono</th>
                                        <th>Prestamo</th>
                                    </tr>
                                    {prestamo.movimientos?.map((movimiento, i) => (
                                        <tr>
                                            <th>{movimiento.dia}</th>
                                            <td>
                                                <input type="hidden" name={`abonos[${i}][id]`} value={movimiento.id_abono} />
                                                <input type="hidden" name={`abonos[${i}][fecha]`} value={movimiento.fecha} />
                                                <input type="number" className="form-control" step="any" name={`abonos[${i}][monto]`} defaultValue={movimiento.abono} />
                                            </td>
                                            <td>
                                                <input type="hidden" name={`prestamos[${i}][id]`} value={movimiento.id_prestamo} />
                                                <input type="hidden" name={`prestamos[${i}][fecha]`} value={movimiento.fecha} />
                                                <input type="number" className="form-control" step="any" name={`prestamos[${i}][monto]`} defaultValue={movimiento.prestamo}/>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="text-right m-t-20">
                        {saving ? (
                            <Loader type='small' />
                        ) : (
                            <button className="btn btn-primary" type="submit">Guardar</button>
                        )}
                    </div>
                </form>
            </div>
        );
    }
}
