import React, { Component } from "react";
import { postRequest, withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import { toast, isMecanico } from "../../../utils/helpers";

import Form from "./Form";

class Edit extends Component {
    state = {
        empleados: [],
        servicios: [],
        saving: false,
        errors: {},
    };

    componentDidMount() {
        this.getTable("empleados", "?estado=activos");
        this.getTable("servicios", "?vehiculo=" + this.props.vehiculo.id);
    }

    editRegistro = async (event) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });

        const { vehiculoId, registro } = this.props;

        const data = new FormData(event.target);
        const url = `vehiculos/${vehiculoId}/servicios/${registro.id}`;
        const response = await postRequest(apiUrl(url), data);

        if (response.success) {
            document.getElementById("serviciosform").reset();
            this.props.toggle();
            toast("Servicio registrado exitosamente.");
            if (this.props.getRegistros) {
                this.props.getRegistros();
            }
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({
            saving: false,
        });
    };

    getTable = async (url = null, params = "") => {
        const table = await this.props.request(url + params);

        if (table.data && table.data.length > 0) {
            this.setState({
                [url]: table.data,
            });
        }
    };

    render() {
        const { vehiculo, registro, deleteRegistro } = this.props;
        const { empleados, servicios, errors } = this.state;

        const mecanicos = empleados.filter((empleado) =>
            isMecanico(empleado.cargo)
        );

        return (
            <form
                vehiculos
                id='serviciosform'
                method='post'
                onSubmit={this.editRegistro}>
                <input type='hidden' name='_method' value='patch' />
                <div className='popup__header'>Editar Registro de Servicio</div>
                <div className='popup__body'>
                    <input
                        type='hidden'
                        name='vehiculo_id'
                        value={vehiculo.id}
                    />
                    <Form
                        vehiculo={vehiculo}
                        mecanicos={mecanicos}
                        servicios={servicios}
                        registro={registro}
                        errors={errors}
                    />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={(e) => {
                            deleteRegistro(e, registro.id);
                        }}>
                        <i className='fas fa-trash-alt'></i>
                    </button>
                    <button
                        type='button'
                        onClick={this.props.toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {this.state.saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Actualizar
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

export default withRequest(Edit);
