import React, { Component } from "react";
import Select from "react-select";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { toast } from "../../utils/helpers";
import { postRequest, withRequest } from "../../utils/request";
import Modal from "../general/Modal";
import dayjs from "dayjs";

class Edit extends Component {
    state = {
        fecha: '',
        monto: '',
        errors: {},
        incentivo: {},
        showDelete: false,
    };
    editIncentivo = async (event) => {
        event.preventDefault();


        const data = new FormData(event.target);

        const response = await postRequest(
            apiUrl(`incentivos/${this.props.incentivoId}`),
            data
        );

        if (response.success) {
            this.props.getIncentivos();
            this.props.toggle();
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };

    onChangeFrecuencia = (e) => {
        if (!e.value) return;

        this.setState({
            frecuencia: e.value,
        });
    };

    deleteIncentivo = async (incentivo) => {


        const url = `incentivos/${incentivo}`;

        const response = await postRequest(apiUrl(url), {}, 'delete');



        if (response.success) {
            this.props.getIncentivos();
            this.props.toggle();
            toast("Incentivo borrado.");
            return;
        } else {
            toast(response.data, "error");
        }
    };
    toggleDeleteModal = () => {
        this.setState({
            showDelete: !this.state.showDelete,
        });
    };
    render() {
        const { errors } = this.state;
        const { toggle, incentivo, incentivoId } = this.props;

        return (
            <div>
                <form method='post' onSubmit={this.editIncentivo}>
                    <input type='hidden' name='_method' value='patch' />
                    <div className='popup__header'>Editar Incentivo</div>
                    <div className="popup__body">
                        <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Fecha:
                                </label>
                            </div>
                            <div className='col col-md-8'>
                                <input
                                    type='date'
                                    name='fecha'
                                    defaultValue={dayjs(incentivo.fecha).format('YYYY-MM-DD')}
                                    className='form-control'
                                />
                                {errors.fecha && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.fecha[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Frecuencia:
                                </label>
                            </div>
                            <div className='col col-md-8'>
                                <Select
                                    name='frecuencia'
                                    onChange={this.onChangeFrecuencia}
                                    required
                                    defaultValue={{
                                        value: incentivo.frecuencia,
                                        label: incentivo.frecuencia,
                                    }}
                                    options={[{
                                        value: 'fijo',
                                        label: 'Fijo',
                                    },{
                                        value: 'unico',
                                        label: 'Único',
                                    },]}
                                />
                                {errors.frecuencia && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.frecuencia[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Monto:
                                </label>
                            </div>
                            <div className='col col-md-8'>
                                <input
                                    type='number'
                                    name='monto'
                                    step="0.01"
                                    defaultValue={incentivo.monto}
                                    className='form-control'
                                />
                                {errors.monto && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.monto[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Descripción:
                                </label>
                            </div>
                            <div className='col col-md-8'>
                                <textarea
                                    name='descripcion'
                                    defaultValue={incentivo.descripcion}
                                    className='form-control'
                                />
                                {errors.descripcion && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.descripcion[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='popup__footer'>
                        <button
                            type='button'
                            className='btn float-left'
                            onClick={this.toggleDeleteModal}>
                            <i className='fas fa-trash-alt'></i>
                        </button>
                        <button
                            type='button'
                            onClick={toggle}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        <button type='submit' className='btn btn-primary'>
                            Guardar
                        </button>
                    </div>
                </form>
                <Modal
                    title='Borrar Incentivo'
                    show={this.state.showDelete}
                    callback={() => this.deleteIncentivo(incentivoId)}
                    acceptText='Borrar'
                    toggle={this.toggleDeleteModal}>
                    ¿Estás seguro de que deseas borrar este incentivo?
                </Modal>
            </div>
        );
    }
}

export default withRequest(Edit);
