import React, { Component } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import { toast } from "../../utils/helpers";
import { withRouter } from "react-router-dom";
import { postRequest, withRequest } from "../../utils/request";

class Edit extends Component {
    state = {
        errors: {},
        ruta: {},
    };
    componentDidMount() {
        this.getRuta();
    }
    getRuta = async () => {
        const id = this.props.match.params.id;
        const ruta = await this.props.request('rutas/' + id);
        if (ruta.data) {
            this.setState({
                ruta: ruta.data,
            });
        }
    }
    editUser = async (event) => {
        event.preventDefault();


        const data = new FormData(event.target);
        const id = this.props.match.params.id;

        const response = await postRequest(apiUrl(`rutas/${id}`), data);



        if (response.success) {
            this.props.getRutas();
            this.props.toggle();
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    deleteRuta = async (event, ruta) => {
        event.preventDefault();



        const url = `rutas/${ruta}`;

        const response = await postRequest(apiUrl(url), {}, 'delete');



        if (response.success) {
            this.props.getRutas();
            this.props.toggle();
            toast("Zona borrada.");
            return;
        } else {
            toast(response.data, "error");
        }
    };
    render() {
        const { errors, ruta } = this.state;
        const { toggle } = this.props;
        return (
            <form method='post' onSubmit={this.editUser}>
                <input type='hidden' name='_method' value='patch' />
                <div className='popup__header'>Editar Zona</div>
                <div className='popup__body'>
                    <Form ruta={ruta} errors={errors} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={(e) => this.deleteRuta(e, ruta.id)}>
                        <i className='fas fa-trash-alt'></i>
                    </button>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

export default withRouter(withRequest(Edit));
