import React, { Component } from "react";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import dayjs from "dayjs";
import Loader from "../general/Loader";
import { request } from "../../utils/request";

export default class ReporteLicenciasVencidas extends Component {
    state = {
        empleados: false,
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {
        const response = await request(apiUrl(`reportes` + window.location.search));

        if (response.success) {
            this.setState({
                empleados: response.data,
            });
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { empleados } = this.state;

        if (!empleados) {
            return <Loader />;
        }

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Licencias Vencidas
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(query.desde).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                        </div>

                        <div className='float-right'>
                            Empleados: {empleados.length}
                        </div>
                    </div>
                </div>

                <table className='table'>
                    <thead>
                        <tr>
                            <th>
                                Empleado
                            </th>
                            <th>Categoría</th>
                            <th>Teléfono</th>
                            <th>Flota</th>
                            <th className="text-right">Fecha Expiración</th>
                        </tr>
                    </thead>
                    <tbody>
                        {empleados.map((empleado) => {
                        return (
                            <tr key={empleado.id}>
                                <td>
                                    {empleado.nombreCompleto}
                                </td>
                                <td>{empleado.licencia}</td>
                                <td>{empleado.telefono}</td>
                                <td>
                                    {empleado.flota}
                                </td>
                                <td className="text-right">
                                    {dayjs(empleado.licenciaExpira).format('DD MMM, YYYY')}
                                </td>
                            </tr>
                            )}
                        )}
                    </tbody>
                </table>
                <div className='col-md-12 text-right p-b-10'>
                    <div className='float-left'>
                    </div>

                    <div className='float-right'>
                        Empleados: {empleados.length}
                    </div>
                </div>
            </div>
        );
    }
}
