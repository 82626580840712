import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import { postRequest, withRequest } from "../../utils/request";
import Loader from "../general/Loader";
import Tarjeta from "./Tarjetas/Tarjeta";
import Modal from "../general/Modal";
import Create from "./Tarjetas/Create";
import EditTarjeta from "./Tarjetas/Edit";
import TicketList from "./tickets/List";
import ProduccionForm from "../GI/Produccion/Form";
import SolicitudesForm from "../Empleados/Solicitudes/Create";
// import AusenciasForm from "../Empleados/Ausencias/Form";
import { apiUrl } from "../../utils/apiUrl";
import { auth } from "../../utils/auth";
import Categorias from "./Categorias";
import { toast, getLink, formatCurrency } from "../../utils/helpers";

class Edit extends Component {
    state = {
        programacion: null,
        tarjetas: [],
        cuentas: [],
        filteredTarjetas: [],
        empleados: false,
        rutas: [],
        categorias: [],
        activo: 1,
        loading: true,
        saving: false,
        tarjetaEditing: {},
        showCreate: false,
        showEdit: false,
        tarjetaTickets: {},
        showTickets: false,
        showDropdown: false,
        showSolicitud: false,
        showAusencia: false,
        showDelete: false,
        isSearching: false,
        search: "",
    };

    constructor(props) {
        super(props);
        this.sidePopupWrap = React.createRef();
        this.sidePopupOverlay = React.createRef();
    }

    componentDidMount() {
        this.getProgramacion();
        this.getEmpleados();
        this.getCategorias();
        this.getCuentas();
    }

    getCategorias = async () => {
        const response = await this.props.request("categorias-asignaciones");

        if (response.data && response.data.length) {
            this.setState({
                categorias: response.data,
                categoriaActiva: response.data.length ? response.data[0].id : 0,
            });
        }
    };

    getProgramacion = async () => {
        const id = this.props.match.params.id;
        const response = await this.props.request("programaciones/" + id);

        if (response.success) {
            this.setState({
                programacion: {
                    id: response.data.id,
                    nombre: response.data.nombre,
                    dia: response.data.dia,
                    categoriaAsignacion: response.data.categoriaAsignacion,
                    fecha: response.data.fecha,
                },
                tarjetas: response.data.tarjetas,
            });

            if (this.isReady()) {
                this.setState({
                    loading: false,
                });
            }
        }
    };

    limpiarProgramacion = async () => {
        const id = this.props.match.params.id;
        const response = await this.props.request(
            "programaciones/" + id + "/limpiar"
        );

        if (response.success) {
            this.setState({
                programacion: {
                    id: response.data.id,
                    nombre: response.data.nombre,
                    categoriaAsignacion: null,
                    dia: response.data.dia,
                    fecha: response.data.fecha,
                },
                tarjetas: response.data.tarjetas,
            });

            if (this.isReady()) {
                this.setState({
                    loading: false,
                });
            }
        }
    };

    cargarTarjetas = async (event) => {
        event.preventDefault();
        this.setState({
            saving: true,
        });


        const data = new FormData(event.target);
        const id = this.props.match.params.id;
        const response = await postRequest(
            apiUrl("programaciones/" + id + "/cargar-tarjetas"),
            data
        );



        if (response.success) {
            this.setState({
                programacion: {
                    ...this.state.programacion,
                    categoriaAsignacion: response.data.categoriaAsignacion,
                },
                tarjetas: response.data.tarjetas,
                saving: false,
            });
        }

        if (response.code === 422) {
            this.setState({
                saving: false,
            });
            toast("Debes seleccionar una categoría", "error");
        }
    };

    getEmpleados = async () => {
        const response = await this.props.request("empleados?estado=activos");

        if (response.data && response.data.length) {
            this.setState({
                empleados: response.data,
            });
        } else {
            this.setState({
                empleados: [],
            });
        }

        if (response.data && this.isReady()) {
            this.setState({
                loading: false,
            });
        }
    };

    getCuentas = async () => {
        const response = await this.props.request("cuentas");

        if (response.data && response.data.length) {
            this.setState({
                cuentas: response.data,
            });
        } else {
            this.setState({
                cuentas: [],
            });
        }

        if (response.data && this.isReady()) {
            this.setState({
                loading: false,
            });
        }
    };

    clickFicha = (activo) => {
        const elem = document.getElementById(activo);
        window.scroll({
            top: elem.offsetTop,
            left: 0,
            behavior: "smooth",
        });
        this.setState({
            activo,
        });
    };

    tarjetaTickets = (activo) => {
        this.setState({
            activo,
        });
        this.toggleTickets(true);
    };

    toggleTickets = (show) => {
        if (!show) {
            this.sidePopupWrap.current.classList.remove("slideInRight");
            this.sidePopupWrap.current.classList.add("slideOutRight");
            this.sidePopupOverlay.current.classList.remove("fadeIn");
            this.sidePopupOverlay.current.classList.add("fadeOut");
            setTimeout(() => {
                this.setState({
                    showTickets: show,
                });
            }, 1000);
            return;
        }

        this.setState({
            showTickets: true,
        });
    };

    onSearch = (e) => {
        this.setState({
            search: e.target.value.toLowerCase(),
        });
    };

    isReady = () => {
        const { empleados, programacion } = this.state;
        return empleados !== false && programacion;
    };

    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
        });
    };

    toggleDeleteModal = () => {
        this.setState({
            showDelete: !this.state.showDelete,
        });
    };

    toggleEditModal = (tarjeta) => {
        this.setState({
            showEdit: !this.state.showEdit,
            tarjetaEditing: tarjeta,
        });
    };

    toggleSolicitudModal = (tarjeta) => {
        this.setState({
            showSolicitud: !this.state.showSolicitud,
        });
        this.toggleDropdown(false);
    };

    toggleAusenciaModal = (tarjeta) => {
        this.setState({
            showAusencia: !this.state.showAusencia,
        });
        this.toggleDropdown(false);
    };

    toggleDropdown = (show = 0) => {
        this.setState({
            showDropdown: show === 0 ? !this.state.showDropdown : show,
        });
    };

    updateTarjetas = (tarjeta) => {
        this.setState((prevState) => ({
            tarjetas: prevState.tarjetas.map((tar) =>
                tar.id === tarjeta.id ? tarjeta : tar
            ),
        }));
    };

    render() {
        const {
            programacion,
            empleados,
            loading,
            activo,
            tarjetas,
            showTickets,
            showDropdown,
            categorias,
            saving,
            isSearching,
            search,
            cuentas,
        } = this.state;

        //Mostrar solo tickets the la tarjeta seleccionada
        const tarjetaTickets = tarjetas.filter((tarj) => tarj.id === activo)[0];

        //Tickets solo del chofer
        const soloChofer = tarjetaTickets ? tarjetaTickets.tickets.filter((ticket) => ticket.soloChofer) : [];

        const empleadosDespachados = tarjetas.reduce((acc, tar) => {
            if (tar.despachada && tar.activa) {
                tar.operadores.map(
                    (op) => op.asignadoId && acc.push(op.asignadoId)
                );
            }
            return acc;
        }, []);

        const empleadosDisponibles =
            empleados &&
            empleados.filter((emp) => {
                return (
                    empleadosDespachados.indexOf(emp.id) === -1 &&
                    (emp.operaVehiculo ||
                        emp.tipo.toLowerCase().indexOf("ayudante") > -1)
                );
            });

        const filteredTarjetas = tarjetas.filter((tarjeta) => {
            if (search) {
                return (
                    JSON.stringify(tarjeta).toLowerCase().indexOf(search) >= 0
                );
            } else {
                return true;
            }
        });

        if (!loading && empleados !== false && empleados.length === 0) {
            return (
                <div className='text-center m-t-50 m-b-50'>
                    No hay empleados creados. Primero debes crear algunos{" "}
                    <Link to={getLink("/empleados/crear")}>aquí</Link>
                </div>
            );
        }

        return (
            <div>
                {loading ? (
                    <Loader />
                ) : (
                    <div className='row'>
                        <div className='asignaciones-main'>
                            <div className='asignaciones-wrap'>
                                <div className='filtros-tarjetas'>
                                    <div
                                        className={
                                            "dropdown " +
                                            (showDropdown ? "show" : "")
                                        }>
                                        <button className='au-btn au-btn-icon au-btn-filter m-r-10'>
                                            <i className='zmdi zmdi-menu'></i>
                                        </button>
                                        <div className='dropdown-menu'>
                                            {/* <button
                                                onClick={
                                                    this.toggleAusenciaModal
                                                }
                                                className='dropdown-item'>
                                                Reportar Ausencias
                                            </button> */}
                                            <button
                                                onClick={
                                                    this.toggleSolicitudModal
                                                }
                                                className='dropdown-item'>
                                                Nueva Solicitud
                                            </button>
                                            <button
                                                onClick={this.toggleDeleteModal}
                                                className='dropdown-item'>
                                                Borrar Tarjetas no depachadas
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            "dropdown " +
                                            (showDropdown ? "show" : "")
                                        }>
                                        <button className='au-btn au-btn-icon au-btn-filter'>
                                            <i className='zmdi zmdi-print'></i>
                                        </button>
                                        <div className='dropdown-menu'>
                                            <Link
                                                to={getLink(
                                                    `/imprimir/programacion/${programacion.fecha}/ausentes`
                                                )}
                                                target='_blank'
                                                className='dropdown-item'>
                                                Imprimir Listado de Ausentes
                                            </Link>
                                            <Link
                                                to={getLink(
                                                    `/imprimir/programacion/${programacion.fecha}/no-despachados?informal=no&fecha=${programacion.fecha}&ids=` +
                                                    empleadosDisponibles
                                                        .map((e) => e.id)
                                                        .join(",")
                                                )}
                                                target='_blank'
                                                className='dropdown-item'>
                                                Imprimir No Despachados
                                            </Link>
                                            <Link
                                                to={getLink(
                                                    `/imprimir/programacion/${programacion.fecha}/despachados`
                                                )}
                                                target='_blank'
                                                className='dropdown-item'>
                                                Imprimir Programación
                                            </Link>
                                            {/* <Link
                                                to={getLink(
                                                    `/imprimir/programacion/${programacion.fecha}/tarjetas`
                                                )}
                                                target='_blank'
                                                className='dropdown-item'>
                                                Imprimir Tarjetas
                                            </Link> */}
                                        </div>
                                    </div>
                                    <input
                                        onChange={this.onSearch}
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control search'
                                    />
                                    <button
                                        onClick={this.toggleCreateModal}
                                        className='au-btn au-btn-icon au-btn--green au-btn--small'>
                                        <i className='zmdi zmdi-plus'></i>
                                        Añadir Tarjeta
                                    </button>
                                </div>
                                <div className='asignaciones-sidebar-left programaciones-sidebar-left'>
                                    <h3>
                                        <small>Programación:</small>
                                        <div>{programacion.nombre}</div>
                                        <small style={{ verticalAlign: 10 }}>
                                            <strong>{programacion.dia}</strong>
                                        </small>
                                    </h3>
                                    <ul className='list-unstyled'>
                                        {filteredTarjetas.map((tarjeta) => (
                                            <li
                                                className={
                                                    activo === tarjeta.id
                                                        ? "active"
                                                        : ""
                                                }
                                                key={tarjeta.id}>
                                                <div className='links-tarjetas'>
                                                    <a
                                                        href={"#" + tarjeta.id}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.clickFicha(
                                                                tarjeta.id
                                                            );
                                                        }}>
                                                        {tarjeta.ficha}
                                                    </a>
                                                    <span
                                                        onClick={() => {
                                                            if (
                                                                !tarjeta.despachada
                                                            ) {
                                                                toast(
                                                                    "Debes despachar la tarjeta antes de poder añadir tickets.",
                                                                    "error"
                                                                );
                                                                return;
                                                            }
                                                            if (
                                                                !tarjeta.activa
                                                            ) {
                                                                toast(
                                                                    "Esta tarjeta está cerrada.",
                                                                    "error"
                                                                );
                                                                return;
                                                            }
                                                            this.tarjetaTickets(
                                                                tarjeta.id
                                                            );
                                                        }}
                                                        className='tickets-count'>
                                                        <i className='far fa-sticky-note completed' />
                                                        {tarjeta.tickets.length}
                                                    </span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                {filteredTarjetas.length > 0 && (
                                    <div className='programacion-resumen'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th
                                                        style={{
                                                            width: "70%",
                                                        }}>
                                                        Asignación
                                                    </th>
                                                    <th className='text-right'>
                                                        Vehiculos
                                                    </th>
                                                    <th className='text-right'>
                                                        Reportado
                                                    </th>
                                                    <th className='text-right'>
                                                        Ventas
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {programacion.categoriaAsignacion && (
                                                            <div>
                                                                {
                                                                    programacion.categoriaAsignacion
                                                                }
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className='text-right'>
                                                        {tarjetas.length}
                                                    </td>
                                                    <td className='text-right'>
                                                        {tarjetas.reduce(
                                                            (acc, tar) =>
                                                                acc +
                                                                tar.tickets
                                                                    .length,
                                                            0
                                                        )}/{tarjetas.length}
                                                    </td>
                                                    <td className='text-right'>
                                                        {formatCurrency(
                                                            tarjetas.reduce(
                                                                (acc, tar) =>
                                                                    acc +
                                                                    parseFloat(
                                                                        tar.giTicket?.total ? tar.giTicket.total : 0
                                                                    ),
                                                                0
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <div className='asignaciones-main-column'>
                                    {filteredTarjetas.length > 0 ? (
                                        filteredTarjetas.map(
                                            (tarjeta, index) => (
                                                <Tarjeta
                                                    key={tarjeta.id}
                                                    index={index}
                                                    editar={
                                                        this.toggleEditModal
                                                    }
                                                    programacion={
                                                        programacion.id
                                                    }
                                                    tarjeta={tarjeta}
                                                    empleados={
                                                        empleadosDisponibles
                                                    }
                                                    clickFicha={() =>
                                                        this.tarjetaTickets(
                                                            tarjeta.id
                                                        )
                                                    }
                                                    getProgramacion={
                                                        this.getProgramacion
                                                    }
                                                    updateTarjetas={
                                                        this.updateTarjetas
                                                    }
                                                    empleadosDespachados={
                                                        empleadosDespachados
                                                    }
                                                />
                                            )
                                        )
                                    ) : (
                                        <div className='text-center'>
                                            {isSearching ? (
                                                <p className='m-b-20'>
                                                    No hay tareas que coincidan
                                                    con esta búsqueda.
                                                </p>
                                            ) : (
                                                <div>
                                                    <p className='m-b-20'>
                                                        Esta programacion
                                                        todavía no tiene
                                                        tarjetas creadas.
                                                        ¿Deseas cargar un grupo
                                                        de asignaciones?
                                                    </p>
                                                    <div>
                                                        <Categorias
                                                            cargarTarjetas={
                                                                this
                                                                    .cargarTarjetas
                                                            }
                                                            saving={saving}
                                                            categorias={
                                                                categorias
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {showTickets && (
                                    <div className='side-popup gi'>
                                        <div
                                            onClick={() =>
                                                this.toggleTickets(false)
                                            }
                                            ref={this.sidePopupOverlay}
                                            className='side-popup-background animated fadeIn'></div>
                                        <div
                                            ref={this.sidePopupWrap}
                                            className='side-popup-container animated slideInRight fast'>
                                            <div className='col-md-12'>
                                                <h2>
                                                    {tarjetaTickets.ficha}{" "}
                                                    <small> » Producción </small>
                                                    <div>
                                                        <small>{tarjetaTickets.ruta.nombre} » {tarjetaTickets.operadores[0]?.asignadoNombre}</small>
                                                    </div>
                                                </h2>
                                                {/* <TicketList
                                                    programacion={
                                                        programacion.id
                                                    }
                                                    tarjeta={tarjetaTickets.id}
                                                    tickets={compartidos}
                                                    getProgramacion={
                                                        this.getProgramacion
                                                    }
                                                /> */}
                                                <ProduccionForm
                                                    programacion={
                                                        programacion.id
                                                    }
                                                    tarjeta={tarjetaTickets}
                                                    cuentas={cuentas}
                                                    toggle={this.toggleTickets}
                                                    getProgramacion={
                                                        this.getProgramacion
                                                    }
                                                />
                                                {soloChofer.length > 0 && (
                                                    <div class="m-t-20">
                                                        <h3>Solo al Chofer</h3>
                                                        <TicketList
                                                            programacion={
                                                                programacion.id
                                                            }
                                                            tarjeta={tarjetaTickets.id}
                                                            tickets={soloChofer}
                                                            getProgramacion={
                                                                this.getProgramacion
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Modal
                            blank
                            show={this.state.showCreate}
                            toggle={this.toggleCreateModal}>
                            <Create
                                getProgramacion={() => this.getProgramacion()}
                                programacion={programacion.id}
                                toggle={this.toggleCreateModal}
                            />
                        </Modal>
                        <Modal
                            blank
                            show={this.state.showEdit}
                            toggle={this.toggleEditModal}>
                            <EditTarjeta
                                getProgramacion={() => this.getProgramacion()}
                                tarjeta={this.state.tarjetaEditing}
                                programacion={programacion.id}
                                toggle={this.toggleEditModal}
                            />
                        </Modal>
                        <Modal
                            blank
                            show={this.state.showSolicitud}
                            toggle={this.toggleSolicitudModal}>
                            <SolicitudesForm
                                empleado={{}}
                                toggle={this.toggleSolicitudModal}
                            />
                        </Modal>
                        {/* <Modal
                            blank
                            show={this.state.showAusencia}
                            toggle={this.toggleAusenciaModal}>
                            <AusenciasForm
                                empleado={{}}
                                toggle={this.toggleAusenciaModal}
                                fecha={this.props.match.params.id}
                            />
                        </Modal> */}
                        <Modal
                            title='Borrar tarjetas'
                            show={this.state.showDelete}
                            callback={this.limpiarProgramacion}
                            toggle={this.toggleDeleteModal}>
                            ¿Seguro que deseas borrar las tarjetas no
                            despachadas?
                        </Modal>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(withRequest(Edit));
