import React, { Component } from "react";
import { postRequest, withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import Create from "./Create";
import Modal from "../../general/Modal";
import Pagination from "../../general/Pagination";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { getLink } from "../../../utils/helpers";
import Select from "react-select";

class Ajustes extends Component {
	state = {
		ajustes: [],
		showCreate: false,
		loading: true,
		filtros: [],
		filtrosQuery: "",
	};
	componentDidMount() {
		this.getAjustes();
	}
	getAjustes = async (url = null) => {
		const id = this.props.producto.id;
		url = url ? url : `ajustes?producto=${id}`;
		const ajustes = await this.props.request(url);

		if (ajustes.data) {
			this.setState({
				ajustes: ajustes.data,
				meta: ajustes.meta,
				links: ajustes.links,
				loading: false,
			});
		}

		this.setState({ loading: false });
	};
	removeAjuste = async (event, ajuste) => {
		event.preventDefault();

		const response = await postRequest(apiUrl(`ajustes/${ajuste}`), {}, 'delete');

		if (response.success) {
			this.getAjustes();
			this.props.getProducto();
			return;
		} else {
			this.setState({ errors: response.data.message });
		}

		this.setState({ loading: false });
	};
	toggleCreateModal = () => {
		this.setState({
			showCreate: !this.state.showCreate,
		});
	};
	onFiltroChange = debounce(async (value, filtro) => {
		await this.setState({
			filtros: {
				...this.state.filtros,
				[filtro]: value,
			},
		});

		this.getAjustesFiltrados();
	}, 500);

	getAjustesFiltrados = async () => {
		const id = this.props.producto.id;
		let query = Object.keys(this.state.filtros).map((fil) => {
			return `${fil}=${this.state.filtros[fil]}`;
		});

		await this.setState({
			filtrosQuery: "?" + query.join("&"),
		});

		this.getAjustes(`ajustes?producto=${id}&` + query.join("&"));
	};
	render() {
		const { producto } = this.props;
		const { ajustes, loading, meta, links } = this.state;

		if (loading === true) {
			return <Loader />;
		}
		return (
			<div className='page-controls'>
				<div className="row justify-content-end pr-3">
					<div className="col-md-3 pr-0 pl-4">
						<label htmlFor="desde">DESDE</label>
						<input
							type="date"
							name="desde"
							id="desde"
							className="form-control"
							onChange={(e) =>
								this.onFiltroChange(e.target.value, "desde")
							}
						/>
					</div>
					<div className="col-md-3">
						<label htmlFor="hasta">HASTA</label>
						<input
							type="date"
							name="hasta"
							id="hasta"
							defaultValue={dayjs()
								.endOf("day")
								.format("YYYY-MM-DD")}
							className="form-control"
							onChange={(e) =>
								this.onFiltroChange(e.target.value, "hasta")
							}
						/>
					</div>
				</div>
				<div className="table-data__tool">
					<div className="table-data__tool-left row">
						<div className="col-md-2">
							<label htmlFor="existencia">EN INVENTARIO</label>
							<input
								type="text"
								id="existencia"
								value={producto.cantidad}
								className="form-control"
								readOnly
							/>
						</div>
						<div className="col-md-4 px-0">
							<label htmlFor="existencia">BUSCAR</label>
							<input
								type="text"
								id="buscar"
								className="form-control"
                                onChange={(e) =>
									this.onFiltroChange(e.target.value, "buscar")
								}
							/>
						</div>
						<div className="col-md-2 pr-0">
							<label htmlFor="incrementar">TIPO DE AJUSTE</label>
							<Select
								name="incrementar"
								id="incrementar"
								onChange={(e) =>
									this.onFiltroChange(e.value, "incrementar")
								}
								defaultValue={{
									value: "todos",
									label: "Todos",
								}}
								options={[
									{ value: "todos", label: "Todos" },
									{
										value: "incrementar",
										label: "Incrementar",
									},
									{ value: "reducir", label: "Reducir" },
								]}
							/>
						</div>

						<div className="col-md-4 d-flex justify-content-around align-items-end pt-4">
							<Link
								to={getLink(
									`/imprimir/ajustes-inventario?producto=${
										producto.id
									}&productoNombre=${
										producto.nombre
									}&${this.state.filtrosQuery.substring(1)}`
								)}
								target="_blank"
								className="au-btn au-btn-icon au-btn-filter m-r-20"
								style={{width: '45%', textAlign: 'center'}}
							>
								<i className="zmdi zmdi-print"></i> Imprimir
							</Link>
							<button
								onClick={this.toggleCreateModal}
								className="au-btn au-btn-icon au-btn--green au-btn--small m-r-15 mt-2 mt-lg-0"
								style={{width: '45%'}}
							>
								<i className="zmdi zmdi-plus" />
								Nuevo Ajuste
							</button>
						</div>
					</div>
				</div>
				{ajustes.length > 0 ? (
					<table className="table tickets-table">
						<thead>
							<tr>
								<th>Fecha</th>
								<th>Tipo de Ajuste</th>
								<th>Descripción</th>
								<th>Cantidad</th>
								<th>En Inventario</th>
								{/* <th></th> */}
							</tr>
						</thead>
						<tbody>
							{ajustes.map((ajuste) => (
								<tr key={ajuste.id}>
									<td>{ajuste.fecha}</td>
									<td>
										{ajuste.incrementar
											? "Incrementar"
											: "Reducir"}
									</td>
									<td>{ajuste.descripcion}</td>
									<td>{ajuste.cantidad}</td>
									<td>{ajuste.existencia}</td>
									<td>
										<div className="dropdown">
											<button className="au-btn au-btn-icon au-btn-filter">
												Acciones
											</button>
											<div
												style={{
													left: "-30%",
												}}
												className="dropdown-menu"
											>
												<Link
													to={getLink(
														`/imprimir/ajustes-inventario/${ajuste.id}?productoNombre=${
															producto.nombre
														}`
													)}
													target="_blank"
													className="dropdown-item"
													title="Imprimir"
												>
													Impresión
												</Link>
											</div>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					<div>No hay ningún ajuste realizado.</div>
				)}

				{meta && (
					<Pagination
						links={links}
						meta={meta}
						getData={this.getAjustes}
					/>
				)}

				<Modal
					show={this.state.showCreate}
					blank={true}
					toggle={this.toggleCreateModal}
				>
					<Create
						toggle={this.toggleCreateModal}
						producto={producto}
						getAjustes={this.getAjustes}
						getProducto={this.props.getProducto}
					/>
				</Modal>
			</div>
		);
	}
}

export default withRequest(Ajustes);
