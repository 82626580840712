import React, { useState } from "react";
import { Link } from "react-router-dom";

import Loader from "../../general/Loader";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import { getLink, toast } from "../../../utils/helpers";
import { postRequest } from "../../../utils/request";

export default function TicketTable({ ticket, toggle }) {
    const [saving, setSaving] = useState(false);

    async function updateTicket(e) {
        e.preventDefault();

        setSaving(true);


        const data = new FormData(e.target);

        const response = await postRequest(apiUrl(`tickets/${ticket.numero}`), data);



        if (response.success) {
            toggle();
            toast("Ticket Actualizado correctamente.");
        } else {
            toast("No se ha podido actualizar el ticket.", "error");
        }

        setSaving(false);
    }

    const [cambiarDigitacion, setCambiarDigitacion] = useState(false);
    const [pesos, setPesos] = useState({
        pesoBruto: ticket.pesoBruto,
        pesoTara: ticket.pesoTara,
        pesoNeto: ticket.pesoNeto,
    });

    function onPesoChange(e, nombre) {
        const input = cambiarDigitacion ? "pesoTara" : "pesoNeto";
        const inputInv = !cambiarDigitacion ? "pesoTara" : "pesoNeto";

        if (nombre === "pesoBruto") {
            setPesos({
                ...pesos,
                [input]: e.target.value - pesos[inputInv],
                [nombre]: e.target.value,
            });
            return;
        }

        setPesos({
            ...pesos,
            [input]: pesos.pesoBruto - e.target.value,
            [nombre]: e.target.value,
        });
    }

    const { pesoBruto, pesoTara, pesoNeto } = pesos;

    return (
        <form onSubmit={updateTicket} className='edit-ticket-form'>
            <input type='hidden' name='_method' value='patch' />
            <div className='popup__header'>Ticket {ticket.numero}</div>
            <div className='popup__body'>
                <table className='table'>
                    <tbody>
                        <tr>
                            <td>Programación:</td>
                            <td>
                                <Link
                                    to={getLink(
                                        `/programaciones/${ticket.programacionLink}`
                                    )}>
                                    {ticket.programacion}
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>Ficha:</td>
                            <td>{ticket.ficha}</td>
                        </tr>
                        <tr>
                            <td>Categoría:</td>
                            <td>{ticket.categoria}</td>
                        </tr>
                        <tr>
                            <td>Zona:</td>
                            <td>{ticket.ruta}</td>
                        </tr>
                        <tr>
                            <td>Número:</td>
                            <td>{ticket.numero}</td>
                        </tr>
                        <tr>
                            <td>Hora:</td>
                            <td>
                                <input
                                    type='text'
                                    name='hora'
                                    defaultValue={ticket.hora}
                                    pattern='(2[0-3]|[0-1]?[0-9]):([0-5][0-9])'
                                    className='form-control'
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2'>
                                <label
                                    style={{
                                        paddingTop: "15px",
                                    }}>
                                    <input
                                        type='checkbox'
                                        onChange={(e) =>
                                            setCambiarDigitacion(
                                                e.target.checked
                                            )
                                        }
                                        checked={cambiarDigitacion}
                                        style={{
                                            marginRight: "7px",
                                            verticalAlign: "-2px",
                                        }}
                                    />
                                    Cambiar Digitación
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>Peso Bruto:</td>
                            <td>
                                <input
                                    type='number'
                                    name='peso_bruto'
                                    onChange={(e) =>
                                        onPesoChange(e, "pesoBruto")
                                    }
                                    defaultValue={pesoBruto}
                                    step='0.01'
                                    className='form-control'
                                />
                            </td>
                        </tr>
                        {!cambiarDigitacion ? (
                            <tr>
                                <td>Peso Tara</td>
                                <td>
                                    <input
                                        type='number'
                                        onChange={(e) =>
                                            onPesoChange(e, "pesoTara")
                                        }
                                        value={pesoTara}
                                        name='peso_tara'
                                        step='0.01'
                                        min='0'
                                        max={pesoBruto}
                                        className='form-control'
                                    />
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td>Peso Neto</td>
                                <td>
                                    <input
                                        type='number'
                                        onChange={(e) =>
                                            onPesoChange(e, "pesoNeto")
                                        }
                                        value={pesoNeto}
                                        name='peso_neto'
                                        step='0.01'
                                        min='0'
                                        className='form-control'
                                    />
                                </td>
                            </tr>
                        )}
                        {!cambiarDigitacion ? (
                            <tr>
                                <td>Peso Neto</td>
                                <td>
                                    <input
                                        type='hidden'
                                        name='peso_neto'
                                        value={pesoNeto}
                                        className='form-control'
                                    />
                                    <div>{pesoNeto}</div>
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td>Peso Tara</td>
                                <td>
                                    <input
                                        type='hidden'
                                        name='peso_tara'
                                        value={pesoTara}
                                        className='form-control'
                                    />
                                    <div>{pesoTara}</div>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan='2'>
                                <label
                                    style={{
                                        paddingTop: "15px",
                                    }}>
                                    <input
                                        type='checkbox'
                                        checked={ticket.soloChofer}
                                        style={{
                                            marginRight: "7px",
                                            verticalAlign: "-2px",
                                        }}
                                    />
                                    Solo al Chofer
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>Nota:</td>
                            <td>
                                <textarea
                                    name='nota'
                                    className='form-control'
                                >{ticket.nota}</textarea>
                            </td>
                        </tr>

                        <tr>
                            <td style={{ paddingTop: 25 }}>Operadores:</td>
                            <td></td>
                        </tr>
                        {ticket.operadores &&
                            ticket.operadores.map((op) => (
                                <tr key={op.asignadoId}>
                                    <td>{op.nombre}</td>
                                    <td>
                                        <Link
                                            to={getLink(
                                                `/empleados/${op.asignadoId}/informacion`
                                            )}>
                                            {op.asignadoNombre}
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <div className='popup__footer'>
                <Link
                    to={getLink("/imprimir/ticket/" + ticket.numero)}
                    target='_blank'
                    className='btn float-left'>
                    <i className='zmdi zmdi-print'></i> Imprimir
                </Link>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                {saving ? (
                    <Loader type='small' />
                ) : (
                    <button type='submit' className='btn btn-primary'>
                        Guardar
                    </button>
                )}
            </div>
        </form>
    );
}
