import React, { Component } from "react";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import { toast } from "../../utils/helpers";
import Loader from "../general/Loader";
import { postRequest } from "../../utils/request";

class Edit extends Component {
	state = {
		errors: {},
        isLoading: false,
	};
	deleteContrato = async (contacto) => {
		const url = `contratos/${contacto.id}`;
		const response = await postRequest(apiUrl(url), {}, 'delete');

		if (response.success) {
			this.props.toggle();
			this.props.getContactos();
			toast("Contrato borrado.");
			return;
		} else {
			toast(response.data, "error");
		}
	};
	editContrato = async (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		const data = new FormData(event.target);

		// Validate documentos
		const documentos = { validos: true };
		data.forEach((value, key) => {
			if(key.startsWith("documentos")){
				const parts = key.split('[');

				if(parts.length >= 3) {
					if (!Object.hasOwnProperty.call(documentos, parts[1])) {
						documentos[parts[1]] = {};
					}

					if (parts[2] === "archivo]")
						documentos[parts[1]].archivo = value.size;
					else
						documentos[parts[1]].name = value;
				}
			}
		});

		for (const index in documentos) {
			if (Object.hasOwnProperty.call(documentos, index)) {
				const documento = documentos[index];

				if(documento.archivo && !documento.name) {
					toast("Debe ingresar un nombre valido a cada archivo", "error");
					documentos.validos = false;
					break;
				}
			}
		}

		if (documentos.validos) {
			const response = await postRequest(apiUrl(`contratos/${this.props.contrato.id}`), data, 'post');

			if (response.success) {
				if (this.props.fromFacturacion) {
					this.props.toggle(null, response.data);
					return;
				}
				this.props.getContactos();
				this.props.toggle();
				toast("Contrato actualizado exitosamente.");
			}

			if (response.code === 422) {
				this.setState({ errors: response.data.message });
				toast("No se pudo actualizar el contrato.", "error");
			}
		}

		this.setState({ isLoading: false });
	};

	render() {
		const { errors, isLoading } = this.state;
		const { toggle, contrato } = this.props;
		return (
			<form method="post" onSubmit={this.editContrato}>
				<input type="hidden" name="_method" value="patch" />
				<div className="popup__header">
					<div className="row">
						<div className="col col-md-12">
							Modificar Contrato
						</div>
					</div>
				</div>
				<div className="popup__body">
					<Form contrato={contrato} errors={errors} />
				</div>
				<div className="popup__footer">
					<button
						type="button"
						className="btn float-left"
						onClick={() => this.deleteContrato(contrato)}
					>
						<i className="fas fa-trash-alt"></i>
					</button>
					<button
						type="button"
						onClick={toggle}
						className="btn btn-plain"
					>
						Cancelar
					</button>
					{isLoading ? (
						<Loader type="small" />
					) : (
						<button type="submit" className="btn btn-primary">
							Guardar
						</button>
					)}

				</div>
			</form>
		);
	}
}

export default Edit;
