import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";

import { downloadFile, postRequest, withRequest } from "../../utils/request";
import Loader from "../general/Loader";
import { formatCurrency, getLink, getSucursal, toast } from "../../utils/helpers";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import Modal from "../general/Modal";
import PrintForm from "./PrintForm";

class View extends Component {
    state = {
        nomina: { pagos: [] },
        nominas: [],
        cuenta: '',
        cuentas: [],
        loading: true,
        showImprimir: false,
        showCerrarNomina: false,
        redondear: false,
        showTXTBR: false,
        cuentaTXTBR: {},
        showFrecuencia: false,
        frecuenciaSeleccionada: "",
    };
    componentDidMount() {
        this.getNomina();
        this.getCuentas();
    }
    getNomina = async (url = null) => {
        const id = this.props.match.params.id;
        url = url ? url : "nominas/" + id;
        const nomina = await this.props.request(url);

        if (nomina.success) {
            this.setState({
                nomina: nomina.data[0],
                loading: false,
                cuentaTXTBR: nomina.data[0]?.gasto ? { value: nomina.data[0].gasto.cuentaId, label: nomina.data[0].gasto.cuenta } : {},
            });
        }
    };
    getCuentas = async (url = null) => {
        url = url ? url : "cuentas";
        const cuentas = await this.props.request(url);

        if (cuentas.data?.length > 0) {
            this.setState({
                cuentas: cuentas.data,
            });
        }
    };

    getNominas = async (sucursal) => {
        const nominas = await this.props.request(
            `nominas?sucursal=${sucursal}`
        );

        if (nominas.data && nominas.data.length) {
            return nominas.data;
        }

        return [];
    };

    toggleDropdown = (show = 0) => {
        this.setState({
            showDropdown: show === 0 ? !this.state.showDropdown : show,
        });
    };
    removeNomina = async (event) => {
        event.preventDefault();

        const id = this.props.match.params.id;

        const url = `nominas/${id}`;
        const response = await postRequest(apiUrl(url), {}, 'delete');

        if (response.success) {
            this.props.history.push(getLink("/nomina"));
            return;
        } else {
            this.setState({ errors: response.data.message });
        }
    };
    cerrarNomina = async () => {
        const id = this.props.match.params.id;
        const {cuenta, redondear} = this.state;

        if (!cuenta) {
            alert('Por favor selecciona una cuenta.')
            return;
        }

        const formData = new FormData();
        formData.append('cuenta', cuenta);
        formData.append('redondear', redondear);

        const url = `nominas/${id}/cerrar`;

        const response = await postRequest(apiUrl(url), formData);

        if (response.success) {
            this.props.history.push(getLink("/nomina"));
            return;
        } else {
            this.setState({ errors: response.data.message });
        }
    };
    abrirNomina = async () => {
        const id = this.props.match.params.id;

        const url = `nominas/${id}/abrir`;

        const response = await postRequest(apiUrl(url), {});

        if (response.success) {
            this.props.history.push(getLink("/nomina"));
            return;
        } else {
            this.setState({ errors: response.data.message });
        }
    };
    exportCSV = () => {
        let rows = [['NOMBRE', 'CEDULA', 'SUELDO']]
        rows = [
            ...rows,
            ...this.state.nomina.pagos.map(pago => {
                return [
                    pago.nombre,
                    pago.cedula,
                    pago.total,
                ];
            })
        ];

        let csvContent = "data:text/csv;charset=utf-8,"
        + rows.map(e => e.join(",")).join("\n");

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");

        link.setAttribute("href", encodedUri);
        link.setAttribute("download", this.state.nomina.nombre + ".csv");
        document.body.appendChild(link); //Firefox Fix
        link.click();
    }
    toggleImprimir = () => {
        this.setState({
            showImprimir: !this.state.showImprimir,
        });
    };
    toggleCerrarNomina = () => {
        this.setState({
            showCerrarNomina: !this.state.showCerrarNomina,
        });
    };
    onChangeCuenta = (e) => {
        this.setState({
            cuenta: e.value
        });
    };
    onChangeRedondear = (e) => {
        this.setState({
            redondear: e.target.checked ? e.target.value : '',
        });
    };
    toggleTXTBR = () => {
        this.setState({ showTXTBR: !this.state.showTXTBR});
    };
    generarTXTBR = () => {
        const cuenta = this.state.cuentas?.find((c) => c.id === this.state.cuentaTXTBR.value);

        if (cuenta) {
            const rows = this.state.nomina.pagos.map(pago => {
                return [
                    "CC",
                    cuenta.moneda,
                    cuenta.numeroCuenta,
                    "CC",
                    "DOP",
                    pago.cuenta,
                    pago.total,
                    pago.nombre.replaceAll('¥', 'N').replaceAll('Ñ', 'N'),
                ];
            });

            const csvContent = `data:text/plain;charset=utf-8,${rows.map(e => e.join(",")).join("\n")}`;

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");

            link.setAttribute("href", encodedUri);
            link.setAttribute("download", this.state.nomina.nombre + ".txt");
            document.body.appendChild(link); //Firefox Fix
            link.click();

            this.toggleTXTBR();
        } else {
            toast("Selecciones una cuenta valida", 'error')
        }
    };
    generarTXTBHD = () => {
        const rows = this.state.nomina.pagos.map((pago, index) => {
            return [
                pago.cuenta,
                pago.nombre.replaceAll('¥', 'N').replaceAll('Ñ', 'N'),
                index + 1,
                pago.total,
                this.state.nomina.nombre
            ];
        });

        const csvContent = `data:text/plain;charset=utf-8,${rows.map(e => e.join(";")).join("\n")}`;

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");

        link.setAttribute("href", encodedUri);
        link.setAttribute("download", this.state.nomina.nombre + ".txt");
        document.body.appendChild(link); //Firefox Fix
        link.click();
    };
    toggleFrecuencia = () => {
        this.setState({ showFrecuencia: !this.state.showFrecuencia});
    };
    downloadDGIITSS = async () => {
        if (this.state.frecuenciaSeleccionada) {
            const id = this.props.match.params.id;

            await downloadFile(`nominas/${id}/DGII-TSS?periodo=${this.state.frecuenciaSeleccionada}`, `DGII_TSS.xlsx`);

            this.toggleFrecuencia();
        } else {
            toast("Selecciones un periodo valido", 'error')
        }
    };
    render() {
        const { nomina, cuentas, loading } = this.state;
        const produccion = nomina.tipo === "PROD";
        const comisiones = nomina.tipo === "COMISIONES" || nomina.tipo === "NAVIDAD";
        const navidad = nomina.tipo === "NAVIDAD";

        return (
            <div>
                {loading && <Loader />}
                {!loading && (
                    <div className='page-header'>
                        <h1 className='page-title row'>
                            <span className='col'>
                                {nomina.nombre}{" "}
                                {nomina.cerrada && (
                                    <small className='badge badge-success'>
                                        <i className='zmdi zmdi-lock'></i>{" "}
                                        CERRADA
                                    </small>
                                )}
                            </span>
                            <button
                                    onClick={this.exportCSV}
                                className='au-btn au-btn-icon au-btn-filter m-r-10'>
                                <i className='zmdi zmdi-download'></i> Descargar
                            </button>
                            <div className='dropdown m-r-10'>
                                <button className='au-btn au-btn-icon au-btn-filter'>
                                    <i className='zmdi zmdi-print'></i> Imprimir
                                </button>
                                <div
                                    style={{ right: "0", left: "auto" }}
                                    className='dropdown-menu'>
                                    {nomina.tipo === 'COMISIONES' && (
                                        <Link
                                            to={getLink(
                                                `/imprimir/nomina/${nomina.id}/comisiones`
                                            )}
                                            target='_blank'
                                            className='dropdown-item'>
                                            Detalle Comisiones
                                        </Link>
                                    )}
                                    <Link
                                        to={getLink(
                                            `/imprimir/nomina/${nomina.id}/resumen`
                                        )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        Resumen de Nómina
                                    </Link>
                                    {nomina.tipo !== 'COMISIONES' && (<>

                                    <Link
                                        to={getLink(
                                            `/imprimir/nomina/${nomina.id}/resumen-columnas`
                                        )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        Resumen + Columnas
                                    </Link>
                                    <Link
                                        to={getLink(
                                            `/imprimir/nomina/${nomina.id}/resumen-columnas-categorizado`
                                        )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        Resumen + Columnas Categorizado
                                    </Link>
                                    <Link
                                        to={getLink(
                                            `/imprimir/nomina/${nomina.id}/comprobantes-normales`
                                        )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        Volantes Normales
                                    </Link>
                                    <Link
                                        to={getLink(
                                            `/imprimir/nomina/${nomina.id}/comprobantes-detalles`
                                        )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        Volantes Detalles
                                    </Link>
                                    <Link
                                        to={getLink(
                                            `/imprimir/nomina/${nomina.id}/comprobantes-normales?tipo=termicos`
                                        )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        Volantes Térmicos
                                    </Link>
                                    <Link
                                        to={getLink(
                                            `/imprimir/nomina/${nomina.id}/sobres`
                                        )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        Sobres
                                    </Link>
                                    <Link
                                        to={getLink(
                                            `/imprimir/nomina/${nomina.id}/sobres-detalles`
                                        )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        Sobres Detalles
                                    </Link>
                                    {nomina.tipo !== 'FIJOS' && (
                                        <Link
                                            to={getLink(
                                                `/imprimir/nomina/${nomina.id}/comprobantes`
                                            )}
                                            target='_blank'
                                            className='dropdown-item'>
                                            Volantes Producción
                                        </Link>
                                    )}
                                    <button
                                        onClick={this.toggleTXTBR}
                                        target="_blank"
                                        className="dropdown-item"
                                    >
                                        Generar txt BR
                                    </button>
                                    <button
                                        onClick={this.generarTXTBHD}
                                        target="_blank"
                                        className="dropdown-item"
                                    >
                                        Generar txt BHD
                                    </button>
                                    <button
                                        onClick={this.toggleFrecuencia}
                                        target="_blank"
                                        className="dropdown-item"
                                    >
                                        Informe DGII-TSS
                                    </button>
                                    <div className='dropdown-divider'></div>
                                    <Link
                                        to={getLink(
                                            `/imprimir/nomina/${nomina.id}/desglose-efectivo`
                                        )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        Desglose Efectivo
                                    </Link>
                                </>)}
                                </div>
                            </div>
                            {!nomina.cerrada && (
                                <button
                                    onClick={this.toggleCerrarNomina}
                                    className='au-btn au-btn-icon au-btn--green au-btn--small'>
                                    <i className='zmdi zmdi-lock'></i> Cerrar
                                </button>
                            )}
                            {nomina.cerrada && auth.is('super-admin') && (
                                <button
                                    onClick={this.abrirNomina}
                                    className='au-btn au-btn-icon au-btn--green au-btn--small'>
                                    <i className='zmdi zmdi-lock-open'></i> Abrir
                                </button>
                            )}
                        </h1>
                    </div>
                )}
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {!nomina.pagos.length ? (
                                <div>
                                    {!loading && (
                                        <div>
                                            No pagos disponibles para esta
                                            nómina.
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <div
                                        className='row'
                                        style={{
                                            borderBottom: "1px solid #e2e2e2",
                                            marginBottom: "10px",
                                            paddingBottom: "5px",
                                        }}>
                                        <div className='col'>
                                            EMPLEADOS: {nomina.empleados}
                                        </div>
                                        <div className='col text-right'>
                                            TOTAL:{" "}
                                            {formatCurrency(nomina.total)}
                                        </div>
                                        {navidad && (
                                            <div className='col text-right'>
                                                TOTAL REDONDEADO:{" "}
                                                {formatCurrency(nomina.totalRedondeado)}
                                            </div>
                                        )}
                                    </div>

                                    {nomina.gasto && (<div className="m-b-20">Debitado de: {nomina.gasto.cuenta} - Fecha: {nomina.gasto.fecha}</div>)}

                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Código</th>
                                                <th>Nombre</th>
                                                <th>Cargo</th>
                                                {produccion && (
                                                    <>
                                                        <th className='text-right'>
                                                            Toneladas
                                                        </th>
                                                        <th className='text-right'>
                                                            Sueldo Fijo
                                                        </th>
                                                    </>
                                                )}
                                                {!comisiones && (
                                                    <>
                                                        <th className='text-right'>
                                                            Sueldo Bruto
                                                        </th>
                                                        <th className='text-right'>
                                                            Descuentos
                                                        </th>
                                                        <th className='text-right'>
                                                            Incentivos
                                                        </th>
                                                        <th className='text-right'>
                                                            Total
                                                        </th>
                                                    </>
                                                )}
                                                {comisiones && !navidad && (
                                                    <>
                                                        <th className='text-right'>
                                                            Total
                                                        </th>
                                                    </>
                                                )}
                                                {navidad && (
                                                    <>
                                                    <th className='text-right'>
                                                        Regalía
                                                    </th>
                                                    <th className='text-right'>
                                                        Total
                                                    </th>
                                                    </>
                                                )}
                                                {comisiones && !navidad && <td/>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {nomina.pagos.map((pago) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={pago.id}>
                                                    <td>{pago.codigo}</td>
                                                    <td>{pago.nombre}</td>
                                                    <td>{pago.cargo}</td>
                                                    {produccion && (
                                                        <>
                                                            <td className='text-right'>
                                                                {pago.toneladas}
                                                            </td>
                                                            <td className='text-right'>
                                                                {formatCurrency(
                                                                    pago.sueldoFijo
                                                                )}
                                                            </td>
                                                        </>
                                                    )}
                                                    {!comisiones && (
                                                        <>
                                                            <td className='text-right'>
                                                                {formatCurrency(
                                                                    pago.sueldoBruto
                                                                )}
                                                            </td>
                                                            <td className='text-right'>
                                                                {formatCurrency(
                                                                    pago.descuentosTotal
                                                                )}
                                                            </td>
                                                            <td className='text-right'>
                                                                {formatCurrency(
                                                                    pago.incentivos
                                                                )}
                                                            </td>
                                                            <td className='text-right'>
                                                                {formatCurrency(
                                                                    pago.total
                                                                )}
                                                            </td>
                                                        </>
                                                    )}
                                                    {comisiones && !navidad && (
                                                        <>
                                                            <td className='text-right'>
                                                                {formatCurrency(
                                                                    pago.total
                                                                )}
                                                            </td>
                                                        </>
                                                    )}
                                                    {navidad && (
                                                        <>
                                                            <td className='text-right'>
                                                                {formatCurrency(
                                                                    pago.total
                                                                )}
                                                            </td>
                                                            <td className='text-right'>
                                                                {formatCurrency(
                                                                    pago.totalRedondeado
                                                                )}
                                                            </td>
                                                        </>
                                                    )}
                                                    {comisiones && !navidad && (
                                                        <td className="text-right">
                                                            <div className="table-data-feature">
                                                                <Link className="item" to={getLink(`/imprimir/nomina/${nomina.id}/comisiones/${pago.id}`)} target="_blank">
                                                                    <i className="zmdi zmdi-print"></i>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                        {!nomina.cerrada && (
                            <div className='delete-footer'>
                                <form onSubmit={this.removeNomina}>
                                    <button
                                        type='submit'
                                        className='btn'>
                                        <i className='fas fa-trash'></i>
                                    </button>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
                <Modal
                    blank
                    toggle={this.toggleImprimir}
                    show={this.state.showImprimir}>
                    <PrintForm
                        toggle={this.toggleImprimir}
                        getNominas={this.getNominas}
                    />
                </Modal>
                <Modal
                    toggle={this.toggleCerrarNomina}
                    title='Cerrar Nómina'
                    acceptText='Cerrar'
                    callback={this.cerrarNomina}
                    show={this.state.showCerrarNomina}>
                    <div className="m-b-20">
                        Luego de cerrada la nómina no puede ser borrada. ¿Estás
                        seguro que deseas cerrarla?
                    </div>
                    <div className="m-b-60">
                        Debitar desde cuenta:
                        <div>
                            <Select
                                onChange={this.onChangeCuenta}
                                options={cuentas.map(c => ({label: c.nombre, value: c.id}))}
                                />
                        </div>
                        <div className="form-check m-t-20">
                            <input type="checkbox" className="form-check-input m-t-5" name="redondear" id="redondear" value="redondear" onChange={this.onChangeRedondear} /> <label htmlFor="redondear" className="form-check-label">Incluir Redondeo</label>
                        </div>
                    </div>
                </Modal>
                <Modal
                    toggle={this.toggleTXTBR}
                    title='Generar TXT BR'
                    acceptText='Generar'
                    callback={this.generarTXTBR}
                    show={this.state.showTXTBR}>
                    <div className="m-b-60">
                        Cuenta:
                        <div>
                            <Select
                                value={this.state.cuentaTXTBR}
                                onChange={(e) => this.setState({ cuentaTXTBR: e })}
                                options={cuentas.map(c => ({label: c.nombre, value: c.id}))}
                                />
                        </div>
                    </div>
                </Modal>

                {/* DGII-TSS frecuencia */}
                <Modal
                    toggle={this.toggleFrecuencia}
                    title='Frecuencia Del Reporte'
                    acceptText='Generar'
                    callback={this.downloadDGIITSS}
                    show={this.state.showFrecuencia}>
                    <div className="m-b-40">
                        Periodo:
                        <div>
                            <select
                                name='periodo'
                                className="form-control"
                                required
                                onChange={(e) => this.setState({ frecuenciaSeleccionada: e.target.value })}
                                value={this.state.frecuenciaSeleccionada}
                            >
                                <option value="">Seleccionar...</option>
                                <option value="semanal">Semanal</option>
                                <option value="quincenal">Quincenal</option>
                                <option value="mensual">Mensual</option>
                            </select>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withRouter(withRequest(View));
