import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import { postRequest } from "../../../utils/request";

class Nomina extends Component {
    state = {
        errors: {},
    };
    editConfig = async (event) => {
        event.preventDefault();

        const data = new FormData(event.target);
        const response = await postRequest(
            apiUrl('configuraciones'),
            data
        );

        if (response.success) {
            this.props.getSucursales();
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { errors } = this.state;
        return (
            <form method='post' onSubmit={this.editConfig}>
                <input type='hidden' name='_method' value='patch' />
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Periodo de Nómina:
                        </label>
                    </div>
                    <div className='col col-md-9'>
                        <input type="hidden" name="configuraciones[nomina][nombre]" value="periodo" />
                        <input type="hidden" name="configuraciones[nomina][seccion]" value="sucursal_nomina" />
                        <select
                            type='text'
                            className='text-input form-control'
                            id='sistema'
                            name='configuraciones[nomina][valor]'>
                            <option value='mensual'>Mensual</option>
                            <option value='quincenal'>Quincenal</option>
                            <option value='semanal'>Semanal</option>
                        </select>
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='popup__footer'>
                    <button type='submit' className='btn btn-primary'>
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

export default Nomina;
