import React, { useRef, useState } from "react";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import Form from "./Form";
import { getLink, toast } from "../../../utils/helpers";
import { postRequest } from "../../../utils/request";

const CreateAjuste = ({ producto, toggle, getAjustes, getProducto }) => {
	const [state, setState] = useState({
		saving: false,
		errors: [],
	});
	const [createButtonClicked, setCreateButtonClicked] = useState("normal");

	const formRef = useRef();

	const createAjuste = async (event) => {
		event.preventDefault();

		setState(prevState => ({
			...prevState,
			saving: true,
		}));

		const data = new FormData(event.target);
		const response = await postRequest(apiUrl(`ajustes`), data);

		if (response.success) {
			if(createButtonClicked === 'imprimir') {
				window.open(
					getLink(`/imprimir/ajustes-inventario/${response.data.id}?productoNombre=${
						producto.nombre
					}`)
				);
			}
			if (getAjustes) {
				getAjustes();
			}
			document.getElementById("ajustesform").reset();

			toggle();

			getProducto();

			toast("Ajuste creada exitosamente.");
		}
		if (response.code === 422) {
			setState(prevState => ({...prevState, errors: response.data.message }));
		}

		setState(prevState => ({
			...prevState,
			saving: false,
		}));
	};

	const createAjusteEImprimir = async () => {
		await setCreateButtonClicked("imprimir");
		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	return (
		<form
			id="ajustesform"
			method="post"
			onSubmit={createAjuste}
			ref={formRef}
		>
			<input type="hidden" name="_method" value="post" />
			<div className="popup__header">Ajustar Producto</div>
			<Form producto={producto} errors={state.errors} />
			<div className="popup__footer">
				<button
					type="button"
					onClick={toggle}
					className="btn btn-plain"
				>
					Cancelar
				</button>
				{state.saving ? (
					<Loader type="small" />
				) : (
					<>
						<button
							type="button"
							onClick={() => createAjusteEImprimir()}
							className="btn btn-outline-primary mr-2"
						>
							Guardar e imprimir
						</button>
						<button type="submit" className="btn btn-primary">
							Ajustar
						</button>
					</>
				)}
			</div>
		</form>
	);
};

export default CreateAjuste;

// class CreateAjuste extends Component {
// 	state = {
// 		saving: false,
// 		errors: [],
// 	};
// 	createAjuste = async (event) => {
// 		event.preventDefault();

// 		this.setState({
// 			saving: true,
// 		});

//
// 		const data = new FormData(event.target);

// 		const url = `ajustes`;

// 		const response = await postRequest(apiUrl(url), {
// 			method: "post",
// 			body: data,
// 			headers: {
// 				Accept: "application/json",
// 				"X-Request-With": "XMLHttpRequest",
// 				Authorization: "Bearer " + token,
// 			},
// 		});

//

// 		if (response.success) {
// 			if (this.props.getAjustes) {
// 				this.props.getAjustes();
// 			}
// 			document.getElementById("ajustesform").reset();
// 			this.props.toggle();

// 			this.props.getProducto();

// 			toast("Ajuste creada exitosamente.");
// 		}
// 		if (response.code === 422) {
// 			this.setState({ errors: response.data.message });
// 		}

// 		this.setState({
// 			saving: false,
// 		});
// 	};
// 	getTable = async (url = null, params = "") => {
// 		const table = await this.props.request(url + params);

// 		if (table.data && table.data.length > 0) {
// 			this.setState({
// 				[url]: table.data,
// 			});
// 		}
// 	};
// 	render() {
// 		const { producto } = this.props;
// 		const { errors } = this.state;
// 		return (
// 			<form id="ajustesform" method="post" onSubmit={this.createAjuste}>
// 				<input type="hidden" name="_method" value="post" />
// 				<div className="popup__header">Ajustar Producto</div>
// 				<Form producto={producto} errors={errors} />
// 				<div className="popup__footer">
// 					<button
// 						type="button"
// 						onClick={this.props.toggle}
// 						className="btn btn-plain"
// 					>
// 						Cancelar
// 					</button>
// 					{this.state.saving ? (
// 						<Loader type="small" />
// 					) : (
// 						<>
// 							<button
// 								type="button"
// 								onClick={() => this.createAjusteEImprimir()}
// 								className="btn btn-outline-primary mr-2"
// 							>
// 								Guardar e imprimir
// 							</button>
// 							<button type="submit" className="btn btn-primary">
// 								Ajustar
// 							</button>
// 						</>
// 					)}
// 				</div>
// 			</form>
// 		);
// 	}
// }
