import React, { Component } from "react";
import { postRequest, withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import { formatCurrency, formatNumber, getLink } from "../../../utils/helpers";
import { Link } from "react-router-dom";
import Pagination from "../../general/Pagination";
import { debounce } from "lodash";

class Compras extends Component {
    state = {
        compras: [],
        showCreate: false,
        loading: true,
        meta: [],
        links: [],
        filtrosQuery: "",
    };
    componentDidMount() {
        this.setState({
            filtrosQuery: `?producto=${this.props.producto.id}`,
        });
        this.getCompras();
    }
    getCompras = async (url = null) => {
        const id = this.props.producto.id;
        url = url ? url : `compras?producto=${id}&limit=20`;
        const data = await this.props.request(url);

        console.log(data);

        if (data) {
            this.setState({
                compras: data.data,
                meta: data.meta,
                links: data.links,
                loading: false,
            });
        }

        this.setState({ loading: false });
    };
    removeAjuste = async (event, producto) => {
        event.preventDefault();



        const url = `productos/${producto}`;

        const response = await postRequest(apiUrl(url), {}, 'delete');



        if (response.success) {
            this.getCompras();
            this.props.getproducto();
            return;
        } else {
            this.setState({ errors: response.data.message });
        }

        this.setState({ loading: false });
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
        });
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: `?producto=${this.props.producto.id}&` + query.join("&"),
        });

        this.getCompras(
            `compras?producto=${this.props.producto.id}&` + query.join("&")
        );
    }, 500);
    render() {
        const { producto } = this.props;
        console.log({producto});
        const { loading, compras, meta, links, filtrosQuery } = this.state;

        if (loading === true) {
            return <Loader />;
        }
        return (
            <div>
                <h3>Historial de Compras de {producto.nombre}</h3>
                <div className='page-controls'>
                    <div className='table-data__tool'>
                        <div className='table-data__tool-left row'>
                            <div className='col-md-4'>
                                <label htmlFor='desde'>Desde</label>
                                <input
                                    type='date'
                                    name='desde'
                                    id='desde'
                                    className='form-control'
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "desde"
                                        )
                                    }
                                />
                            </div>
                            <div className='col-md-4'>
                                <label htmlFor='hasta'>Hasta</label>
                                <input
                                    type='date'
                                    name='hasta'
                                    id='hasta'
                                    className='form-control'
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "hasta"
                                        )
                                    }
                                />
                            </div>
                            <div className='col-md-4 text-right p-t-25'>
                                <Link
                                    to={getLink(
                                        `/imprimir/compras-producto${filtrosQuery}`
                                    )}
                                    target='_blank'
                                    className='au-btn-filter m-r-15'>
                                    <i className='zmdi zmdi-print' /> Imprimir
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {compras && compras.length > 0 ? (
                    <table className='table tickets-table'>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Suplidor</th>
                                <th className='text-right'>Cantidad</th>
                                <th className='text-right'>Costo/UND</th>
                                <th className='text-right'>Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {compras.map((compra) => (
                                <tr key={compra.id}>
                                    <td>{compra.fecha}</td>
                                    <td>{compra.suplidor}</td>
                                    <td className='text-right'>
                                        {formatNumber(compra.cantidad)}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(compra.costo)}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(
                                            compra.costo * compra.cantidad
                                        )}
                                    </td>
                                    <td className='text-right'>
                                        <Link
                                            to={getLink(
                                                `/compras/${compra.compraId}`
                                            )}>
                                            Ver Compra
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div>No se han realidado compras de este producto.</div>
                )}
                {meta && (
                    <Pagination
                        links={links}
                        meta={meta}
                        getData={this.getCompras}
                    />
                )}
            </div>
        );
    }
}

export default withRequest(Compras);
