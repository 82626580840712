import React, { Component } from "react";
import { debounce } from "lodash";
import { Link, Route, withRouter } from "react-router-dom";
import Select from "react-select";

import { postRequest, withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Abonar from "./Abonar";
import Create from "./Create";
import Edit from "./Edit";
import EditCuota from "./EditCuota";
import BulkEditCuotas from "./BulkEditCuotas";
import Loader from "../general/Loader";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { getLink, toast } from "../../utils/helpers";
import View from "./View";
import DescuentoItem from "./DescuentoItem";
import dayjs from "dayjs";

class DescuentosProgramados extends Component {
    state = {
        descuentos: [],
        descuento: {},
        columnas: [],
        links: null,
        meta: null,
        showCreate: false,
        showEdit: false,
        showBulkEdit: false,
        showCompletar: true,
        showProgramacion: true,
        estado: "completada",
        loading: true,
        filtros: {
            buscar: "",
            estado: "todo",
            desde: dayjs().startOf('month').format('YYYY-MM-DD'),
            hasta: dayjs().endOf('month').format('YYYY-MM-DD'),
        },
        filtrosQuery: `?limit=20&desde=${dayjs().startOf('month').format('YYYY-MM-DD')}&hasta=${dayjs().endOf('month').format('YYYY-MM-DD')}&estado=todo`,
        empleados: [],
        grupos: [],
    };
    componentDidMount() {
        this.getDescuentosProgramados();
        this.getGruposTipos();
        this.getColumnas();
        this.getTable("empleados", '?estado=activos');
    }
    getTable = async (table, params = '') => {
        const response = await this.props.request(table + params);

        if (response.data && response.data.length) {
            this.setState({
                [table]: response.data,
            });
        }
    };
    getDescuentosProgramados = async (url = null) => {
        url = url
            ? url + "&limit=20"
            : "descuentos" + this.state.filtrosQuery;
        const descuentos = await this.props.request(url);
        if (descuentos.data) {
            this.setState({
                descuentos: descuentos.data,
                links: descuentos.links,
                meta: descuentos.meta,
                loading: false,
            });
        }
    };
    getGruposTipos = async (url = null) => {
        url = url ? url + "&limit=15" : "grupos-tipos-empleados";
        const grupos = await this.props.request(url);

        if (grupos.data) {
            this.setState({
                grupos: grupos.data,
            });
        }
    };
    getColumnas = async (url = null) => {
        url = url ? url : "taxonomias?relacion=nomina&tipo=columnas";
        const columnas = await this.props.request(url);

        if (columnas.data) {
            this.setState({
                columnas: columnas.data,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            descuento: { operadores: [] },
        });
    };
    toggleEditModal = (descuento) => {
        this.setState({
            showEdit: !this.state.showEdit,
            descuento: descuento,
        });
        this.props.history.push(getLink("/descuentos"));
    };
    toggleBulkEditModal = (descuento) => {
        this.setState({
            showBulkEdit: !this.state.showEdit,
            descuento: descuento,
        });
        this.props.history.push(getLink("/descuentos"));
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getDescuentosProgramados("descuentos?limit=20&" + query.join("&"));
    }, 500);

    updateDescuento = async (descuento, fields) => {
        let data = new FormData();
        data.append("_method", "PATCH");
        Object.keys(fields).map((field) => data.append(field, fields[field]));
        const response = await postRequest(apiUrl(`descuentos/${descuento}`), data);

        if (response.success) {
            this.getDescuentosProgramados();
            toast("¡Reclamación actualizada!");
        }
    };

    render() {
        const {
            descuento,
            descuentos,
            empleados,
            grupos,
            columnas,
            loading,
            filtrosQuery,
        } = this.state;
        return (
            <div className='container'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Descuentos Programados</span>
                        <div className='col-md-2'>
                            <label htmlFor='desde' style={{fontSize: '12px', textTransform: 'uppercase'}}>Desde</label>
                            <input
                                type='date'
                                name='desde'
                                id='desde'
                                className='form-control'
                                defaultValue={dayjs().startOf('month').format('YYYY-MM-DD')}
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.target.value,
                                        "desde"
                                    )
                                }
                            />
                        </div>
                        <div className='col-md-2'>
                            <label htmlFor='hasta' style={{fontSize: '12px', textTransform: 'uppercase'}}>Hasta</label>
                            <input
                                type='date'
                                name='hasta'
                                id='hasta'
                                className='form-control'
                                defaultValue={dayjs().endOf('month').format('YYYY-MM-DD')}
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.target.value,
                                        "hasta"
                                    )
                                }
                            />
                        </div>
                        <Link
                            to={getLink(
                                `/imprimir/descuentos${filtrosQuery.replace('limit=20&', '')}`
                            )}
                            target='_blank'
                            className='au-btn-filter m-r-15'>
                            <i className='zmdi zmdi-print' /> Imprimir
                        </Link>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Descuento
                        </button>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='buscar'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>

                                <div className='col-md-2'>
                                    <label htmlFor='frecuencia'>Frecuencia</label>
                                    <Select
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "frecuencia"
                                            )
                                        }
                                        name='frecuencia'
                                        defaultValue='pendiente'
                                        options={[
                                            {label: 'Todos', value: 'todo'},
                                            {label: 'Único', value: 'Unico'},
                                            {label: 'Semanal', value: 'semanal'},
                                            {label: 'Quincenal', value: 'quincenal'},
                                            {label: 'Mensual', value: 'mensual'},
                                        ]}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Grupos:</label>
                                    <Select
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "grupo"
                                            )
                                        }
                                        name='grupo'
                                        defaultValue='todo'
                                        options={[
                                            {value: 'todos', label: 'Todos'},
                                            ...grupos.map(grupo => ({
                                                value: grupo.id,
                                                label: grupo.nombre,
                                            }))
                                        ]}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Filtrar Rango Por:</label>
                                    <Select
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "estado"
                                            )
                                        }
                                        name='estado'
                                        defaultValue='todo'
                                        options={[
                                            {label: 'Todos', value: 'todo'},
                                            {label: 'Cuotas Pendientes', value: 'pendiente'},
                                            {label: 'Cuotas Pagadas', value: 'completado'},
                                            {label: 'Descuento Incompleto', value: 'incompleto'},
                                            {label: 'Descuento Saldado', value: 'saldado'},
                                        ]}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Columna:</label>
                                    <Select
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "columna"
                                            )
                                        }
                                        name='columna'
                                        defaultValue='todo'
                                        options={[
                                            {value: '', label: 'Todos'},
                                            ...columnas.map(col => ({
                                                value: col.id,
                                                label: col.nombre,
                                            }))
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!descuentos.length ? (
                                <div>
                                    {!loading && (
                                        <div>No hay descuentos creados.</div>
                                    )}
                                </div>
                            ) : (
                                <div className="tabla-descuentos">
                                    {/* <div className="tabla-descuentos__header descuento-row">
                                        <div>Empleado</div>
                                        <div>Detalle</div>
                                        <div>Monto</div>
                                        <div>Cuota</div>
                                        <div>Descuentos</div>
                                        <div></div>
                                    </div> */}
                                    <div className="tabla-descuentos__body">
                                        {descuentos.map(
                                            (descuento) => <DescuentoItem descuento={descuento} key={descuento.id} />
                                        )}
                                    </div>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getDescuentosProgramados}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Route
                    exact
                    path='/:sucursal/descuentos/cuotas/editar'
                    render={(props) => (
                        <Modal show blank toggle={this.toggleBulkEditModal}>
                            <BulkEditCuotas
                                toggle={this.toggleBulkEditModal}
                                empleados={empleados}
                                descuentoId={parseInt(props.match.params.id )}
                                descuento={
                                    descuentos.filter( (d) => {
                                        if ( d.id === parseInt(props.match.params.id ) ) {
                                            return true;
                                        }

                                        let cuota = false;
                                        d.cuotas.forEach(c => {
                                            if(c.id === parseInt(props.match.params.id )) {
                                                cuota = true;
                                            }
                                        });

                                        return cuota;
                                    })[0] || {}
                                }
                                getDescuentos={this.getDescuentosProgramados}
                            />
                        </Modal>
                    )}
                />
                <Route
                    exact
                    path='/:sucursal/descuentos/cuota/:id'
                    render={(props) => (
                        <Modal show blank toggle={this.toggleEditModal}>
                            <EditCuota
                                toggle={this.toggleEditModal}
                                empleados={empleados}
                                descuentoId={parseInt(props.match.params.id )}
                                descuento={
                                    descuentos.filter( (d) => {
                                        if ( d.id === parseInt(props.match.params.id ) ) {
                                            return true;
                                        }

                                        let cuota = false;
                                        d.cuotas.forEach(c => {
                                            if(c.id === parseInt(props.match.params.id )) {
                                                cuota = true;
                                            }
                                        });

                                        return cuota;
                                    })[0] || {}
                                }
                                getDescuentos={this.getDescuentosProgramados}
                            />
                        </Modal>
                    )}
                />
                <Route
                    exact
                    path='/:sucursal/descuentos/:id'
                    render={(props) => (
                        <Modal show blank toggle={this.toggleEditModal}>
                            <Edit
                                toggle={this.toggleEditModal}
                                empleados={empleados}
                                descuentoId={parseInt(props.match.params.id )}
                                columnas={columnas}
                                descuento={
                                    descuentos.filter( (d) => {
                                        if ( d.id === parseInt(props.match.params.id ) ) {
                                            return true;
                                        }

                                        let cuota = false;
                                        d.cuotas.forEach(c => {
                                            if(c.id === parseInt(props.match.params.id )) {
                                                cuota = true;
                                            }
                                        });

                                        return cuota;
                                    })[0] || {}
                                }
                                getDescuentos={this.getDescuentosProgramados}
                            />
                        </Modal>
                    )}
                />
                <Route
                    exact
                    path='/:sucursal/descuentos/:id/ver'
                    render={(props) => {
                        return (
                            <Modal
                                show
                                title='Ver Descuento Programado'
                                toggle={this.toggleEditModal}>
                                <View
                                    descuento={
                                        descuentos.filter(
                                            (r) =>
                                                r.id ===
                                                parseInt(props.match.params.id)
                                        )[0] || {}
                                    }
                                />
                            </Modal>
                        );
                    }}
                />
                <Route
                    exact
                    path='/:sucursal/descuentos/:id/abonar'
                    render={(props) => {
                        return (
                            <Modal
                                show
                                blank
                                title='Ver Descuento Programado'
                                toggle={this.toggleEditModal}>
                                <Abonar
                                    toggle={this.toggleEditModal}
                                    empleados={empleados}
                                    descuentoId={parseInt(props.match.params.id )}
                                    descuento={
                                        descuentos.filter( (d) => {
                                            if ( d.id === parseInt(props.match.params.id ) ) {
                                                return true;
                                            }

                                            let cuota = false;
                                            d.cuotas.forEach(c => {
                                                if(c.id === parseInt(props.match.params.id )) {
                                                    cuota = true;
                                                }
                                            });

                                            return cuota;
                                        })[0] || {}
                                    }
                                    getDescuentos={this.getDescuentosProgramados}
                                />
                            </Modal>
                        );
                    }}
                />
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        descuento={descuento}
                        empleados={empleados}
                        columnas={columnas}
                        getDescuentosProgramados={this.getDescuentosProgramados}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRouter(withRequest(DescuentosProgramados));
