import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { apiUrl } from "../../utils/apiUrl";
import { postRequest, withRequest } from "../../utils/request";
import { getLink, toast, isPastDate } from "../../utils/helpers";
import Loader from "../general/Loader";
import Modal from "../general/Modal";
import Form from "./Form";
import FileLoader from "../general/FileLoader";

class Edit extends Component {
	state = {
		errors: {},
		compra: {},
		contactos: [],
		terminos: [],
		impuestos: [],
		contacto_id: false,
		items: [],
		grupos: [],
		compraItems: [],
		total: 0,
		saving: false,
		showEliminarModal: false,
		submitBtnClicked: "normal",
		showPagoModal: false,
		prevTotal: 0,
	};
	componentDidMount() {
		this.getData();
		this.formRef = React.createRef();
	}
	getData = () => {
		const compra = this.props.match.params.id;
		this.getCompra(compra);
		this.getTable("contactos?tipo=proveedor", "contactos");
		this.getTable("terminos-pago", "terminos");
		this.getTable("items");
		this.getTable("productos");
		this.getTable("grupos");
		this.getTable("impuestos");
	};
	getCompra = async (id) => {
		const table = await this.props.request("compras/" + id);

		if (table.data) {
			this.setState({
				compra: table.data,
				compraItems: table.data.items,
				contacto_id: table.data.contactoId,
				loading: false,
				prevTotal: table.data?.total,
			});
		}
	};
	getTable = async (url = null, nombre = null) => {
		const table = await this.props.request(url);
		const tableName = nombre ? nombre : url;

		if (table.data) {
			this.setState({
				[tableName]: table.data,
			});
		}
	};

	guardarYSalir = async (btnClick) => {
		await this.setState({ submitBtnClicked: "normal" });
		this.formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};
	guardarYPagar = () => {
		this.setState({ showPagoModal: true });
	};
	guardarEImprimir = async () => {
		await this.setState({ submitBtnClicked: "imprimir" });

		this.formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};
	guardarYNuevaFactura = async () => {
		await this.setState({ submitBtnClicked: "nueva" });

		this.formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};
	editCompra = async (event) => {
		event.preventDefault();
		this.setState({ saving: true });


		const data = new FormData(event.target);

		const response = await postRequest(apiUrl(`compras/${this.state.compra.id}`), data);



		if (response.success) {
			if (this.state.submitBtnClicked === "normal") {
				this.props.history.push(getLink("/compras"));
			} else if (this.state.submitBtnClicked === "nueva") {
				this.setState({
					errors: {},
					compra: {},
					contactos: [false],
					items: [false],
					productos: [],
					grupos: [],
					impuestos: [],
					terminos: [],
					almacenes: [],
					compraItems: [{ id: Date.now(), subtotal: 0, itbis: 0 }],
					useItbis: "aplicado",
					itbis: 1.18,
					total: 0,
					tipo: "producto",
					saving: false,
					contacto_id: false,
					showCreateContactoModal: false,
					showEditContactoModal: false,
					submitBtnClicked: "normal",
					showPagoModal: false,
				});
			} else if (this.state.submitBtnClicked === "imprimir") {
				window.open(getLink("/imprimir/compras/" + response.data?.id));
				this.props.history.push(getLink("/compras"));
			}
			toast("Compra editada con éxito");
		}

		if (response.code === 422) {
			this.setState({ errors: response.data.message });
            var errors = Object.keys(response.data.message);
            for (let index = 0; index < errors.length; index++) {
                if (errors[index].indexOf('item_id') > -1) {
                    toast("Favor seleccionar un producto o borrar el item vacío.", 'error');
                    break;
                }
                if (errors[index].indexOf('costo') > -1) {
                    toast("Hay un producto con el campo costo vacío.", 'error');
                    break;
                }
                if (errors[index].indexOf('precios') > -1) {
                    toast("Favor verifica que todos los productos tenga al menos un precio.", 'error');
                    break;
                }
            }
		} else if (response.code === 500) {
			this.setState({ errors: response.data.message, saving: false });
			toast("Error editando compra", "error");
		}

		this.setState({ saving: false });
	};
	deleteCompra = async (event, compra) => {
		event.preventDefault();

		const url = `compras/${compra}`;
		const data = new FormData(event.target);

		const response = await postRequest(apiUrl(url), data, 'delete');

		if (response.success) {
			window.location = getLink("/compras");
			return;
		} else {
			this.setState({ errors: response.data.message });
		}
	};
	toggleModal = async (modal, data = false) => {
		const name = "show" + modal + "Modal";
		this.setState({
			[name]: !this.state[name],
		});

		if (data) {
			await this.getTable("contactos");
			this.setState({
				contacto_id: data.id,
			});
		}
	};

	updateContacto = (value) => {
		this.setState({
			contacto_id: value,
		});
	};

	addItem = () => {
		this.setState({
			compraItems: [...this.state.compraItems, { id: Date.now() }],
		});
	};

	removeItem = (item) => {
		this.setState({
			compraItems: this.state.compraItems.filter((i) => i.id !== item),
		});
	};

	calcularSubtotal = (items) => {
		return items.reduce((accum, item) => {
			return accum + parseFloat(item.subtotal);
		}, 0);
	};

	togglePagoModal = () => {
		this.setState((state) => ({
			...state,
			showPagoModal: false,
			pagoInfo: { pago: 0, tipo_pago: "efectivo" },
		}));
	};

	render() {
		const {
			errors,
			compra,
			contactos,
			compraItems,
			items,
			productos,
			terminos,
			grupos,
			impuestos,
			showEliminarModal,
			contacto_id,
			saving,
			showPagoModal,
		} = this.state;

		const total = this.calcularSubtotal(compraItems);

		const contacto =
			contactos && contactos.filter((c) => c.id === contacto_id)[0];

		return !compra.id ? (
			<Loader />
		) : (
			<div className="crear-compra">
				<div className="page-header">
					<h1 className="page-title row">
						<span className="col">
							Editar Compra #
							<span>{compra.numero}</span>
						</span>
					</h1>
				</div>
				{compra.borrada && (
					<div className="alert alert-danger p-10">
						<i className="fas fa-exclamation-triangle m-r-15"></i>{" "}
						Esta compra fue borrada.
					</div>
				)}
				<form
					method="post"
					onSubmit={this.editCompra}
					onKeyUp={this.preventEnter}
					className="row"
					ref={this.formRef}
				>
					<input type="hidden" name="_method" value="PATCH" />
					<input type="hidden" name="tipo" value={compra.tipo} />
					<Form
						tipo={compra.tipo}
						compra={compra}
						errors={errors}
						items={items}
						productos={productos}
						terminos={terminos}
						grupos={grupos}
						impuestos={impuestos}
						total={total}
						toggleModal={this.toggleModal}
						updateContacto={this.updateContacto}
						citems={compra.items}
						contactos={contactos}
						contacto={contacto}
						showPagoModal={showPagoModal}
						togglePagoModal={this.togglePagoModal}
						guardarYSalir={this.guardarYSalir}
						prevTotal={this.state.prevTotal}
					>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    {compra.facturaCompra.length > 0 ? (
                                        <label className="label form-control-label">
                                            Factura Original en PDF:
                                        </label>
                                    ) : (
                                        <label className="label form-control-label">
                                            Adjuntar Factura Original:
                                        </label>
                                    )}
                                    <FileLoader
                                        name="factura_compra[]"
                                        archivos={compra.facturaCompra}
                                    />
                                    {errors.factura_compra && (
                                        <small className="help-blockParams form-Text">
                                            {errors.factura_compra[0]}
                                        </small>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <label className="label form-control-label">
                                        Nota:
                                    </label>
                                    {compra.borrada ? (
                                        <div>{compra.descripcion}</div>
                                    ) : (
                                        <textarea
                                            className="text-input form-control"
                                            id="descripcion"
                                            name="descripcion"
                                            rows="4"
                                            defaultValue={compra.descripcion}
                                        />
                                    )}
                                    {errors.descripcion && (
                                        <small className="help-blockParams form-Text">
                                            {errors.descripcion[0]}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
					</Form>

					<div className="page-footer text-right col-md-12">
						{!compra.borrada && (
							<button
								type="button"
								className="btn float-left"
								onClick={() => this.toggleModal("Eliminar")}
							>
								<i className="fas fa-trash-alt"></i>
							</button>
						)}
						<button
							type="button"
							onClick={() =>
								(window.location = getLink("/compras"))
							}
							className="btn btn-plain"
						>
							Volver
						</button>

						{!saving && !isPastDate(compra.fecha) && (compra.porPagar === compra.total) && (
							<button
								type="button"
								className="btn btn-outline-primary"
								onClick={() => this.guardarYPagar()}
							>
								Guardar y agregar pago
							</button>
						)}

						{!saving && (
							<button
								type="button"
								className="btn btn-outline-primary"
								onClick={() => this.guardarEImprimir()}
							>
								Guardar e imprimir
							</button>
						)}

						{saving ? (
							<Loader type="small" />
						) : (
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => this.guardarYSalir()}
							>
								Guardar
							</button>
						)}
					</div>
				</form>
				<Modal
					show={showEliminarModal}
					blank={true}
					toggle={() => this.toggleModal("Eliminar")}
				>
					<form onSubmit={(e) => this.deleteCompra(e, compra.id)}>
						<div className="popup__header">Eliminar Compra</div>
						<div className="popup__body">
							<p className="m-b-20">
								Esta acción no puede ser revertida. Si deseas
								continuar por favor indica la razón por la cual
								deseas eliminarla:
							</p>
							<textarea
								className="form-control"
								name="descripcion"
								required
								rows="5"
							></textarea>
						</div>
						<div className="popup__footer">
							<button
								onClick={() => this.toggleModal("Eliminar")}
								type="button"
								className="btn btn-plain"
							>
								Cancelar
							</button>
							<button type="submit" className="btn btn-primary">
								Eliminar
							</button>
						</div>
					</form>
				</Modal>
			</div>
		);
	}
}

export default withRouter(withRequest(Edit));
