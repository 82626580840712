import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Form from "./Form";
import { formatDate, getLink, toast } from "../../../utils/helpers";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import Modal from "../../general/Modal";
import { postRequest, withRequest } from "../../../utils/request";
import SearchCedula from "./SearchCedula";

class Edit extends Component {
    state = {
        tutor: {},
        errors: {},
        showDelete: false,
        saving: false,
        showSearch: false,
        search: "",
        searchResults: null,
        searchSuccess: null,
        searching: false,
    };
    componentDidMount() {
        this.setState({
            tutor: this.props.tutor
        });
    }
    updateTutor = async (event) => {
        event.preventDefault();

        this.setState({ saving: true });

        const data = new FormData(event.target);

        const id = this.props.tutor.id;
        const url = "academico/padres-tutores/" + id;

        const response = await postRequest(apiUrl(url), data);

        if (response.code === 422) {
            this.setState({ errors: response.data.message, saving: false });
            toast("Tutor no pudo ser actualizado.", "error");
            return;
        }

        if (response.success) {
            this.props.history.push(getLink("/academico/padres-tutores"));
            toast("Tutor actualizado correctamente.");
        } else {
            toast("Tutor no pudo ser actualizado.", "error");
        }

        this.setState({ saving: false });
    };
    deleteTutor = async (tutor) => {

        const url = `academico/padres-tutores/${tutor}`;
        const response = await postRequest(apiUrl(url), {}, 'delete');

        if (response.success) {
            this.props.history.push(getLink("/academico/padres-tutores"));
            toast("Tutor borrado.");
        } else {
            toast("Tutor no pudo ser borrado.", "error");
        }
    };
    toggleSearchModal = () => {
        this.setState({
            showSearch: !this.state.showSearch,
        });
    };
    buscarTutor = async (e) => {
        e.preventDefault();

        this.setState({
            searching: true,
        });

        const { search } = this.state;
        const url = `https://app.migente.com.do/api/getpadronexterno/?cedula=${search}&user=felipe_api&pswd=F3l1pe`;
        const response = await fetch(url);
        response.json().then((res) => {
            this.setState({
                searchResults: res.Persona,
                searchSuccess: res.Existe,
                searching: false,
            });
        });
    };
    insertarTutor = () => {
        const search = this.state.searchResults;

        if (!search) {
            return;
        }

        this.setState(prevState => {
            return {tutor: {
                ...prevState.tutor,
                nombre: search.Nombres,
                apellido:
                    search.Apellido1 +
                    " " +
                    (search.Apellido2 ? search.Apellido2 : ""),
                cedula: search.Cedula,
                fechaNacimiento: formatDate(Date.parse(search.FechaNacimiento)),
                direccion: search.Municipio + ", " + search.Provincia,
                foto: search.Imagen,
                fotoCed: search.Imagen,
                nacionalidad: "Dominicano",
            }}
        });
        this.toggleSearchModal();
    };
    toggleDeleteModal = () => {
        this.setState({
            showDelete: !this.state.showDelete,
        });
    };
    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    render() {
        const {
            errors,
            tutor,
            tipos,
            saving,
            searchResults,
            searching,
            searchSuccess
        } = this.state;

        return (
            <div>
                <form
                    method='post'
                    onSubmit={this.updateTutor}
                    encType='multipart/form-data'>
                    <input type='hidden' name='_method' value='patch' />
                    <Form tutor={tutor} errors={errors} tipos={tipos} toggleSearchModal={this.toggleSearchModal} />
                    <div className='page-footer text-right'>
                        <button
                            type='button'
                            className='btn float-left'
                            onClick={(e) => this.toggleDeleteModal()}>
                            <i className='fas fa-trash-alt'></i>
                        </button>
                        <Link to={getLink("/academico/padres-tutores")} className='btn btn-plain'>
                            Cancelar
                        </Link>
                        {saving ? (
                            <Loader type='small' />
                        ) : (
                            <button type='submit' className='btn btn-primary'>
                                Guardar
                            </button>
                        )}
                    </div>
                    <Modal
                        title='Borrar Tutor'
                        show={this.state.showDelete}
                        callback={() => this.deleteTutor(tutor.id)}
                        acceptText="Borrar"
                        toggle={this.toggleDeleteModal}>
                        ¿Seguro que deseas borrar a {tutor.nombre} {tutor.apellido}?
                    </Modal>
                </form>
                <Modal
                    blank
                    show={this.state.showSearch}
                    toggle={this.toggleSearchModal}>
                    <SearchCedula
                        searching={searching}
                        searchResults={searchResults}
                        searchSuccess={searchSuccess}
                        onInputChange={this.onInputChange}
                        buscarTutor={this.buscarTutor}
                        insertarTutor={this.insertarTutor}
                        toggleSearchModal={this.toggleSearchModal}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRouter(withRequest(Edit));
