import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import { postRequest } from "../../../utils/request";

class Create extends Component {
    state = {
        errors: {}
    };
    createUser = async event => {
        event.preventDefault();

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl("usuarios"), data);

        if (response.success) {
            this.props.getUsers();
            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { errors } = this.state;
        const { toggle, usuario, sucursales } = this.props;
        return (
            <form method='post' onSubmit={this.createUser}>
                <div className='popup__header'>Crear Usuario</div>
                <div className='popup__body'>
                    <Form usuario={usuario} errors={errors} sucursales={sucursales} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Crear
                    </button>
                </div>
            </form>
        );
    }
}

export default Create;
