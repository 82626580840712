import React, { Component } from "react";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import dayjs from "dayjs";
import Loader from "../general/Loader";
import { request } from "../../utils/request";

export default class ReporteSegurosVencidos extends Component {
    state = {
        vehiculos: false,
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {
        const response = await request(apiUrl(`reportes` + window.location.search));

        if (response.success) {
            this.setState({
                vehiculos: response.data,
            });
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { vehiculos } = this.state;

        if (!vehiculos) {
            return <Loader />;
        }

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Seguros Vencidos
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs().format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                        </div>

                        <div className='float-right'>
                            Vehículos: {vehiculos.length}
                        </div>
                    </div>
                </div>

                <table className='table'>
                    <thead>
                        <tr>
                            <th>
                                Ficha
                            </th>
                            <th>Categoría</th>
                            <th>Marca/Modelo</th>
                            <th>Chasis</th>
                            <th>Año</th>
                            <th>Placa</th>
                            <th className="text-right">Fecha Expiración</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vehiculos.map((vehiculo) => {
                        return (
                            <tr key={vehiculo.id}>
                                <td>
                                    {vehiculo.ficha}
                                </td>
                                <td>{vehiculo.categoria}</td>
                                <td>{vehiculo.modelo}</td>
                                <td>
                                    {vehiculo.chasis}
                                </td>
                                <td>
                                    {vehiculo.ano}
                                </td>
                                <td>
                                    {vehiculo.placa}
                                </td>
                                <td className="text-right">
                                    {dayjs(vehiculo.seguroExpira).format('DD MMM, YYYY')}
                                </td>
                            </tr>
                            )}
                        )}
                    </tbody>
                </table>
                <div className='col-md-12 text-right p-b-10'>
                    <div className='float-left'>
                    </div>

                    <div className='float-right'>
                        Vehículos: {vehiculos.length}
                    </div>
                </div>
            </div>
        );
    }
}
