import React, { Component } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import { postRequest, withRequest } from "../../utils/request";
import Loader from "../general/Loader";
import { toast } from "../../utils/helpers";

class Create extends Component {
    state = {
        errors: {},
        tiposEmpleados: [],
        gruposEmpleados: [],
        saving: false,
    };
    componentDidMount() {
        this.getTable("tipos", "tiposEmpleados");
        this.getTable("grupos-tipos-empleados?estado=activo", "gruposEmpleados");
    }
    getTable = async (url, name = null) => {
        const request = await this.props.request(url);
        const table = name ? name : url;

        if (request.data) {
            this.setState({
                [table]: request.data,
            });
            this.setState({
                loading: false,
            });
        }
    };
    createNomina = async (event) => {
        event.preventDefault();

        this.setState({ saving: true });

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl("nominas"), data);

        if (response.success) {
            this.props.getNominas();
            this.props.toggle();
            this.setState({ saving: false });
        }
        if (response.code === 422) {
            if (response.data.message) {
                this.setState({ errors: response.data.message, saving: false });
                toast('No se ha podido generar la nomina. Favor revisar campos.', 'error');
            } else {
                this.setState({saving: false})
                toast(response.data, 'error');
            }
        }
    };
    render() {
        const { errors, saving, tiposEmpleados, gruposEmpleados } = this.state;
        const { toggle, nomina } = this.props;
        return (
            <form method='post' onSubmit={this.createNomina}>
                <div className='popup__header'>Generar Nómina</div>
                <div className='popup__body'>
                    <Form
                        nomina={nomina}
                        tiposEmpleados={tiposEmpleados}
                        gruposEmpleados={gruposEmpleados}
                        errors={errors}
                    />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Crear
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

export default withRequest(Create);
