import React, { Component } from "react";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatNumber, getCurrentDayName } from "../../utils/helpers";
import dayjs from "dayjs";
import Loader from "../general/Loader";
import { request } from "../../utils/request";

export default class ReporteResumenProduccion extends Component {
    state = {
        tickets: false
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {
        const response = await request(apiUrl(`reportes` + window.location.search));

        if (response.success) {
            this.setState({
                tickets: response.data
            });
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { tickets } = this.state;

        if (!tickets) {
            return <Loader />;
        }

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            suc => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);

        const fechas = tickets.reduce((acc, ticket) => {
            if (!acc[ticket.fecha]) {
                acc[ticket.fecha] = [];
            }
            acc[ticket.fecha].push(ticket);

            return acc;
        }, {});

        const toneladas =
            tickets.reduce(
                (acc, ticket) => acc + parseFloat(ticket.pesoNeto),
                0
            ) / 1000;

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Resumen de Producción por Días
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(query.desde).format("DD/MM/YYYY")} -{" "}
                        {dayjs(query.hasta).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            Total de viajes: {tickets.length}
                        </div>

                        <div className='float-right'>
                            <h3>TOTAL VENTAS: {formatNumber(toneladas)}</h3>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Día</th>
                            <th>Fecha</th>
                            <th className='text-center'>Viajes/Tickets</th>
                            <th className='text-center'>Cant. Vehículos</th>
                            <th className='text-right'>VENTAS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(fechas).map(fecha => {
                            const fichas = fechas[fecha].reduce(
                                (acc, ticket) => {
                                    if (acc.indexOf(ticket.ficha) === -1) {
                                        acc.push(ticket.ficha);
                                    }
                                    return acc;
                                },
                                []
                            );
                            const total = fechas[fecha].reduce(
                                (acc, ticket) => {
                                    console.log(ticket.pesoNeto);
                                    acc += parseFloat(ticket.pesoNeto);
                                    return acc;
                                },
                                0
                            );
                            console.log(fichas);
                            return (
                                <tr key={fecha}>
                                    <td>
                                        {getCurrentDayName(dayjs(fecha).day())}
                                    </td>
                                    <td>{dayjs(fecha).format("DD/MM/YYYY")}</td>
                                    <td className='text-center'>
                                        {fechas[fecha].length}
                                    </td>
                                    <td className='text-center'>
                                        {fichas.length}
                                    </td>
                                    <td className='text-right'>
                                        {formatNumber(total / 1000)}
                                    </td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td colSpan='2'></td>
                            <td className='text-center'>
                                <strong>
                                    Total de Viajes: {tickets.length}
                                </strong>
                            </td>
                            <td></td>
                            <td className='text-right'>
                                <strong>
                                    Total VENTAS: {formatNumber(toneladas)}
                                </strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
