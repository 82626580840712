import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";

import { auth } from "../../utils/auth";
import { postRequest, withRequest } from "../../utils/request";
import { toast, getLink } from "../../utils/helpers";
import { apiUrl } from "../../utils/apiUrl";
import Loader from "../general/Loader";
import Modal from "../general/Modal";
import Create from "../Contactos/Create";
import Edit from "../Contactos/Edit";
import Form from "./Form";

class CrearGastosGenerales extends Component {
    state = {
        errors: {},
        compra: {},
        items: null,
        impuestos: null,
        terminos: null,
        contacto_id: false,
        contactos: null,
        showCreateContactoModal: false,
        showEditContactoModal: false,
        gastoItems: [{ id: Date.now(), subtotal: 0 }],
        total: 0,
        fecha: dayjs().format("YYYY-MM-DD"),
        loading: true,
        saving: false,
    };
    componentDidMount() {
        this.getData();
    }
    getData = async () => {
        this.getTable("items", "items");
        this.getTable("terminos-pago", "terminos");
        await this.getTable("impuestos", "impuestos");
        await this.getTable("contactos");

        this.setState({
            loading: false
        });
    }
    getTable = async (url = null, nombre = null) => {
        const table = await this.props.request(url);
        const tableName = nombre ? nombre : url;

        if (table.data) {
            this.setState({
                [tableName]: table.data,
            });
        }
    };
    updateContacto = (value) => {
        this.setState({
            contacto_id: value
        })
    }
    createGastos = async (event, salir = false, imprimir = false) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl("gastos-internos"), data);

        if (response.success) {
            this.setState({
                saving: false,
            });
            if (imprimir) {
                window.open(getLink('/imprimir/gastos-internos/' + response.data.id))
            }
            if (salir) {
                this.props.history.push(getLink("/gastos-internos"));
            } else {
                const current = window.location.pathname;
                this.props.history.replace(getLink('/reload'));
                    setTimeout(() => {
                    this.props.history.replace(current);
                });
            }
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message, saving: false });
            toast("Por favor revisa todos los campos", "error");
        }
    };
    addItem = () => {
        this.setState({
            gastoItems: [...this.state.gastoItems, { id: Date.now() }],
        });
    };
    removeGasto = (item) => {
        this.setState({
            gastoItems: this.state.gastoItems.filter((i) => i.id !== item),
        });
    };
    updateTotal = async (id, value) => {
        await this.setState({
            gastoItems: this.state.gastoItems.map((item) => {
                if (item.id === id) {
                    item.subtotal = value;
                }
                return item;
            }),
        });

        this.setState({
            total: this.state.gastoItems.reduce((accum, item) => {
                return accum + item.subtotal;
            }, 0),
        });
    };
    onChangeDate = (e) => {
        this.setState({
            fecha: e.target.value,
        });
    };
    onChangeInput = async (name, value) => {
        await this.setState({
            [name]: value,
        });
    };
    toggleModal = async (modal, data = false) => {
        const name = 'show' + modal + 'Modal';
        this.setState({
            [name]: !this.state[name]
        });

        if (data) {
            await this.getTable('contactos');

            this.setState({
                contacto_id: data.id,
            });
        }
    };
    guardarSalir = (e) => {
        e.target = e.target.form;
        this.createGastos(e, true)
    }
    guardarImprimir = (e) => {
        e.target = e.target.form;
        this.createGastos(e, true, true)
    }
    render() {
        const {
            errors,
            items,
            loading,
            saving,
            impuestos,
            contactos,
            terminos,
            contacto_id,
        } = this.state;

        const contacto = contactos && contactos.filter(c => c.id ===contacto_id)[0];

        return (
            <div className='crear-compra'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Añadir Gastos</span>
                    </h1>
                </div>
                { loading ? <Loader /> : (
                    <form
                        method='post'
                        onSubmit={this.createGastos}
                        onKeyUp={this.preventEnter}
                        className='row'>
                        <Form
                            isCreate={true}
                            toggleModal={this.toggleModal}
                            updateContacto={this.updateContacto}
                            impuestos={impuestos}
                            contactos={contactos}
                            terminos={terminos}
                            items={items}
                            gasto={{tipo: 'interno'}}
                            errors={errors}
                            contacto={contacto}
                            gitems={[{id: Date.now(), cantidad: 1 }]}
                            cates={[]}
                            date={dayjs().format("YYYY-MM-DD")}
                            />
                        <div className='page-footer text-right col-md-12'>
                            <button
                                type='button'
                                onClick={() =>
                                    this.props.history.push(getLink("/gastos-internos"))
                                }
                                className='btn btn-plain'>
                                Cancelar
                            </button>
                            {!saving && (
                                <button type='button' className='btn btn-secondary' onClick={this.guardarSalir}>
                                    Guardar
                                </button>
                            )}
                            {!saving && (
                                <button type='button' className='btn btn-secondary' onClick={this.guardarImprimir}>
                                    Guardar e Imprimir
                                </button>
                            )}
                            {saving ? (
                                <Loader type='small' />
                            ) : (
                                <button type='submit' className='btn btn-primary'>
                                    Guardar y Crear Nuevo
                                </button>
                            )}
                        </div>
                    </form>
                )}

                <Modal
                    show={this.state.showCreateContactoModal}
                    blank={true}
                    toggle={() => this.toggleModal('CreateContacto')}
                    size="lg"
                >
                    <Create toggle={(e, contacto) => this.toggleModal('CreateContacto', contacto)} fromFacturacion />
                </Modal>

                <Modal
                    show={this.state.showEditContactoModal}
                    blank={true}
                    toggle={() => this.toggleModal('EditContacto')}
                    size="lg"
                >
                    <Edit
                        toggle={(e, contacto) => this.toggleModal('EditContacto', contacto)}
                        contacto={contacto}
                        fromFacturacion
                    />
                </Modal>
            </div>
        );
    }
}

export default withRouter(withRequest(CrearGastosGenerales));
