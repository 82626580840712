import React, { Component } from "react";
import queryString from "query-string";
import Select from "react-select";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatCurrency, toast } from "../../utils/helpers";
import { postRequest, withRequest } from "../../utils/request";
import Loader from "../general/Loader";

class BulkEditCuotas extends Component {
    state = {
        fecha: '',
        monto: '',
        descontar: 0,
        errors: {},
        cuota: {},
        cuentas: [],
        showDelete: false,
        saving: false,
    };
    /* componentDidMount() {
        this.getTable('cuentas')
    } */
    editCuotas = async (event) => {
        event.preventDefault();

        this.setState({saving: true});

        const data = new FormData(event.target);
        const response = await postRequest(
            apiUrl(`descuentos/cuotas/editar` + window.location.search),
            data
        );

        if (response.success) {
            this.props.getDescuentos();
            this.props.toggle();

            const event = new Event('update-cuotas');
            window.dispatchEvent(event);

            toast(response.data);
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({saving: false});
    };
    getTable = async (url = null, nombre = null) => {
        const table = await this.props.request(url);
        const tableName = nombre ? nombre : url;

        if (table.data) {
            this.setState({
                [tableName]: table.data,
            });
        }
    };
    onChangeDescontar = e => {
        this.setState({
            descontar: e.target.value,
        });
    }
    render() {
        const { errors, descontar, cuentas, saving } = this.state;
        const { toggle } = this.props;

        const seleccionCuotas = queryString.parse(window.location.search).cuotas;
        const monto = parseFloat(queryString.parse(window.location.search).monto);

        return (
            <div>
                <form method='post' onSubmit={this.editCuotas}>
                    <input type='hidden' name='_method' value='patch' />
                    <input type="hidden" name="cuotas" value={seleccionCuotas} />
                    <div className='popup__header'>Editar Cuotas</div>
                    <div className="popup__body">
                        <p className="m-b-40">Esta acción afectará {seleccionCuotas.split(',').length} cuotas de {formatCurrency(monto)}. Ingresa el monto que deseas descontar a cada cuota.</p>
                        <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Descontar:
                                </label>
                            </div>
                            <div className='col col-md-8'>
                                <input
                                    type='number'
                                    name='descontar'
                                    step="any"
                                    className='form-control'
                                    max={monto}
                                    value={descontar}
                                    onChange={this.onChangeDescontar}
                                />
                                {errors.descontar && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.descontar[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        {/* <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Cuenta:
                                </label>
                            </div>
                            <div className='col col-md-8'>
                                <Select
                                    name="cuenta_id"
                                    options={cuentas.map( c => ({value: c.id, label: c.nombre}))}
                                />
                                {errors.cuenta_id && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.cuenta_id[0]}
                                    </small>
                                )}
                            </div>
                        </div> */}
                        <div className="row form-group">
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Comentario:
                                </label>
                            </div>
                            <div className="col">
                                <textarea required name="comentario" rows="4" width="100%" className="form-control"></textarea>
                            </div>
                        </div>
                        {(descontar > 0 && descontar < monto) && (
                            <div className="form-group text-center p-t-10">
                                <p>El nuevo valor de las cuotas será de {formatCurrency(monto - descontar)}</p>
                            </div>
                        )}
                        {descontar > monto && (
                            <div className="form-group text-center alert alert-danger p-t-10">
                                <p>El nuevo valor de las cuotas no puede exceder el valor de las cuotas: {formatCurrency(monto)}</p>
                            </div>
                        )}
                    </div>
                    <div className='popup__footer'>
                        <button
                            type='button'
                            onClick={toggle}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        {saving ? (
                            <Loader type="small"/>
                        ) : (
                            <button type='submit' className='btn btn-primary'>
                                Guardar
                            </button>
                        )}
                    </div>
                </form>
            </div>
        );
    }
}

export default withRequest(BulkEditCuotas);
